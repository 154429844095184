import axios from "axios";
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config)=>{
    const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : null;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
})
export * from "./queries";
export * from "./mutations";