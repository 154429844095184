import * as React from "react";
import { uniq, includes } from "lodash";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts-more";
import highchartsPolynomial from "highcharts/highcharts-more";
import highchartsPatternfill from "highcharts/modules/pattern-fill";
import { getSensorIconURL, getSensorBoomLength, getSensorLabel } from "./Timeline/SensorDisplaySettings";
import lidarDark from "assets/icons/LidarDark.svg";
import sodarDark from "assets/icons/SodarDark.svg";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import "./LocationEleDrawing.css";

highchartsPatternfill(Highcharts);
highchartsPolynomial(Highcharts);

function checkForCollision(x, y, pts, radius = 25) {
  for (let point of pts) {
    if (Math.hypot(Number(point.x) - x, Number(point.y) - y) < radius) {
      return true;
    }
  }
  return false;
}

/**
 *
 * @param {json} measurementLocationData : A measurement location json
 */
export const LocationElevDrawing = (props) => {
  let measurementLocationData = props.measurementLocationData;
  let chartWidth = props.chartWidth;
  let chartHeight = props.chartHeight;
  if (measurementLocationData.measurement_station_type_id === "mast") {
    return (
      <Mast
        data={measurementLocationData}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        maxHeight={props.maxHeight}
      />
    );
  } else if (includes(["lidar", "sodar"], measurementLocationData.measurement_station_type_id)){
    return(
      <VerticalProfiler
        data={measurementLocationData}
        chartWidth={chartWidth}
        chartHeight={chartHeight}
        maxHeight={props.maxHeight}
      />
    )
  } else {
    return (
      <div className="towerNo">
        <ErrorOutlineIcon color="disabled" fontSize="large" />
        <p>No Preview</p>
      </div>
    );

  }
};

/** Convert from measurement height to chart dimensions */

const Mast = (props) => {
  //** Note: The measurementLocationData follows Task 43 json format */
  const measurementLocationData = props.data;
  const mastName = measurementLocationData.name;
  const mastProps = measurementLocationData.measurement_location_mast_properties[0]?.mast_property;
  const chartHeight = props.chartHeight;
  const chartWidth = props.chartWidth;
  const maxHeight = props.maxHeight || Number(mastProps?.mast_height_m);
  const showAsIcon = chartWidth < 100 || chartHeight < 200 ? true : false;
  const showHeightLabels = showAsIcon ? false : true;
  // const showGuysWires = props.showGuyWires || false;
  // SensorDisplaySettings
  const settings = {
    iconSize: showAsIcon ? 13 : 16,
    sensorIconColor: "red", //"#1E2447",
    towerColor: "black", //"#1E2447",
    groundColor: "darkgray",
    guyWireColors: "darkgray",
    guyWireAnchorColors: "darkgray",
    boomColor: showAsIcon ? "lightgray" : "darkgray",
    sensorIconBackgroundCircleColor: "#f4f4f4", //"rgba(201, 215, 222, 0.4)", //"#C9D7DE "
  };
  // Prepare a polygon to render tower
  const mastHt = Number(mastProps?.mast_height_m);
  const groundHt = 0;
  const mastWt = showAsIcon ? 2 : 1;
  let mastShape = {
    type: "area",
    data: [
      [-mastWt, groundHt],
      [mastWt, groundHt],
      [mastWt, mastHt],
      [-mastWt, mastHt],
      [-mastWt, groundHt],
    ],
    marker: {
      enabled: false,
    },
    lineWidth: 1,
    fillColor: {
      pattern: {
        path: {
          // d: "M 3 3 L 8 3 L 8 8 Z",
          d: showAsIcon ? "M 0 0 L 30 10 M 30 0 L 0 10" : "M 0 0 L 20 10 M 20 0 L 0 10",
          strokeWidth: 1,
          strokeColor: settings.towerColor, //"#000000"
        },
        width: 12,
        height: 12,
        color: settings.towerColor, //"#907000",
        opacity: 0.5,
      },
    },
    enableMouseTracking: false,
    lineColor: settings.towerColor,
  };
  // Prepare guy wires
  const verticalGuyAnchorDist = 18; //Every 20m section of tower gets a guy wire
  const anchorRadiusPer50m = 40; // 60m
  let guyWiresSeriesList = [];
  let guyAnchorsPts = [];
  for (let i = 1; i <= Math.floor(mastHt / verticalGuyAnchorDist); i++) {
    let verticalAnchorPoint = i * verticalGuyAnchorDist;
    let radialIncrement = Math.ceil(verticalAnchorPoint / 50) * anchorRadiusPer50m;
    [-1, 1].forEach((sign) => {
      // Add guy wires in pos/neg directions
      guyWiresSeriesList.push({
        type: "line",
        dashStyle: "solid",
        lineWidth: 0.25,
        color: settings.guyWireColors,
        marker: { enabled: false },
        data: [
          [sign * radialIncrement, groundHt],
          [(sign * mastWt) / 2, verticalAnchorPoint],
        ],
        enableMouseTracking: false,
      });
      guyAnchorsPts.push([sign * radialIncrement, groundHt]);
    });
  }

  const guyAnchorSeries = {
    type: "scatter",
    data: guyAnchorsPts,
    color: settings.guyWireAnchorColors,
    marker: {
      symbol: "square",
      radius: 2,
    },
    enableMouseTracking: false,
  };

  // Prepare to show measurement icons
  let measurementPoints = measurementLocationData.measurement_points;
  let sensorPoints = measurementPoints.map((mPoint) => {
    let boomLength = getSensorBoomLength(mPoint.measurement_type_id) * (showAsIcon ? 1.5 : 1.0);
    let sensorLabel = getSensorLabel(mPoint.measurement_type_id);
    let sensorIconURL = getSensorIconURL(mPoint.measurement_type_id);
    // let x = boomLength * 3 + 5;
    // let y = Number(mPoint.height_m) + settings.iconSize / 6;
    return {
      boomLength: boomLength,
      sensorLabel: sensorLabel,
      x: boomLength * 3 + Math.sign(boomLength) * 5,
      y: Number(mPoint.height_m), // + settings.iconSize / 6, // Y offset to account for icon height
      sensorHeight: Number(mPoint.height_m),
      description: `${sensorLabel} @ ${mPoint.height_m}m.`,
      marker: {
        symbol: `url(${sensorIconURL})`,
        width: settings.iconSize,
        height: settings.iconSize,
        fillColor: settings.sensorIconColor,
        lineColor: settings.sensorIconColor,
      },
      tooltip: {
        enabled: false,
      },
      states: {
        hover: { enabled: false },
      },
    };
  });

  // Avoid collisions of points
  for (let idx = 1; idx < sensorPoints.length; idx++) {
    let point = sensorPoints[idx];
    let collision = checkForCollision(Number(point.x), Number(point.y), sensorPoints.slice(0, idx));
    if (collision) {
      point.x = -point.x;
    }
  }

  const sensorIconsSeries = {
    type: "scatter",
    data: sensorPoints,
    tooltip: {
      // pointFormat: "{point.description}",
      headerFormat: '<span style="font-size: 10px">Sensor: </span><br/>',
      pointFormat: '<b><span style="color:{point.color}">{point.description}</span></b><br/>',
    },
    enableMouseTracking: true,
    enabled: {
      hover: {
        enabled: false,
        halo: {
          enabled: false,
        },
      },
    },
  };
  const sensorIconCircleBackground = {
    type: "scatter",
    data: sensorPoints.map((point) => {
      return { x: point.x, y: point.y, description: point.description };
    }),
    marker: {
      symbol: "circle",
      fillColor: settings.sensorIconBackgroundCircleColor,
      lineColor: "lightgray",
      radius: settings.iconSize * 0.75,
      shadow: true,
    },
    color: settings.sensorIconBackgroundCircleColor,
    shadow: true,
    // tooltip: {
    //   // pointFormat: "{point.description}",
    //   headerFormat: '<span style="font-size: 10px">Sensor: </span><br/>',
    //   pointFormat:
    //     '<b><span style="color:{point.color}">{point.description}</span></b><br/>'
    // },
    enableMouseTracking: false,
  };
  // Prepare to show booms
  const boomLinesData = sensorPoints.map((sensorPoint) => {
    let sign = Math.sign(sensorPoint.x);
    return {
      type: "line",
      data: [
        [(sign * mastWt) / 2.0, sensorPoint.sensorHeight],
        [sensorPoint.x, sensorPoint.sensorHeight],
      ],
      color: settings.boomColor,
      dashStyle: "ShortDash",
      lineWidth: 1,
      marker: {
        enabled: false,
      },
      enableMouseTracking: false,
    };
  });

  // if (showAsIcon) {
  //   const seriesData = [mastShape, sensorIconCircleBackground, sensorIconsSeries]
  // } else {
  //   const seriesData = [mastShape, ...boomLinesData, ...guyWiresSeriesList, guyAnchorSeries, sensorIconCircleBackground, sensorIconsSeries]
  // }
  let seriesData = [];
  if (showAsIcon) {
    seriesData = [...boomLinesData, sensorIconCircleBackground, sensorIconsSeries, mastShape];
  } else {
    seriesData = [
      mastShape,
      ...boomLinesData,
      ...guyWiresSeriesList,
      guyAnchorSeries,
      sensorIconCircleBackground,
      sensorIconsSeries,
    ];
  }

  const chartOptions = {
    chart: {
      backgroundColor: "white",
      shadow: false,
      marginBottom: 5,
      marginTop: 5,
      height: chartHeight,
      width: chartWidth,
    },
    title: {
      text: "",
    },
    xAxis: {
      title: { enabled: false },
      min: -60,
      max: 60,
    },
    yAxis: {
      title: { enabled: false },
      labels: {
        enabled: showHeightLabels,
        format: "{value} m",
        useHTML: true,
        style: {
          fontSize: "9px",
          color: "lightgray",
        },
      },
      gridLineWidth: 0.0,
      lineWidth: 0,
      tickLength: 5,
      tickWidth: 2,
      tickInterval: 20,
      visible: showHeightLabels,
      endOnTick: false,
      min: 0,
      max: maxHeight, //1.1 * mastHt, //Math.max(100, mastHt + 10)
    },
    series: seriesData,
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
  const marginstr = showAsIcon ? { margin: "10px 0px" } : { margin: "25px 0px" };
  return (
    <div
      style={marginstr}
      // className="forSafari"
    >
      <HighchartsReact key={mastName} highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

const VerticalProfiler = (props) => {
  //** Note: The measurementLocationData follows Task 43 json format */
  const measurementLocationData = props.data;
  const mastName = measurementLocationData.name;
  const mastProps = measurementLocationData.measurement_location_mast_properties[0]?.mast_property;
  const chartHeight = props.chartHeight;
  const chartWidth = props.chartWidth;
  const maxHeight = props.maxHeight || Number(mastProps?.mast_height_m);
  const showAsIcon = chartWidth < 100 || chartHeight < 200 ? true : false;
  const showHeightLabels = showAsIcon ? false : true;

  const stationType = measurementLocationData['measurement_station_type_id']; // lidar or sodar

  // Plot the device icon
  const loggerName = measurementLocationData?.logger_main_configs[0]?.logger_model_name || "Unknown model";
  const groundHeight = showAsIcon ? 4 : 3;
  const xIconOffset = stationType=='sodar' ? (showAsIcon ? 0.5 : 0.2) : 0; // SODAR icon is slightly offset to the right

  const deviceIcon = stationType=='lidar' ? lidarDark : sodarDark;
  const profilerIcon = {
    type: "scatter",
    data: [[xIconOffset, groundHeight]],
    marker: {
      color: "darkgray",
      symbol: `url(${deviceIcon})`,
    },
    name: loggerName,
    tooltip: {
      enabled: false,
      stickyTracking: false,
      // pointFormat: "{point.description}",
      headerFormat: '<span style="font-size: 10px">Sensor: </span><br/>',
      pointFormat: '<b><span style="color:{point.color}">{series.name}</span></b><br/>',
    },
    enableMouseTracking: true,
    enabled: {
      hover: {
        enabled: false,
        halo: {
          enabled: false,
        },
      },
    },
  };

  // Plot the beam cone
  const mastHt = Number(mastProps?.mast_height_m);
  const groundHt = 0;
  const mastWt = showAsIcon ? 2 : 1;
  const coneWidthTop = showAsIcon ? 15: 10;
  const coneWidthBottom = showAsIcon ? 0.6 : 0.2;
  const coneBottomHeight = showAsIcon ? 10 : 6;
  // console.log('Showing as icon: ', showAsIcon, coneWidthBottom);
  const beamProjection = {
    type: "polygon",
    data: [ 
      [-1.0*coneWidthBottom, coneBottomHeight], 
      [-4, mastHt], 
      [4, mastHt], 
      [coneWidthBottom, coneBottomHeight], 
      // [-coneWidthBottom, coneBottomHeight] 
    ],
    threshold: coneBottomHeight,
    marker: {
      enabled: false,
    },
    lineWidth: 1,
    color: "#E1E1E1",
    opacity: 0.5,
    enableMouseTracking: false,
    lineColor: "#E1E1E1",
    tooltip: {
      stickyTracking: false
    }
  }

  // Plot measurement lines
  let measurementPoints = measurementLocationData.measurement_points;
  const measurement_heights = uniq(measurementLocationData.measurement_points.map( (x) => x.height_m));
  const height_line_series = measurement_heights.map( (height) => {
    return {
      type: "line",
      data: [[-0.9*chartWidth, height], [chartWidth*0.9, height]],
      color: "white",
      lineWidth: 2,
      dashStyle: "solid",
      marker: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        stickyTracking: true,
        pointFormat: '{series.name}: <b>{point.y}</b><br/>',
        valueSuffix: ' cm',
      },
      legend: {
        enabled: true
      }
    }
  })
  
  const plotSeries = [profilerIcon, beamProjection, ...height_line_series];
  // Prepare
  const chartOptions = {
    chart: {
      backgroundColor: "white",
      shadow: false,
      marginBottom: 5,
      marginTop: 5,
      height: chartHeight,
      width: chartWidth,
    },
    title: {
      text: "",
    },
    xAxis: {
      title: { enabled: false },
      min: -10,
      max: 10,
    },
    yAxis: {
      title: { enabled: false },
      labels: {
        enabled: showHeightLabels,
        format: "{value} m",
        useHTML: true,
        style: {
          fontSize: "9px",
          color: "lightgray",
        },
      },
      gridLineWidth: 0.0,
      lineWidth: 0,
      tickLength: 5,
      tickWidth: 2,
      tickInterval: 20,
      visible: showHeightLabels,
      endOnTick: false,
      min: 0,
      max: Math.max(maxHeight, mastHt), //1.1 * mastHt, //Math.max(100, mastHt + 10)
    },
    series: plotSeries,
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      stickyTracking: false,
      backgroundColor: "gray"
    }
  };
  const marginstr = showAsIcon ? { margin: "10px 0px" } : { margin: "25px 0px" };
  return (
    <div
      style={marginstr}
      // className="forSafari"
    >
      <HighchartsReact key={mastName} highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};