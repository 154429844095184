import React, { useState, useCallback } from "react";

import { Modal } from "@material-ui/core";

import { ProjectDetailsPanel } from "component_tree/shared";
import "./CreateProjectModal.css";
import { CloseIcon } from "../../../assets/icons";

export const CreateProjectModal = ({
  modalOpen,
  handleOnClose,
  title,
  formItems,
  formProps,
  onMapClick,
  center,
  buttonLabel,
  locationsDataList,
  mapZoom,
  modalType,
  confirmModalData = {},
  autoOpen = true,
}) => {
  const { onConfirmClick, modalTitle = "", modalSubTitle = "" } = confirmModalData;
  const [currentModalType, setCurrentModalType] = useState(() => modalType);

  const handleModalClose = useCallback(() => {
    handleOnClose();
    setCurrentModalType(modalType);
  }, [handleOnClose, modalType]);

  const renderModalType = () => {
    switch (currentModalType) {
      case "newProject":
        return (
          <section className="cp__new-project">
            <h2>Start by adding a project</h2>
            <h3>Add a project to get you started and manage your towers</h3>
            <button className="cp__newProject-btn" onClick={() => setCurrentModalType("projectDetails")}>
              Create Project
            </button>
          </section>
        );

      case "confirm":
        return (
          <section className="cp__new-project cp__confirm">
            <span className="cp__close-icon" onClick={() => handleModalClose()}>
              <CloseIcon />
            </span>
            <h2>{modalTitle}</h2>
            <h3>{modalSubTitle}</h3>

            <div>
              <button className="cp__confirm-btn cp__cancel-btn" onClick={() => handleModalClose()}>
                <h3>Cancel</h3>
              </button>
              <button className="cp__confirm-btn cp__delete-btn" onClick={onConfirmClick}>
                <h3>Delete</h3>
              </button>
            </div>
          </section>
        );

      case "projectDetails":
        return (
          <ProjectDetailsPanel
            title={title}
            formButtons={[
              { title: buttonLabel, type: "submit" },
              { title: "Cancel", onClick: handleModalClose, type: "button" },
            ]}
            rootStyles="cp__root"
            onMapClick={onMapClick}
            center={center}
            autoOpen={autoOpen}
            formItems={formItems}
            formProps={formProps}
            locationsDataList={locationsDataList}
            mapZoom={mapZoom}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Modal
        open={Boolean(modalOpen)}
        onClose={handleModalClose}
        aria-labelledby="form-dialog-title"
        className="cp__modal"
      >
        <main className="cp__modal-main">{renderModalType()}</main>
      </Modal>
    </div>
  );
};

