import { useContext } from "react";
import { Formik } from "formik";

import { TrashRed, TrashBlack, TrashRed2 } from "assets/new";
import { DetailsBox } from "component_tree/shared/DetailsBox";
import { ErrorContext, RightSidebarContext } from "contexts/misc/context";
import { deleteFile, downloadResource } from "utils/FileAPI";
import { CALLIBRATION_GROUP_ID } from "../../../constants";
import { Spinner } from "component_tree/shared";
import { DownloadFileIcon } from "assets";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";

export const CreateNewCalibration = ({
  validateCalibrationForm,
  handleCalibration,
  showForm = true,
  setShowForm,
  listWrapStyles,
  fromCalibration = false,
}) => {
  const { isCollapsed } = useContext(RightSidebarContext);
  const { setErrors } = useContext(ErrorContext);

  const handleDownload = async (link = "", filename = "") => {
    downloadResource(link, filename, CALLIBRATION_GROUP_ID)
      .then(() => {})
      .catch((e) => {
        setErrors("Error Occured While Downloading");
      });
  };

  const handleDeleteFile = (fileId, cb) => {
    // return new Promise((resolve,reject)=>{
    deleteFile(fileId, CALLIBRATION_GROUP_ID)
      .then(() => {
        cb();
      })
      .catch((error) => {
        setErrors("Error Occured while deleting files");
        cb(error);
      });
    // })
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      //[`height_m-${uuid}`]: Yup.number().required("Required").positive("Require positive input").typeError("Number required"),
      [`date_of_calibration`]: Yup.date().required("Required"),
      [`slope`]: Yup.number().required("Required").typeError("Number required"),
      [`offset`]: Yup.number().required("Required").typeError("Number required"),
    });

    return schema;
  };

  return (
    <section
      className={`sca__list-wrap ${showForm && "sca__list-expand"} ${listWrapStyles}`}
      style={{ background: fromCalibration ? "#ffffff" : "#f9f9f9", maxWidth: "1060px" }}
    >
      <Formik
        initialValues={{
          [`slope`]: null,
          [`offset`]: null,
          // [`uncertainty_k_factor`]: null,
          [`date_of_calibration`]: null,
          [`notes`]: null,
          [`report_file_name`]: "",
          [`report_link`]: "",
          [`place_of_calibration`]: null,
          'is_consensus_calibration': false,
        }}
        validationSchema={getValidationSchema()}
        enableReinitialize={true}
        validate={(values) => validateCalibrationForm(values)}
        onSubmit={async (values, { setSubmitting }) => {
          await handleCalibration(values, setSubmitting);
          setShowForm(!showForm);
        }}
      >
        {({
          isSubmitting,
          handleSubmit,
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          setSubmitting,
          initialValues,
        }) => {
          return (
            <div className="calibration-InnerContainer">
              <button className="sc__list-head sc__list-head-selected" type="button">
                <h3>Create Calibration</h3>
              </button>
              <div style={{ width: "100%" }}>
                <form onSubmit={handleSubmit}>
                  <DetailsBox
                    renderHeader={<div />}
                    key={"basicDetails"}
                    rootStyles={"sca__box "}
                    inputContainerStyles={`sca__form " ${fromCalibration && "sca__form-flex"} ${
                      isCollapsed && "sca__form-collapsed"
                    }`}
                    showForm={showForm}
                    setShowForm={setShowForm}
                    isCollapsed={isCollapsed}
                    formList={[
                      {
                        value: values[`date_of_calibration`] || null,
                        setValue: (val) => {
                          setFieldValue(`date_of_calibration`, val);
                        },
                        clickHandler: () => {},
                        setFieldError: setFieldError,
                        setFieldTouched: setFieldTouched,
                        label: "Date",
                        name: `date_of_calibration`,
                        placeholder: "",
                        inputStyles: "ca__input-box",
                        inputType: "date",
                        dateRootStyles: "sca__cal-input",
                        dateRootStyles2: "sca__cal-input2",
                        error:
                          errors[`date_of_calibration`] && touched[`date_of_calibration`]
                            ? errors[`date_of_calibration`]
                            : "",
                        dateInit: initialValues.date_of_calibration,
                        required: true,
                      },
                      {
                        value: values[`slope`],
                        setValue: (val) => handleChange(val),
                        clickHandler: (e) => {
                          handleBlur(e);
                        },
                        label: "Slope",
                        name: `slope`,
                        placeholder: "",
                        inputStyles: "ca__input-box  ",
                        inputStyles2: "ca__input-box2",
                        inputType: "customNum",
                        numType: "decimal",
                        type: "text",
                        error: errors[`slope`] && touched[`slope`] ? errors[`slope`] : "",
                      },
                      {
                        value: values[`offset`],
                        setValue: (val) => handleChange(val),
                        clickHandler: (e) => {
                          handleBlur(e);
                        },
                        label: "Offset",
                        name: `offset`,
                        placeholder: "",
                        inputStyles: "ca__input-box",
                        inputStyles2: "ca__input-box2",
                        inputType: "customNum",
                        numType: "decimal",
                        type: "text",
                        error: errors[`offset`] && touched[`offset`] ? errors[`offset`] : "",
                      },
                      {
                        value: values[`place_of_calibration`],
                        setValue: (val) => handleChange(val),
                        clickHandler: (e) => {
                          handleBlur(e);
                        },
                        label: isCollapsed ? "Place" : "Place Of Callibration",
                        name: `place_of_calibration`,
                        placeholder: "",
                        inputStyles: "ca__input-box",
                        inputStyles2: "ca__input-box2",
                        inputType: "input",
                        error:
                          errors[`place_of_calibration`] && touched[`place_of_calibration`]
                            ? errors[`place_of_calibration`]
                            : "",
                      },
                      {
                        value: values[`is_consensus_calibration`],
                        setValue: (val) => {
                          handleChange({target: {name: `is_consensus_calibration`, value: val}});
                        },
                        clickHandler: (e) => {
                          handleBlur(e);

                          // handleSubmit();
                        },
                        label: "Is Consensus Calibration",
                        name: `is_consensus_calibration`,
                        inputStyles: "ca__input-box ca__input-box-checkbox ExceptionWidth",

                        placeholder: "",
                        inputType: "checkbox",
                        error:
                          errors[`is_consensus_calibration`] &&
                            touched[`is_consensus_calibration`]
                            ? errors[`is_consensus_calibration`]
                            : "",
                      },
                      
                      
                      {
                        value: values[`notes`],
                        setValue: (val) => handleChange(val),
                        clickHandler: (e) => {
                          handleBlur(e);
                        },
                        label: "Notes",
                        name: `notes`,
                        placeholder: "",
                        inputStyles: "tm__input-box-full ca__textarea-box",
                        inputType: "textarea",
                        error: errors[`notes`] && touched[`notes`] ? errors[`notes`] : "",
                      },
                      {
                        value: values[`report_file_name`],
                        setValue: (fileName, fileId) => {
                          setFieldTouched(`report_link`);
                          setFieldTouched(`report_file_name`);
                          setFieldValue(`report_link`, fileId.toString());
                          setFieldValue(`report_file_name`, fileName);
                        },
                        clickHandler: () => {},
                        label: (
                          <>
                            <span>Documentation</span>
                          </>
                        ),
                        name: `report_link`,
                        placeholder: "-",
                        inputStyles: `ca__input-box sca__upload-documentation upload ${
                          values[`report_file_name`] ? "" : "activeBox"
                        }`,
                        inputType: "upload",
                        renderRight: (
                          <>
                            <button
                              type={"button"}
                              className={"sca__delete-button"}
                              style={{
                                display: values[`report_file_name`] ? "inline-block" : "none",
                              }}
                              onClick={() => handleDownload(values[`report_link`], values[`report_file_name`])}
                            >
                              <DownloadFileIcon />
                            </button>
                            <button
                              type="button"
                              style={{
                                display: values[`report_file_name`] ? "inline-block" : "none",
                              }}
                              className="sca__delete-button"
                              onClick={(e) =>
                                handleDeleteFile(values.report_link, (err) => {
                                  if (!err) {
                                    setFieldValue("report_file_name", null);
                                    setFieldValue("report_link", null);
                                  } else {
                                  }
                                })
                              }
                            >
                              <TrashRed2 />
                            </button>
                          </>
                        ),
                        error:
                          errors[`report_file_name`] && touched[`report_file_name`] ? errors[`report_file_name`] : "",
                      },
                    ]}
                  />
                  <div style={{ marginTop: "15px" }} className={"ca__submit-container"}>
                    <div className="ca__submit">
                      <button type="submit" className="ca__submit-btn" disabled={isSubmitting} onClick={handleSubmit}>
                        {isSubmitting ? <Spinner color="white" /> : "Create"}
                      </button>
                      <button
                        type="button"
                        className="ca__submit-btn ca__submit-cancel"
                        onClick={() => setShowForm(false)}
                      >
                        <Typography variant="button" style={{ color: "#1e244780" }}>
                          Cancel
                        </Typography>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          );
        }}
      </Formik>
    </section>
  );
};
