export const darkPalette = {
  palette: {
    primary: {
      main: "#1e2447",
      dark: "#0e1625",
      light: "#111b30",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00c48c",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#000",
      secondary: "#fff"
    }
  },
};
export const lightPalette = {
  palette: {
    primary: {
      main: "#1e2447",
      dark: "#0e1625",
      light: "#111b30",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00c48c",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#000",
      secondary: "#fff"
    }
  },
};
