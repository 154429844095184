import { format } from "date-fns";

export * from "./getIconType";
export * from "./calendarUtils";
export * from "./strings";
export * from "./hooks";
export * from "./sensorDataUtils";
export * from "./formValues";

export const patchID = (id) => (id.charAt(0).toUpperCase() + id.slice(1)).replace(/_/g, " ");
export const checkValidLatLng = (lat, lng) => lat < 90 && lat > -90 && lng < 180 && lng > -180;

export const checkIsLocationDecommissioned = (location) => {
    const { decommissioned_date, is_active } = location;
    return decommissioned_date && !is_active;

}

export const getMastStatusString = (location) => {
    if(!location) return "";
    const { decommissioned_date, is_active } = location;
    if (decommissioned_date && !is_active) {
        return `  (Decommissioned on ${format(new Date(decommissioned_date), 'yyyy MMM dd')})`;
    }
    return "  (Active)";

}
