import { makeStyles } from "@material-ui/core";
import { ArrowBackIosOutlined } from "@material-ui/icons";
import { RightSidebarContextProvider } from "../../contexts/misc/context";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    right: "0",
    top: "53.6px",
    display: "flex",
    // width: "250px",
    width: "695px",
    height: "calc(100% - 53px)",
    transition: "width 0.5s ease-in-out",
    zIndex: "1300",
    background: "#ffffff",
    boxShadow: "0px 2px 12px 7px rgba(0, 0, 0, 0.02)",
    "@media (max-width:1600px)": {
      width: 550,
    },
    "@media (max-width:1200px)": {
      width: 400,
    },
    // "&.active": {
    //   width: "705px",
    //   transition: "width 0.5s ease-in-out",
    // },
  },
  expandButton: {
    position: "absolute",
    top: "23px",
    left: "-18px",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "4px",
    padding: "8px",
    zIndex: theme.spacing(2),
    boxShadow: " 0 2px 12px -2px rgba(0, 0, 0, 0.04)",
    transition: "all .3s ease-in",
    "&:hover svg": {
      opacity: "1",
    },
    "& svg": {
      fontSize: "14px",
      opacity: "0.7",
    },
  },
  visible: {},
  notVisible: {
    left: "100%",
    opacity: "0",
    pointerEvents: "none",
  },
}));

export const SidePreview = ({ children, expand = false, onClick, active, show }) => {
  const classes = useStyles();

  const handleActive = (e) => {
    if (expand) {
      // if (!isThisFormElement) {
      if (onClick) {
        onClick(e);
      }
      // }
    }
  };
  return (
    <div id={"right-preview-sidebar"} data-isexpanded={active} className={`${classes.root} ${active ? "active" : ""}`}>
      {/* <span
        className={classes.expandButton + " " + (!active ? classes.visible : classes.notVisible)}
        onClick={handleActive}
      >
        <ArrowBackIosOutlined />
      </span> */}
      <RightSidebarContextProvider value={{ isCollapsed: !active }}>{children}</RightSidebarContextProvider>
    </div>
  );
};
