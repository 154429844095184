import { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";

import { ArrowWhiteUp, ArrowGrayDown, TickWhite, TrashBlack, TrashRed, TrashRed2 } from "assets/new";
import { PlusGreenIcon, DownloadFileIcon } from "assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DetailsBox } from "component_tree/shared/DetailsBox";
import { UPDATE_CALIBRATION, CREATE_CALIBRATION, GET_TOWER_BY_ID, DELETE_CALIBRATION } from "services";
import "./SensorCalibration.css";
import { ErrorContext, RightSidebarContext } from "contexts/misc/context";
import { deleteFile, downloadResource } from "utils/FileAPI";
import { CALLIBRATION_GROUP_ID } from "../../../constants";
import { handleErrorFromGQL } from "utils";
import { CreateProjectModal } from "component_tree/shared/CreateProjectModal";
import { format } from "date-fns";
import { CreateNewCalibration } from "./CreateNewCalibration";
import { Spinner } from "component_tree/shared";
import * as Yup from "yup";
import TrashIcon from "assets/new/TrashIcon";
import MeasurementAccordion from "component_tree/shared/Measurement/MeasurementAccordion";
import { useConfirmationDialogue } from "contexts/misc/ConfirmationDialogueContext";

export const SensorCalibrationList = ({
  dis,
  validateCalibrationForm,
  calibration,
  handleCalibration,
  showForm,
  setShowForm,
  handleDeleteFile,
  listWrapStyles,
  showDelete = false,
  deleteCalibration,
  fromCalibration,
}) => {
  const { isCollapsed } = useContext(RightSidebarContext);
  const { setErrors } = useContext(ErrorContext);

  const [confirmModalOpen, setConfirmModalOpen] = useState(null);
  const [isFormChange, setIsFormChange] = useState(false);

  const handleDownload = async (link = "", filename = "") => {
    downloadResource(link, filename, CALLIBRATION_GROUP_ID)
      .then(() => { })
      .catch((e) => {
        setErrors("Error Occured While Downloading");
      });
  };
  const { confirm } = useConfirmationDialogue();

  const getValidationSchema = (uuid) => {
    const schema = Yup.object().shape({
      [`date_of_calibration-${uuid}`]: Yup.date().required("Required"),
      [`slope-${uuid}`]: Yup.number().required("Required").typeError("Number required"),
      [`offset-${uuid}`]: Yup.number().required("Required").typeError("Number required"),
    });

    return schema;
  };
  return (
    <>
      <section
        key={calibration.uuid}
        className={`sc__list-wrap ${showForm?.[calibration.uuid] ? "sca__list-expand" : ""} ${listWrapStyles}`}
        style={{ background: fromCalibration ? "#ffffff" : "#f9f9f9" }}
      >
        <Formik
          initialValues={{
            [`slope-${calibration.uuid}`]: calibration.slope || null,
            [`offset-${calibration.uuid}`]: calibration.offset || null,
            [`date_of_calibration-${calibration.uuid}`]: calibration.date_of_calibration
              ? format(new Date(calibration.date_of_calibration), `yyyy-MM-dd'T'HH:mm:ss`)
              : null,
            [`notes-${calibration.uuid}`]: calibration.notes || null,
            [`report_file_name-${calibration.uuid}`]: calibration.report_file_name || null,
            [`report_link-${calibration.uuid}`]: calibration.report_link || null,
            [`place_of_calibration-${calibration.uuid}`]: calibration.place_of_calibration || null,
            [`is_consensus_calibration-${calibration.uuid}`]: calibration.is_consensus_calibration || null,
          }}
          enableReinitialize={true}
          validate={(v) => validateCalibrationForm(v, calibration.uuid)}
          onSubmit={(values, { setSubmitting }) => {
            handleCalibration(values, setSubmitting, calibration?.uuid);
            setIsFormChange(false);
          }}
          key={calibration.uuid}
          validationSchema={getValidationSchema(calibration.uuid)}
        >
          {({
            isSubmitting,
            handleSubmit,
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            setSubmitting,
            resetForm,
            initialValues,
          }) => {
            const isActive = values[`report_file_name-${calibration.uuid}`];
            const heading = <>
            <h3>
                    <span className={`sc_head_span ${showForm?.[calibration.uuid] && 'sc_head_span-active'}`}>
                      {calibration.date_of_calibration
                        ? format(new Date(calibration.date_of_calibration), "yyyy-MM-dd")
                        : ""}{" "}
                    </span>
                    <span className={`sc_head_span ${showForm?.[calibration.uuid] && 'sc_head_span-active'}`}>
                      {calibration.slope ? `Slope: ${calibration.slope}` : ""}{" "}
                    </span>
                    <span className={`sc_head_span ${showForm?.[calibration.uuid] && 'sc_head_span-active'}`}>
                      {calibration.offset ? `Offset: ${calibration.offset}` : ""}{" "}
                    </span>
                  </h3>
                  <aside>
                    {showForm?.[calibration.uuid] ? <ArrowWhiteUp /> : <ExpandMoreIcon style={{ color: "#1E2447" }} />}
                  </aside>
            </>
            const handleAccordianClick = () => {
              setShowForm({ [calibration.uuid]: !showForm?.[calibration.uuid] });
            };
            return (
              <div key={calibration.uuid}>
                <MeasurementAccordion 
                  heading={heading}
                  active_bg_color="blue"
                  onClick={handleAccordianClick}
                  open={showForm?.[calibration.uuid]}
                  >
                <form
                  onSubmit={handleSubmit}
                  key={calibration.uuid}
                  id={calibration.uuid}
                  style={{ display: `${!showForm?.[calibration.uuid] ? "none" : "block"}` }}
                // style={{ display: "block" }}
                >
                  <DetailsBox
                    renderHeader={<div />}
                    key={"basicDetails"}
                    rootStyles={"sca__box "}
                    inputContainerStyles={`sca__form " ${fromCalibration && "sca__form-flex"} ${isCollapsed && "sca__form-collapsed"
                      } `}
                    formList={[
                      {
                        value: values[`date_of_calibration-${calibration.uuid}`] || null,
                        setValue: (val) => {
                          setFieldValue(`date_of_calibration-${calibration.uuid}`, val);
                          // setTimeout(() => {
                          //   handleSubmit();
                          // }, 200);
                        },
                        clickHandler: (e) => handleBlur(e),
                        setFieldError: setFieldError,
                        setFieldTouched: setFieldTouched,
                        error:
                          errors[`date_of_calibration-${calibration.uuid}`] &&
                            touched[`date_of_calibration-${calibration.uuid}`]
                            ? errors[`date_of_calibration-${calibration.uuid}`]
                            : "",

                        label: "Date",
                        name: `date_of_calibration-${calibration.uuid}`,
                        placeholder: "",
                        inputStyles: "ca__input-box",
                        inputType: "date",
                        dateRootStyles: "sca__cal-input",
                        required: true,
                        dateInit: initialValues[`date_of_calibration-${calibration.uuid}`],
                      },
                      {
                        value: values[`slope-${calibration.uuid}`],
                        setValue: (val) => {
                          handleChange(val);
                          setIsFormChange(true);
                        },
                        clickHandler: (e) => {
                          handleBlur(e);
                          // handleSubmit();
                        },
                        label: "Slope",
                        name: `slope-${calibration.uuid}`,
                        placeholder: "",
                        inputStyles: "ca__input-box",
                        inputType: "customNum",
                        numType: "decimal",
                        type: "text",
                        // dateRootStyles: "sca__grey-input",
                        error:
                          errors[`slope-${calibration.uuid}`] && touched[`slope-${calibration.uuid}`]
                            ? errors[`slope-${calibration.uuid}`]
                            : "",
                      },
                      {
                        value: values[`offset-${calibration.uuid}`],
                        setValue: (val) => {
                          handleChange(val);
                          setIsFormChange(true);
                        },
                        clickHandler: (e) => {
                          handleBlur(e);

                          // handleSubmit();
                        },
                        label: "Offset",
                        name: `offset-${calibration.uuid}`,
                        placeholder: "",
                        inputStyles: "ca__input-box",
                        // dateRootStyles: "sca__grey-input",
                        inputType: "customNum",
                        numType: "decimal",
                        type: "text",
                        error:
                          errors[`offset-${calibration.uuid}`] && touched[`offset-${calibration.uuid}`]
                            ? errors[`offset-${calibration.uuid}`]
                            : "",
                      },
                      {
                        value: values[`place_of_calibration-${calibration.uuid}`],
                        setValue: (val) => {
                          handleChange(val);
                          setIsFormChange(true);
                        },
                        clickHandler: (e) => {
                          handleBlur(e);

                          // handleSubmit();
                        },
                        label: "Place Of Calibration",
                        name: `place_of_calibration-${calibration.uuid}`,
                        placeholder: "",
                        inputStyles: "ca__input-box ExceptionWidth48",
                        inputType: "input",
                        error:
                          errors[`place_of_calibration-${calibration.uuid}`] &&
                            touched[`place_of_calibration-${calibration.uuid}`]
                            ? errors[`place_of_calibration-${calibration.uuid}`]
                            : "",
                      },
                      {
                        value: values[`is_consensus_calibration-${calibration.uuid}`],
                        setValue: (val) => {
                          handleChange({target: {name: `is_consensus_calibration-${calibration.uuid}`, value: val}});
                          setIsFormChange(true);
                        },
                        clickHandler: (e) => {
                          handleBlur(e);

                          // handleSubmit();
                        },
                        label: "Is Consensus Calibration",
                        name: `is_consensus_calibration-${calibration.uuid}`,
                        inputStyles: "ca__input-box ca__input-box-checkbox ExceptionWidth",

                        placeholder: "",
                        inputType: "checkbox",
                        error:
                          errors[`is_consensus_calibration-${calibration.uuid}`] &&
                            touched[`is_consensus_calibration-${calibration.uuid}`]
                            ? errors[`is_consensus_calibration-${calibration.uuid}`]
                            : "",
                      },
                      {
                        value: values[`notes-${calibration.uuid}`],
                        setValue: (val) => {
                          handleChange(val);
                          setIsFormChange(true);
                        },
                        clickHandler: (e) => {
                          handleBlur(e);

                          // handleSubmit();
                        },
                        label: "Additional Notes",
                        name: `notes-${calibration.uuid}`,
                        placeholder: "",
                        inputStyles: `tm__input-box-full ca__input-box ExceptionWidth100`,
                        inputType: "textarea",
                        id: "TextAreaIdHeight",
                        inputGreyStyles: "sca__input-box-gr",
                        error:
                          errors[`notes-${calibration.uuid}`] && touched[`notes-${calibration.uuid}`]
                            ? errors[`notes-${calibration.uuid}`]
                            : "",
                      },
                      {
                        value: values[`report_file_name-${calibration.uuid}`],
                        setValue: (fileName, fileId) => {
                          setFieldTouched(`report_link-${calibration.uuid}`);
                          setFieldTouched(`report_file_name-${calibration.uuid}`);
                          setFieldValue(`report_link-${calibration.uuid}`, fileId.toString());
                          setFieldValue(`report_file_name-${calibration.uuid}`, fileName);
                          setIsFormChange(true);

                          // handleSubmit();
                        },
                        // clickHandler: (co) => {},
                        label: (
                          <>
                            <span>Documentation</span>
                          </>
                        ),
                        name: `report_link-${calibration.uuid}`,
                        placeholder: "",
                        inputStyles: `${dis} ${isActive ? "" : "activeBox"}`,
                        inputType: "upload",
                        renderRight: (
                          <>
                            {values[`report_file_name-${calibration.uuid}`] && (
                              <>
                                <button
                                  type={"button"}
                                  className={"sca__delete-button"}
                                  onClick={() =>
                                    handleDownload(
                                      values[`report_link-${calibration.uuid}`],
                                      values[`report_file_name-${calibration.uuid}`]
                                    )
                                  }
                                >
                                  <DownloadFileIcon />
                                </button>
                                <button
                                  type="button"
                                  className="sca__delete-button"
                                  onClick={(e) =>
                                    handleDeleteFile(values, setSubmitting, calibration.uuid, (e) => {
                                      setFieldValue("report_file_name", null);
                                      setFieldValue("report_link", null);
                                    })
                                  }
                                >
                                  <TrashIcon />
                                </button>
                              </>
                            )}
                          </>
                        ),
                        error:
                          errors[`report_file_name-${calibration.uuid}`] &&
                            touched[`report_file_name-${calibration.uuid}`]
                            ? [`report_file_name-${calibration.uuid}`]
                            : "",
                      },
                    ]}
                  />
                  <div className={"ca__submit-container"}>
                    {(isFormChange || Object.keys(touched).length > 0) && (
                      <div className="ca__submit">
                        <button type="submit" className="ca__submit-btn" disabled={isSubmitting}>
                          {isSubmitting ? <Spinner color="white" /> : "Save"}
                        </button>
                        <button
                          type="button"
                          className="ca__submit-btn ca__submit-cancel"
                          onClick={() => {
                            resetForm();
                            setShowForm({ [calibration.uuid]: !showForm?.[calibration.uuid] });
                            setIsFormChange(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                    {calibration.update_at && (
                      <div className={"updated-at-text"}>
                        Last Updated at : {format(new Date(calibration.update_at), "dd MMMM yyyy")}
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    className="ca__delete-btn"
                    onClick={(e) => {
                      confirm({
                        color: 'error',
                        title: `Delete Calibration`,
                        acceptButtonText: 'Delete',
                        content:
                          `Are you sure you want to delete this Calibration?`,
                        onClose: async (isConfirmed) => {
                          if (isConfirmed) {
                            await deleteCalibration({
                              variables: {
                                calibrationId: calibration?.uuid,
                              },
                            })
                          }
                        },
                      });
                    }}
                  >
                    <TrashIcon /> <span> Delete Calibration</span>
                  </button>
                </form>
                </MeasurementAccordion>
              </div>
            );
          }}
        </Formik>

        <CreateProjectModal
          modalType={"confirm"}
          modalOpen={confirmModalOpen}
          handleOnClose={() => setConfirmModalOpen(false)}
          confirmModalData={{
            onConfirmClick: async () => {
              await deleteCalibration({
                variables: {
                  calibrationId: confirmModalOpen,
                },
              });
              setConfirmModalOpen(false);
            },
            modalTitle: "Delete Calibration",
            modalSubTitle: `Are you sure you want to delete this Calibration?`,
          }}
        />
      </section>
      {showDelete && (
        <div className="sc__delete-cal">
          <button className="cc__trash" onClick={() => setConfirmModalOpen(calibration?.uuid)}>
            <TrashBlack />
          </button>
        </div>
      )}
    </>
  );
};

export const SensorCalibration = ({
  show = false,
  calibrations,
  oem,
  model,
  serial,
  date_from,
  date_to,
  towerId,
  sensorId,
  fromCalibration = false,
  isActive,
  dis,
}) => {
  const [showForm, setShowForm] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [calibrationsList, setCalibrationsList] = useState(() => calibrations);
  const { setErrors } = useContext(ErrorContext);

  const [deleteCalibration] = useMutation(DELETE_CALIBRATION, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });
  const [updateCalibration] = useMutation(UPDATE_CALIBRATION, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });
  const [createCalibration] = useMutation(CREATE_CALIBRATION, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  useEffect(() => {
    if (calibrations !== calibrationsList) {
      setCalibrationsList(calibrations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calibrations]);

  const handleShowCreateForm = () => {
    setShowCreateForm(true);
  };

  const handleCreateCalibration = async (values, setSubmitting) => {
    try {
      await createCalibration({
        variables: {
          sensorId,
          name: null,
          slope: values.slope,
          offset: values.offset,
          date_of_calibration: values.date_of_calibration,
          place_of_calibration: values.place_of_calibration,
          notes: values.notes,
          report_file_name: values.report_file_name,
          report_link: values.report_link,
          is_consensus_calibration: values.is_consensus_calibration,
        },
      });
      setSubmitting(false);
    } catch (e) {
      setErrors("Error Occured");
    }
    // setTimeout(() => {
    //   setSubmitting(false);
    //   setShowCreateForm(false);
    // }, 1000);
  };

  const handleCalibration = async (values, setSubmitting, calibrationId) => {
    try {
      await updateCalibration({
        variables: {
          calibrationId,
          name: values[`name-${calibrationId}`],
          slope: values[`slope-${calibrationId}`],
          offset: values[`offset-${calibrationId}`],
          sensitivity: values[`sensitivity-${calibrationId}`],
          date_of_calibration: values[`date_of_calibration-${calibrationId}`],
          place_of_calibration: values[`place_of_calibration-${calibrationId}`],
          calibration_organisation: values[`calibration_organisation-${calibrationId}`],
          notes: values[`notes-${calibrationId}`],
          report_file_name: values[`report_file_name-${calibrationId}`] || "",
          report_link: values[`report_link-${calibrationId}`] || "",
          is_consensus_calibration: values[`is_consensus_calibration-${calibrationId}`],
        },
      });
    } catch (error) {
      //handleErrorFromGQL(error, setErrors);
    }finally{
      setSubmitting(false);
    }
    // setTimeout(() => {
    //   setSubmitting(false);
    //   setShowForm({ [calibrationId]: !showForm?.[calibrationId] });
    // }, 1000);
  };

  const handleDeleteFile = async (values, setSubmitting, calibrationId, cb) => {
    setSubmitting(true);
    try {
      await deleteFile(values["report_link-" + calibrationId], CALLIBRATION_GROUP_ID);
      await updateCalibration({
        variables: {
          calibrationId,
          name: values[`name-${calibrationId}`],
          slope: values[`slope-${calibrationId}`],
          offset: values[`offset-${calibrationId}`],
          sensitivity: values[`sensitivity-${calibrationId}`],
          uncertainty_k_factor: values[`uncertainty_k_factor-${calibrationId}`],
          date_of_calibration: values[`date_of_calibration-${calibrationId}`],
          place_of_calibration: values[`place_of_calibration-${calibrationId}`],
          calibration_organisation: values[`calibration_organisation-${calibrationId}`],
          notes: values[`notes-${calibrationId}`],
          report_file_name: "",
          report_link: "",
        },
      });
      cb();
    } catch (e) {
      setErrors("Error occured in Deleting File");
      cb(e);
    } finally {
      setSubmitting(false);
    }
  };

  const validateCalibrationForm = (values, configId) => {
    const errors = {};

    // if (!values[`report_file_name-${configId}`]) {
    //   errors[`report_file_name-${configId}`] = "Report File Name Required";
    // }

    return errors;
  };

  const validateCraeteCalibrationForm = async (values) => {
    const errors = {};
    if (!values[`slope`]) {
      errors[`slope`] = "Required";
    }
    if (!values[`offset`]) {
      errors[`offset`] = "Required";
    }
    if (!values[`date_of_calibration`]) {
      errors[`date_of_calibration`] = "Required";
    }
    return errors;
  };
  let sensorHeaderStr = oem ? oem + " - " : "";
  sensorHeaderStr += model ? model + "" : "Unknown model ";
  sensorHeaderStr += serial ? " , S/N: " + serial : " , S/N: Serial unknown ";
  sensorHeaderStr += ',      '
  return (
    <article
      className={!fromCalibration ? "sc__body" : "sc__body2"}
      style={{ maxWidth: fromCalibration ? "470px" : "" }}
    >
      {!fromCalibration && (
        <div className="sc__head" style={{ paddingBottom: "10px" }}>
          <p className={isActive ? "sc__active-row" : ""}>
            {/* {oem}[{date_from ? format(new Date(date_from), "yyyy-MM-dd") : ""}
            {date_to ? ` to ${format(new Date(date_to), "yyyy-MM-dd")}` : ""}] */}
            {`${sensorHeaderStr}`}
            [ {date_from ? format(new Date(date_from), "yyyy-MM-dd") : ""}
            {date_to ? ` to ${format(new Date(date_to), "yyyy-MM-dd")}` : ""} ]
          </p>
          {calibrationsList.length > 0 && (
            <button onClick={handleShowCreateForm} style={{textWrap:'nowrap'}}>
              <PlusGreenIcon />
              {<span>New Calibration</span>}
            </button>
          )}
        </div>
      )}

      {showCreateForm && (
        <CreateNewCalibration
          handleCalibration={handleCreateCalibration}
          showForm={showCreateForm}
          setShowForm={setShowCreateForm}
          validateCalibrationForm={validateCraeteCalibrationForm}
        />
      )}
      {/* {console.log(calibrationsList,'is calibrations List')} */}

      {calibrationsList
        .slice()
        // .sort((a, b) => a.uuid.localeCompare(b.uuid))  // Don't sort it on uuid, it is pre sorted by when the sensors are deployed
        .sort((a, b) => new Date(a.date_of_calibration).getTime() - new Date(b.date_of_calibration).getTime())
        .map((calibration) => (
          <SensorCalibrationList
            dis={dis}
            key={calibration.uuid}
            calibration={calibration}
            validateCalibrationForm={validateCalibrationForm}
            handleCalibration={handleCalibration}
            showForm={showForm}
            deleteCalibration={deleteCalibration}
            handleDeleteFile={handleDeleteFile}
            setShowForm={setShowForm}
            fromCalibration={fromCalibration}
          />
        ))}

      {calibrationsList.length === 0 && !fromCalibration && (
        <button onClick={handleShowCreateForm} className="new-config-row">
          <PlusGreenIcon />
          {show && <span> New Calibration </span>}
        </button>
      )}
    </article>
  );
};
