import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import "./Select.css";
import { DropDownSelectorDark } from "assets";

export const Select = ({
  error,
  getLeftIcon,
  selectedValue,
  onItemClick,
  onBlur,
  options,
  selectListStyles,
  tdLogSelectStyles,
  disabled,
  tdLogSelectIconStyles,
  autoOpen = false,
  freeSolo
}) => {
  const selectBox = useRef();
  const dropIcon = useRef();
  const [isOpen, setIsOpen] = useState(autoOpen);
  const [selectInput, setSelectInput] = useState(selectedValue);
  const [selectOptions, setSelectOptions] = useState(() => options);
  const listRef = useRef();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    function handleClose(e) {
      let x = selectBox.current;
      let y = dropIcon.current;
      if (x !== e.target && y !== e.target) {
        // if (!autoOpen) {
        setIsOpen(false);
        // }
      }
    }

    setTimeout(() => {
      window.addEventListener("click", handleClose);
    }, 2000);
    if (autoOpen) {
      setIsOpen(true);
    }

    return () => {
      setIsOpen(autoOpen);
      window.removeEventListener("click", handleClose);
    };
  }, []);

  useEffect(() => {
    if (isOpen === true || selectedValue !== "") setIsSelected(true);
    if (isOpen === true && selectedValue !== "") setIsSelected(true);
  }, [isOpen, selectedValue]);

  useEffect(() => {
    if (selectedValue !== selectInput) {
      setSelectInput(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const onValChange = (e) => {
    setSelectInput(e.target.value);

    if (freeSolo) {
      let q = e.target.value;
      const filtered = options.filter((obj) => obj.id.toLowerCase().includes(q.toLowerCase()));
      setSelectOptions(filtered);
      onItemClick(e.target.value, { id: e.target.value, label: e.target.value });
      setIsOpen(true);
    } else {
      if (e.target.value === "") {
        setSelectOptions(options);
      } else {
        let q = e.target.value;
        const filtered = options.filter((obj) => obj.id.toLowerCase().includes(q.toLowerCase()));
        setSelectOptions(filtered);
        setIsOpen(true);
      }
    }
  };
  return (
    <div className="select__select" onBlur={() => onBlur()}>
      <div
        className={clsx("select__select_main", {
          [tdLogSelectStyles]: tdLogSelectStyles,
          // eslint-disable-next-line no-useless-computed-key
          ["select__select_main_selected"]: isSelected,
          // eslint-disable-next-line no-useless-computed-key
          ["select__input-error"]: error,
        })}
        style={{cursor: freeSolo ? "text" : ""}}
        onClick={() => {
          // setIsOpen(!isOpen);
          !isOpen && setTimeout(() => listRef.current.focus(), 50);
        }}
      >
        <input
          placeholder="Select an option"
          value={selectInput}
          onChange={onValChange}
          style={{ background: "transparent", cursor: freeSolo ? "text" : "" }}
          ref={selectBox}
          onFocus={() => setIsOpen(true)}
        />
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
            !isOpen && setTimeout(() => listRef.current.focus(), 50);
          }}
          className={clsx("select__select-arrow", { [tdLogSelectIconStyles]: tdLogSelectIconStyles })}
          ref={dropIcon}
        >
          <DropDownSelectorDark onClick={() => setIsOpen(!isOpen)} />
        </span>
      </div>

      <ul
        className={`select__list-container ${selectListStyles}`}
        ref={listRef}
        style={{ display: isOpen ? "inherit" : "none" }}
        tabIndex={0}
      >
        {selectOptions.map((type) => {
          let labelDisplay;
          if(type.label === undefined || type.label === null) labelDisplay = type.id;
          else labelDisplay = type.label;
          return (
            <li
              className="select__list-item"
              onClick={() => {
                setIsOpen(false);
                onItemClick(type.id, type);
              }}
              key={type.id}
            >
              {getLeftIcon && getLeftIcon(type.id)}
              <h3>{labelDisplay}</h3>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
