import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useContext } from "react";
import { useCallback } from "react";
import { useMemo } from "react";

const { createContext, useState } = require("react");

const RightSidebarContext = createContext({ isCollapsed: true });

const ErrorContext = createContext({ setErrors: () => null, setDuration: () => null, setSuccess: () => null });

const RightSidebarContextProvider = RightSidebarContext.Provider;
const initialSnacbkarProps = {
  open: false,
  severity: "error",
  autoHideDuration: 6000,
}
const ErrorProvider = (props) => {
  const [snackbarProps, setSnackbarProps] = useState(initialSnacbkarProps);
  const [duration, setDuration] = useState(undefined);
  const setErrors = useCallback((errors) => {
    setSnackbarProps({
      ...snackbarProps,
      open: true,
      severity: "error",
      autoHideDuration: 6000,
      message: errors,
    });
  }, []);

  const setSuccess = useCallback((success) => {
    setSnackbarProps({
      ...snackbarProps,
      open: true,
      severity: "success",
      autoHideDuration: 6000,
      message: success,
    });

  }, []);
  // console.log(snackbarProps);
  return (
    <>
      <ErrorContext.Provider
        value={{
          setErrors,
          setDuration,
          setSuccess
        }}
      >
        <Snackbar
          open={snackbarProps.open}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => {
            setSnackbarProps(s=>({...s, open: false}));
          }}
          autoHideDuration={snackbarProps.autoHideDuration}
          severity={snackbarProps.severity}
        >
          <Alert
            severity={snackbarProps.severity}
            variant={"filled"}
            onClose={() => {
             setSnackbarProps(s=>({...s, open: false}));
            }}
          >
            <AlertTitle>{snackbarProps.severity==="error"?  "Error": "Success"}</AlertTitle>
            {snackbarProps.message}
          </Alert>
        </Snackbar>
        {useMemo(() => {
          return props.children;
        }, [props.children])}
      </ErrorContext.Provider>
    </>
  );
};

const storeUserSetting = (key = "", value = "") => {
  try {
    const userSettings = JSON.parse(localStorage.getItem("userSettings"));
    return localStorage.setItem("userSettings", JSON.stringify({ ...userSettings, [key]: value }));
  } catch (e) {
    return localStorage.setItem("userSettings", JSON.stringify({ [key]: value }));
  }
};

const getUserSetting = (key = "", _default = undefined) => {
  try {
    const userSettings = JSON.parse(localStorage.getItem("userSettings"));
    return userSettings[key] || _default;
  } catch (error) {
    return _default;
  }
};

const useSnackbar = () => {
  return useContext(ErrorContext)
}

export {
  RightSidebarContext,
  ErrorContext,
  ErrorProvider,
  storeUserSetting,
  getUserSetting,
  useSnackbar,
  RightSidebarContextProvider,
};
