import { useState } from "react";
import NoDataPreview from "component_tree/shared/NoDataPreview";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  makeStyles,
  TableSortLabel,
  Typography,
  TableRow,
} from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";
import './index.css';


const useStyles = makeStyles({
  customTableContainer: {
    overflowX: "initial",
    width: "calc(100% - 70px)",
  },
  tsLabel: {
    color: "#fff !important",
    "&:hover": {
      color: "#fff !important",
    },
  },
  tableContainer: {
    "& th:first-child": {
      borderTopLeftRadius: "4px",
    },
    "& th:last-child": {
      borderTopRightRadius: "4px",
    },
  },
  tableBodyContainer: {
    "& .ndc__inputFields": {
      width: "29% !important",
    },
  },
});

const AppList = ({data=[], app_uuid, runs=[]}) => {
  const classes = useStyles();
  const match = useRouteMatch([
    "/view_data",
    "/alerts",
    "/create",
    "/nodles",
    "/alerts",
    "/:projectId/:towerId/tower/:property", 
    "/:projectId/:towerId/tower/:property/:app_uuid",
    "/:projectId/map-view",
    "/:projectId/site_details",
    "/:projectId/create",
    "/:projectId/settings",
    "/:projectId/export-data",
    "/:projectId/:towerId",
    "/:projectId",
  ]);
  let projectId = match?.params?.projectId || "";
  let towerId = match?.params?.towerId || "";
  let property = match?.params?.property || "";
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");

  const handleSort = (property) => {
    setOrderBy(property);
    setOrder(orderBy === property && order === "asc" ? "desc" : "asc");
  };

  return (
    <TableContainer
      classes={{
        root: classes.customTableContainer,
      }}
      style={{
        overflowY: "scroll",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Table aria-label="Data Columns New" stickyHeader style={{ borderSpacing: "0 0" }}>
        <TableHead
          classes={{
            root: "ndc__root",
          }}
          style={{ borderRadius: "4px 4px 0 0" }}
          className={classes.tableContainer}
        >
          <TableRow>
            <TableCell align="left" style={{ padding: "9px 16px", background:'#111b30' }}>
              <TableSortLabel
                classes={{
                  root: classes.tsLabel,
                  active: classes.tsLabel,
                  icon: classes.tsLabel,
                  iconDirectionDesc: classes.tsLabel,
                  iconDirectionAsc: classes.tsLabel,
                }}
                active={orderBy === "name" ? true : false}
                direction={order}
                onClick={() => handleSort("name")}
              >
                <Typography variant="subtitle2" style={{ color: "#fff", whiteSpace: "nowrap" }}>
                  App Run Name
                </Typography>
              </TableSortLabel>
            </TableCell>

          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBodyContainer}>
        
        { data && <TableRow key={data.flow.uuid} style={{background:'#fff'}}>
              <TableCell scope="row" align="left" className="ndc__inputFields">
              <Link to={`/${projectId}/${towerId}/tower/app/${app_uuid}/${data.flow_uuid}`} className='Applink'>
                Start new run
              </Link>
              </TableCell>
            </TableRow>
          }

          {runs && runs?.map((run, index) => {
            if(!run.name){return null;}
             return (
               <TableRow key={run.uuid} style={{background:'#fff'}}>
                 <TableCell scope="row" align="left" className="ndc__inputFields">
                 <Link to={`/${projectId}/${towerId}/tower/app/${app_uuid}/${run.uuid}`} className='Applink'>
                   {run.name}
                 </Link>
                 </TableCell>
               </TableRow>
             )
              })
            }
        </TableBody>
      </Table>
      {data.length === 0 && <NoDataPreview />}
    </TableContainer>
  );
};

export default AppList;
