import { Link, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import "./Header.css";

import {
  LogoWhite,
  AlertGreyIcon,
  ResourceDataPlatform,
  DataWhiteIcon,
  PlotsGreyIcon,
  DataGreyIcon,
  AlertWhiteIcon,
  NodlesGreyIcon,
  PrismWhiteIcon,
  PrismDarkIcon,
  prism
} from "assets";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    display: "block",
    marginRight: "6px",
  },
  headerMenu: {
    marginLeft: "3.5rem",
    display: "flex",
    align: "center",
  },
  tower: {
    display: "block",
    padding: ".8rem 2.2rem",
    textAlign: "center",
    transition: "all .3s ease-in",
    "&.active,&:hover": {
      background: "rgba(255,255 ,255,  .18)",
      mixBlendMode: "normal",
    },
  },
  iconLabel: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "8px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFFFFF",
    marginTop: "8px",
    letterSpacing: "0.4px",
  },
  title: {
    fontSize: "14px",
    letterSpacing: "0.3px",
    opacity: "0.9",
    fontFamily: "Sanuk-SC",
    color: "var(--icon-white)",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    marginRight: "1.9rem",
  },
  dashboardWrap: {},
  header: {
    background: theme.palette.primary.dark,
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
  },
  headerWrap: {
    minHeight: "48px",
    padding: "0 1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoWrap: {
    display: "flex",
    alignItems: "center",
  },
  userName: {
    display: "flex",
    alignItems: "center",
    columnGap: "16px",
    "& a": {
      textDecoration: "none",
      width: "28px",
      height: "28px",
      color: "#fff",
      fontSize: "14px",
      textTransform: "uppercase",
      borderRadius: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgb(255,255 ,255,  .1)",
    },
  },
  logout: {
    background: "#00c48c",
    borderRadius: "4px",
    color: "#ffffff",
    border: "#00c48c",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 14px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "12px",
  },
  bodyWrap: {
    display: "flex",
    flexWrap: "wrap",
    height: "100vh",
  },
}));

export const Header = () => {
  const classes = useStyles();
  const location = useLocation();

  const logout = () => {
    localStorage.removeItem("user");
    window.location = window.location.origin + "/login";
  };

  return (
    <AppBar className={classes.header}>
      <Toolbar className={classes.headerWrap} disableGutters>
        <div className={classes.logoWrap}>
          <Link to="/">
            <picture className={classes.logo}>
              <LogoWhite />
            </picture>
          </Link>
          <Typography variant="h6" className={classes.title}>
            <ResourceDataPlatform />
          </Typography>
          <div className={classes.headerMenu}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <picture className={`${classes.tower} ${location.pathname !== "/alerts" ? "active" : ""}`}>
                {location.pathname !== "/alerts" ? <DataWhiteIcon /> : <DataGreyIcon />}
                <h4 className={classes.iconLabel}>Data</h4>
              </picture>
            </Link>
            <Link to="/alerts" style={{ textDecoration: "none" }}>
              <picture className={`${classes.tower} ${location.pathname === "/alerts" ? "active" : ""}`}>
                {location.pathname === "/alerts" ? <AlertWhiteIcon /> : <AlertGreyIcon />}
                <h4 className={classes.iconLabel}>Alerts</h4>
              </picture>
            </Link>
            {/* <Link to="/view_data" style={{ textDecoration: "none" }} target="_blank">
              <picture className={`${classes.tower} `}>
                <PlotsGreyIcon />
                <h4 className={classes.iconLabel}>Plots</h4>
              </picture>
            </Link> */}
            <Link to="/nodles" style={{ textDecoration: "none" }} target="_blank">
              <picture className={`${classes.tower} `}>
                <NodlesGreyIcon />
                <h4 className={classes.iconLabel}>Nodles</h4>
              </picture>
            </Link>
            <Link to="/prism" style={{ textDecoration: "none" }}  target="_blank">
              <picture className={`${classes.tower} `}>
               {/* <img src={prism} alt="prism-logo" className="prism"/> */}
               <PrismDarkIcon />
                <h4 className={classes.iconLabel} style={{ letterSpacing: "1px" }} >PRISM</h4>
              </picture>
            </Link>
          </div>
        </div>
        <div className={classes.userName}>
          <div className="header__dropdown">
            <Link to="#" className="header__dropbtn">
              A
            </Link>
            <ul className="header__dropdown-content">
              <li onClick={logout}>Log Out</li>
            </ul>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
