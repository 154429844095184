import { Button, Checkbox } from "@material-ui/core";
import { PlusCTAGreen } from "assets";
import { ErrorContext } from "contexts/misc/context";
import React, { useContext, useState } from "react";
import { handleErrorFromGQL } from "utils";
import {sortBy} from "lodash";
import "./DataColumns.css";

export const DataColumns = ({
  data,
  noTitle,
  updateDataColumn,
  statsDropdown = [],
  colsDropdown = [],
  updateDataColumnStats,
  isMeasurement,
  onCreate,
  selectedDataColumnIds, 
  onCheckboxChange,
  showCheckbox
}) => {
  const { setErrors } = useContext(ErrorContext);

  const [addDataColumnClicked, setAddDataColumnClicked] = useState(false);
  const [newValues, setNewValues] = useState({ col: "", stat: "" });
  const stats = statsDropdown.map((val, index) => {
    return (
      <option value={val.id} key={val.id}>
        {val.id}
      </option>
    );
  });
  const alreadyTaken = {};
  const cols = colsDropdown.map((val, index) => {
    if (alreadyTaken[val.column_name]) {
      return null;
    } else {
      alreadyTaken[val.column_name] = true;
      return (
        <option value={val.column_name} key={val.column_name}>
          {val.column_name}
        </option>
      );
    }
  });
  // const { data, loading, error } = useQuery(FETCH_DATA_COLUMNS, { variables: { m_id: measurementPointId } });
  const handleOnChange = async (newColName = "", uuid = "") => {
    const data = await updateDataColumn({ variables: { newColName, id: uuid } });
    if (data.errors) {
      //handleErrorFromGQL(data.errors, setErrors);
    }
  };

  const handleSave = async (stats = "", uuid = "") => {
    const data = await updateDataColumnStats({ variables: { id: uuid, newStatsName: stats } });
    if (data.errors) {
      //handleErrorFromGQL(data.errors, setErrors);
    }
  };
  return (
    <article className="sc__body">
      {!noTitle && (
        <div className="sc__head" style={{ paddingBottom: "20px" }}>
          <h3>Data Columns</h3>
        </div>
      )}

      {data.length === 0 ? (
        <p className={"no-data"}>No Columns Found</p>
      ) : (
        <div className={"dc__tables"}>
          <div className={"row header"}>
            <span className={"stats_col"} style={{marginLeft:showCheckbox? "40px": "0px"}}>Stats</span>
            <span className={"col_col"} style={{marginLeft:showCheckbox? "25px": "0px"}}>Column Name</span>
          </div>

          {sortBy(data, 'statistic_type_id').map((datum) => {
            return (
              <div className={"row"} key={datum.uuid}>
                {showCheckbox && <Checkbox
                  checked={selectedDataColumnIds.includes(datum.uuid)}
                  onChange={() => onCheckboxChange(datum.uuid)}
                />}
                <span style={{ width: "calc(36% - 10px)", marginRight: "10px" }}>
                  <select
                    className={"select-box"}
                    value={datum.statistic_type_id}
                    onChange={(e) => handleSave(e.target.value, datum.uuid)}
                  >
                    {stats}
                  </select>
                </span>
                <span className={"col_col"}>
                  <select
                    className={"select-box"}
                    value={datum.column_name}
                    onChange={(e) => handleOnChange(e.target.value, datum.uuid)}
                  >
                    {cols}
                  </select>
                </span>
              </div>
            );
          })}
        </div>
      )}
      {!isMeasurement &&
        (addDataColumnClicked ? (
          <div className="dc_tables newDataColumn">
            <div className={"row"}>
              <span style={{ width: "calc(36% - 10px)", marginRight: "10px" }}>
                <select
                  className={"select-box"}
                  value={newValues.stat}
                  onChange={(e) => setNewValues({ ...newValues, stat: e.target.value })}
                >
                  {stats}
                </select>
              </span>
              <span style={{ width: "64%" }}>
                <select
                  className={"select-box"}
                  value={newValues.col}
                  onChange={(e) => setNewValues({ ...newValues, col: e.target.value })}
                >
                  {cols}
                </select>
              </span>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => {
                  onCreate(newValues.stat, newValues.col)
                    .then(() => {
                      setAddDataColumnClicked(false);
                    })
                    .catch(() => {});
                }}
              >
                Save
              </Button>
              <Button
                variant={"contained"}
                color={"inherit"}
                onClick={() => {
                  setAddDataColumnClicked(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              marginTop: 10,
              cursor: "pointer",
              color: "#999",
              fontSize: 14,
              border: "1px solid #eee",
              padding: "4px 8px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setAddDataColumnClicked(true);
              setNewValues({ stat: statsDropdown[0]?.id || "", col: colsDropdown[0]?.column_name || "" });
            }}
          >
            {/* <Button variant="outlined" color="secondary" style={{ fontSize: 18 }}> */}
            <PlusCTAGreen />
            <span style={{ marginLeft: 6 }}>Add Data Column</span>
            {/* </Button> */}
          </div>
        ))}
    </article>
  );
};
