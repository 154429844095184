import FileDownload from "js-file-download";
import { uploadRaptInstance } from "utils/Axios";
// import { saveAs } from "file-saver";

let BASE_URL = process.env.REACT_APP_UPLOAD_URL;
let GROUP_ID = localStorage.getItem("maintenance");
const redirectToLogin = () => {
  localStorage.removeItem("user");
  window.location = window.location.origin + "/login";
}
const generateDownloadURL = (groupId, fileId) => {
  return `${BASE_URL}/groups/${groupId}/upload/${fileId}/source`;
};

export const uploadFile = async (file, groupId = "") => {
  const currentGroupId = groupId === "" ? GROUP_ID : groupId;
  const formData = new FormData();
  formData.append("file", file);
  try {
    let res = await uploadRaptInstance.post(`/groups/${currentGroupId}/upload/`, formData, 
    { headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
    }});
    return res;
  } catch (error) {
    return null;
  }
};

export const getFileContent = async (fileId) => {
  try {
    let res = await uploadRaptInstance.get(`/groups/${GROUP_ID}/upload/${fileId}/source`);
    let url = `data:${res.data.filetype};base64,` + res.data.fileContent;
    return url;
  } catch (error) {
    return null;
  }
};

export const downloadResource = (id = "", filename = "", groupId = "") => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentGroupId = groupId === "" ? GROUP_ID : groupId;
      const res = await uploadRaptInstance.get(generateDownloadURL(currentGroupId, id));
      const uri = `data:${res.data.filetype};base64,` + res.data.fileContent;
      fetch(uri)
        .then((res) => res.blob())
        .then((data) => {
          FileDownload(data, filename);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const deleteFile = async (fileId = "", groupId = "") => {
  const currentGroupId = groupId === "" ? GROUP_ID : groupId;

  let res = await uploadRaptInstance.delete(`/groups/${currentGroupId}/upload/${fileId}`);
  return res;
};

// Handle Files In Bulk

export const uploadFiles = async (pastDocs, files, fileCount, groupId, onUploadProgress = () => { }) => {
  const currentGroupId = groupId;
  let result = pastDocs;

  for (let i = 0; i < fileCount; i++) {
    const formData = new FormData();
    formData.append("file", files[i]);
    try {
      let res = await uploadRaptInstance.post(`/groups/${currentGroupId}/upload/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
          onUploadProgress: (progressEvt) => {
            onUploadProgress(progressEvt);
          },
        }
      );
      result.push(res.data.upload_id);
      
    } catch (err) {
    }
  }
  return result;
};

export const fetchAllFiles = async (fileCount, fileIds) => {
  const result = [];
  for (let i = 0; i < fileCount; i++) {
    try {
      let res = await uploadRaptInstance.get(`/groups/${GROUP_ID}/upload/${fileIds[i]}/preview`);
      let url = `data:${res.data.filetype};base64,` + res.data.fileContent;
      let f = {
        id: fileIds[i],
        fileName: res.data.filename,
        fileType: res.data.filetype,
        fileUrl: url,
      };
      result.push(f);
    } catch (error) {
    }
  }
  return result;
};

export const downloadFiles = async (files, fileCount) => {
  const result = [];
  for (let i = 0; i < fileCount; i++) {
    try {
      let res = await uploadRaptInstance.get(`/groups/${GROUP_ID}/upload/${files[i].id}/source`);
      let url = `data:${res.data.filetype};base64,` + res.data.fileContent;
      await fetch(url)
        .then((r) => r.blob())
        .then((r) => result.push({ blob: r, name: res.data.filename }));
    } catch (error) {
    }
  }
  return result;
};

export const deleteFiles = async (fileIds, fileCount, groupId) => {
  const currentGroupId = groupId;

  for (let i = 0; i < fileCount; i++) {
    await uploadRaptInstance.delete(`/groups/${currentGroupId}/upload/${fileIds[i]}`);
  }
  return "Files deleted";
};

// Get group id from FileAPI

export const createGroup = async (grpName) => {
  let res = await uploadRaptInstance.post("/groups/", {
    name: grpName,
    group_metadata: { items: [] },
  });
  return res;
};

export const getGroupFiles = async (grpId) => {
  const result = [];
  try {
    let res = await uploadRaptInstance.get(`/groups/${grpId}`);
    res.data.uploads.forEach((r) => {
      let url = `data:${r.filetype};base64,` + r.imgPreview;
      let f = {
        id: r.id,
        fileName: r.filename,
        fileType: r.filetype,
        fileUrl: url,
      };
      result.push(f);
    });
  } catch (error) {
  }
  return result;
};

export const getGroupId = async (grpName) => {
  let result = "";
  let res = await uploadRaptInstance.get("/groups/");
  res.data.data.forEach((d) => {
    if (d.name === grpName) result = d.id;
  });
  localStorage.setItem(grpName, result);
};

export const deleteGroup = async (grpId) => {
  await uploadRaptInstance.delete(`/groups/${grpId}`);
};
