import React, { useState, createContext } from 'react';
import { CircularProgress, Modal } from "@material-ui/core";
import { CloseIcon } from "../assets/icons";
import './useModal.css'
import { useCallback } from 'react';


const ModalContext = createContext({
  setTitle: () => null,
  setContent: () => null,
  setGlobalModalOpen: () => null,
  setOnConfirmClick: () => null,
  confirm: ({title, content, onConfirm= (isAgreed)=>{

  }}) => null
});

const ModalProvider = (props) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [modalOpen, setGlobalModalOpen] = useState(false);
  const [onConfirmClick, setOnConfirmClick] = useState(null);
  const [flag, setFlag] = useState(false);
  const [callbackFn, setCallbackFn] = useState(null);

  const handleModalClose = async () => {
    setGlobalModalOpen(false);
    setTitle("");
    setContent("");
    callbackFn && callbackFn(false);
  }

  const handleSubmitModal = async () => {
    setFlag(true);
    onConfirmClick();
    setGlobalModalOpen(false);
    setTitle("");
    setContent("");
    setOnConfirmClick(null);
    setFlag(false);
    callbackFn && callbackFn(true);
  }

  const confirm = useCallback(({title, content, onConfirm})=>{
    // return new Promise((resolve,reject)=>{
      setTitle(title);
      setContent(content);
      setGlobalModalOpen(true);
      const fn = ()=>{
        return ()=>{}
      } 
      setOnConfirmClick(fn);
      const callbackFn = (isAgreed)=>{
        onConfirm(isAgreed);
      }
      setCallbackFn(()=>callbackFn);  
    // })
  }, []);


  return (
    <>
      <ModalContext.Provider
        value={{
          setTitle,
          setContent,
          setGlobalModalOpen,
          setOnConfirmClick,
          confirm
        }}
      >
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="form-dialog-title"
          className="cp__modal"
        >
          <main>
            <section className="cp__new-project cp__confirm">
              <span className="cp__close-icon" onClick={() => handleModalClose()}>
                <CloseIcon />
              </span>
              <h2>{title}</h2>
              <h3>{content}</h3>

              <div>
                <button className="cp__confirm-btn cp__cancel-btn" onClick={() => handleModalClose()}>
                  <h3>Cancel</h3>
                </button>
                <button className="cp__confirm-btn cp__delete-btn" onClick={handleSubmitModal}>
                  {flag ? (
                    <CircularProgress style={{ width: "24px", height: "24px", color: "#fff" }} />
                  ) : (
                    <h3>Delete</h3>
                  )}

                </button>
              </div>
            </section>
          </main>
        </Modal>
        {props.children}
      </ModalContext.Provider>
    </>
  )
}

export const useConfirmation = ()=>{
  const { confirm } = React.useContext(ModalContext);
  return confirm;
}
export {
  ModalContext,
  ModalProvider
}