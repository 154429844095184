import { Paper, Typography, makeStyles, Box, Select, MenuItem, TextField, Icon } from "@material-ui/core";
import { getIconForSensorTypeId } from "utils";
import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { capUnderSentence } from "utils";
import CustomFilter from "component_tree/shared/CustomFilter";

const useStyles = makeStyles({
  mainFormPaper: {
    marginBottom: "15px",
    paddingBottom: "5px",
    display: "flex",
    flexDirection: "row",
    height: "45px",
  },
  head1: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#1E2447",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    width: "20%",
  },
  head2: {
    marginLeft: "30px",
    display: "inline-block",
  },
  clearFilt: {
    color: "rgba(30, 36, 71, 0.5)",
    background: "#eff0f2",
    borderColor: "#eff0f2",
    borderRadius: "4px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    cursor: "pointer",
    marginRight: "10px",
    padding: "5px 10px",
  },
  dropBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "80%",
  },
  filterSelect: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px !important",
    lineHeight: "16px inmportant",
    letterSpacing: "0.2px",
    color: "#1E2447",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  listItems: {
    "& li": {
      color: "#1E2447",
      opacity: "0.5",
      fontSize: "14px",
      overflowX: "hidden",
      "&:hover": {
        opacity: "1",
      },
    },
  },
  input: {
    fontSize: "13px",
  },
  siteNameHead: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "13px",
    letterSpacing: "0.3px",
    color: "#1E2447",
    mixBlendMode: "normal",
    opacity: 0.3,
  },
  selectIconStyles: {
    height: "16px",
    width: "16px",
    top: "calc(50% - 10px)",
  },
});

const FilterForm = ({
  sensorTypes,
  isFilterApplied,
  setIsFilterApplied,
  handleTableFilter,
  handleClearFilter,
  name,
  measurementTypes,
  pageName,
  control,
}) => {
  const classes = useStyles();
  const [col, setCol] = useState(" ");
  const [val, setVal] = useState([]);
  const [selectedVal, setSelectedVal] = useState(" ");
  const [sensorFilterSelect, setSensorFilterSelect] = useState(" ");
  const [measurementFilterSelect, setMeasurementFilterSelect] = useState(" ");
  const [search, setSearch] = useState("");
  const [measureSearch, setMeasureSearch] = useState("");

  // const handleColumnsChange = (e) => {
  //     setCol(e.target.value);
  //     setSelectedVal(" ");
  //     if (e.target.value === 'icon') setVal(sensorTypes);
  //     else if (e.target.value === 'sensorType') setVal(measurementTypes);
  // }

  // const handleSelectedValueChange = (e) => {
  //     setSelectedVal(e.target.value);
  //     setIsFilterApplied(true);
  //     handleTableFilter(col, e.target.value);
  // }

  const clearFilter = () => {
    setIsFilterApplied(false);
    setMeasurementFilterSelect(" ");
    setSearch("");
    setMeasureSearch("");
    setSensorFilterSelect(" ");
    setCol(" ");
    setVal([]);
    handleClearFilter();
  };

  const handleSensorFilterChange = (e) => {
    // setIsFilterApplied(true);
    setMeasurementFilterSelect(" ");
    setSensorFilterSelect(e);
    handleTableFilter("icon", e); //icon
  };

  const handleMeasurementFilterChange = (e) => {
    // setIsFilterApplied(true);
    setSensorFilterSelect(" ");
    setMeasurementFilterSelect(e);
    handleTableFilter(pageName === "Data Columns" ? "sensorType" : "type", e); //sensorType
  };

  const customFilterRenderOption = (option) => {
    if (option !== undefined) return capUnderSentence(option).toString();
  };

  const formatTextValue = (text) => {
    if (text && text.length >= 1) {
      let s = text.charAt(0).toUpperCase() + text.slice(1);
      s = s.replaceAll("_", " ");
      return s;
    }
    return "";
  };

  const renderStypeOptionFunction = (option) => {
    return (
      <>
        {getIconForSensorTypeId(option)}
        {formatTextValue(option)}
      </>
    );
  };

  const customMeasuremnetFilterRenderOption = (option) => {
    if (option !== undefined) return formatTextValue(option);
  };

  return (
    <>
      <Typography varaint={"h5"} className={classes.siteNameHead}>
        {name}
      </Typography>
      <Box
        className={classes.mainFormPaper}
        variant="outlined"
        width="100%"
        elevation={"8"}
        display="flex"
        flexDirection="row"
      >
        <Typography variant="h1" className={classes.head1}>
          {pageName}
        </Typography>
        <Box className={classes.dropBox}>
          {/* {isFilterApplied && (
            <>
              <Box>
                <div type="button" className={classes.clearFilt} onClick={clearFilter}>
                  Clear Filters
                </div>
              </Box>
            </>
          )} */}
          {/* <Box>
                        <Typography variant="h2" className={classes.head2} style={{ color: "black" }}>Columns :  </Typography>
                        <Select
                            name="columnSelect"
                            value={col}
                            disableUnderline
                            className="ndc__input"
                            style={{ marginLeft: "5px" }}
                            onChange={handleColumnsChange}
                        >
                            <MenuItem value=" " disabled>Select a Column</MenuItem>
                            <MenuItem value="icon"> Sensor Types</MenuItem>
                            <MenuItem value="sensorType"> Measurement</MenuItem>
                        </Select>
                    </Box> */}

          {/* <Box>
                        <Select
                            name="sensorFilterSelect"
                            value={sensorFilterSelect}
                            disableUnderline
                            className={classes.filterSelect}
                            style={{ marginLeft: "5px" }}
                            onChange={(e) => handleSensorFilterChange(e)}
                            classes={{
                                icon: classes.selectIconStyles
                            }}
                        >
                            <MenuItem value=" " disabled>Sensor Type</MenuItem>
                            {sensorTypes.map((sensor, index) => console.log(getIconForSensorTypeId(sensor)))}
                            {sensorTypes.map((sensor, index) => (

                                < MenuItem value={sensor} key={index} >
                                    {getIconForSensorTypeId(sensor)}
                                    {sensor}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box> */}
          {pageName === "Data Columns" && (
            <CustomFilter
              handleValueChange={setSearch}
              handleFilterModelChange={handleSensorFilterChange}
              value={formatTextValue(search)}
              text={search}
              label={"Sensor Type"}
              style={{ marginRight: "2%" }}
              options={sensorTypes}
              renderOptionFunction={renderStypeOptionFunction}
              getOptionLabel={customMeasuremnetFilterRenderOption}
              width={170}
            />
          )}

          {(pageName === "Maintenance" || pageName === "Alerts") && control}

          {/* <Autocomplete
                        onChange={(e, value) => { setSearch(value); handleSensorFilterChange(value) }}
                        value={search}
                        label={"Sensor Type"}
                        style={{ marginRight: '2%', }}
                        openOnFocus
                        autoComplete
                        options={sensorTypes}
                        getOptionLabel={option => option}
                        renderOption={option => {
                            return (
                                <Fragment>
                                    {getIconForSensorTypeId(option)}
                                    {option}
                                </Fragment>
                            );
                        }}
                        PaperComponent={({ children }) => (
                            <Paper className={classes.listItems}>{children}</Paper>
                        )}
                        renderInput={(props) => (
                            <TextField {...props} variant={"outlined"} label={'Sensor Type'} size={"small"} style={{ width: 150 }} className="auto-complete-input-label-1" />
                        )}
                    /> */}

          {/* <Box>
                        <Typography variant="h2" className={classes.head2} style={{ color: "black" }}>Value :  </Typography>
                        <Select
                            name="valSelect"
                            value={selectedVal}
                            disableUnderline
                            className="ndc__input"
                            style={{ marginLeft: "5px" }}
                            onChange={handleSelectedValueChange}
                        >
                            <MenuItem value=" " disabled>Select a Value</MenuItem>
                            {val.map((id, index) => (
                                <MenuItem value={id} key={index}>
                                    {id}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box> */}

          {/* <Box>
                        <Select
                            name="measurementFilterSelect"
                            value={measurementFilterSelect}
                            disableUnderline
                            className={classes.filterSelect}
                            style={{ marginLeft: "5px" }}
                            onChange={(e) => handleMeasurementFilterChnage(e)}
                            classes={{
                                icon: classes.selectIconStyles
                            }}
                        >
                            <MenuItem value=" " disabled>Measurement Type</MenuItem>
                            {measurementTypes.map((id, index) => (
                                <MenuItem value={id} key={index}>
                                    {id}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box> */}
          {pageName !== "Maintenance" && pageName !== "Sensor Data" && pageName !== "Alerts" && measurementTypes && (
            <CustomFilter
              handleValueChange={setMeasureSearch}
              handleFilterModelChange={handleMeasurementFilterChange}
              value={formatTextValue(measureSearch)}
              label={"Measurement Type"}
              options={measurementTypes}
              renderOptionFunction={customFilterRenderOption}
              getOptionLabel={customFilterRenderOption}
              width={170}
            />
          )}
          {/* <Autocomplete
                        onChange={(e, value) => { setMeasureSearch(value); handleMeasurementFilterChange(value) }}
                        value={measureSearch}
                        label={"Measurement Type"}
                        openOnFocus
                        autoComplete
                        options={measurementTypes.map((id) => id)}
                        renderOption={(option) => {
                            if(option !== undefined) return capUnderSentence(option).toString();
                        }}
                        PaperComponent={({ children }) => (
                            <Paper className={classes.listItems}>{children}</Paper>
                        )}
                        renderInput={(props) => (
                            <TextField {...props} variant={"outlined"} label={'Measurement Type'} size={"small"} style={{ width: 180 }} className="auto-complete-input-label-1" />
                        )}
                    /> */}
        </Box>
      </Box>
    </>
  );
};

export default FilterForm;
