import { LocationElevDrawing } from "component_tree/shared";
import React, { useEffect } from 'react'

import "./RightSideBar.css";

export const RightSideBar = ({ towerDetails, measurement = false }) => {
  useEffect(() => {

    //  
  }, [])
  return (
    <div className={`rsb__towerSidePane ${measurement ? "width-wide" : "width-narrow"}`}>

      {/* <div className="rsb__towerSidePane" style={{width: `${measurement ? "695px" : "250px"}`, }}> */}
      <div className="rsb__towerSideSubPane" style={{}}>
        <span className="towerNameSidePane">{towerDetails.name}</span>
        <span className="towerLocationPreview">Location Preview</span>
      </div>
      <div className="towerLocationContainer" style={{ justifyContent: `${measurement ? "center" : "start"}` }}>

        <LocationElevDrawing measurementLocationData={towerDetails} chartHeight={400} chartWidth={240} />
      </div>


    </div>
  );
};
