
const CALLIBRATION_GROUP_ID = process.env.REACT_APP_CALLIBRATION_GROUP_ID;

const CURRENT_MAPPING_MEASUREMENT_TYPES_SENSOR_TYPE = {
  wind_speed: "anemometer",
  wind_direction: "wind_vane",
  air_temperature: "thermometer",
  temperature: "thermometer",
  air_pressure: "barometer",
  pressure: "barometer",
  relative_humidity: "hygrometer",
  pyranometer: "pyranometer",
  direct_normal_irradiance: "pyranometer",
  device_humidity: "pyranometer",
  device_temperature: "pyranometer",
  fan_speed: "pyranometer",
  heater_current: "pyranometer",
  tilt: "pyranometer",
  irradiance: "pyranometer",
  irradiance_uc: "pyranometer",
  pv_clean_current: "ammeter",
  pv_soiled_current: "ammeter",
  battery_voltage: "voltmeter",
  rain: "rain_gauge",
  
};

const getCurrentSensorType = (measurement_type = "") => {
  return CURRENT_MAPPING_MEASUREMENT_TYPES_SENSOR_TYPE[measurement_type];
};

const LIST_SUPPORTED_FORMAT = [
  {
    text: "SymphoniePro Text Files",
    isSoon: false,
  },
  {
    text: "Symphonie Data Retriever",
    isSoon: false,
  },
  {
    text: "Campbell Logger Text File",
    isSoon: false,
  },
  {
    text: "Windographer Exported Text File",
    isSoon: false,
  },
  {
    text: "Data in Table Format (CSV, Parquet)",
    isSoon: false,
  },
  {
    text: "NRG -  RLD/RWD ",
    isSoon: true,
  },
];

const USER_SETTINGS = {
  SENSOR_DATA_COLUMN_PAGE: "sensorDataRowsPerPage",
};

export { CALLIBRATION_GROUP_ID, getCurrentSensorType, LIST_SUPPORTED_FORMAT, USER_SETTINGS };


export const TIMEZONE_OPTIONS = [
  'US/Alaska', 'US/Arizona', 'US/Central', 'US/Eastern', 'US/Hawaii', 'US/Mountain', 'US/Pacific', 'UTC'
]