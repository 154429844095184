import styled from "styled-components";

import { makeStyles } from "@material-ui/core";

import { DropdownSelectIcon } from "assets";

const useStyles = makeStyles((theme) => ({
  towerBodyBasic: {
    padding: "20px 20px 30px 20px",
    borderTop: "1px solid rgb(30 , 36 , 71 , .1)",
    "&.active": {
      paddingRight: "210px",
    },
    "& h3": {
      color: theme.palette.primary.main,
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: "1.07",
      letterSpacing: "normal",
      marginBottom: "1.4rem",
    },
  },
  inputField: {
    "& > not:first-child": {
      marginTop: "16px",
    },
  },
}));

const FieldSet = styled.fieldset`
  width: min-content;
  position: relative;
  label {
    opacity: 0.5;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #000000;
  }
  input,
  select {
    margin-top: 5px;
    width: 210px;
    height: 24px;
    padding: 4px 0px 4px 8px;
    border-radius: 2px;
    background-color: rgba(30, 36, 71, 0.06);
    font-family: "Roboto";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.9);
  }
  input[type="date"] {
    padding: 3px 0px 5px 8px !important;
  }
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none;
    -webkit-appearance: none; */
  }
  select {
    outline: none;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .select-icon {
    position: absolute;
    right: 10px;
    top: 28px;
  }

  .input-suffix-icon {
    position: absolute;
    right: 10px;
    top: 25px;
  }
`;

const InputField = ({ label, className, suffixIcon, ...rest }) => {
  return (
    <FieldSet className={className}>
      <label>{label}</label>
      <input {...rest} />
      {/* {suffixIcon && <span className="input-suffix-icon"> {suffixIcon}</span>} */}
    </FieldSet>
  );
};

const SelectField = ({ label, className, children, ...rest }) => {
  return (
    <FieldSet className={className}>
      <label>{label}</label>
      <select {...rest}>{children}</select>
      <DropdownSelectIcon className="select-icon" />
    </FieldSet>
  );
};

const SensorBasicDetailWrapper = styled.div`
  padding: 20px 15px 30px 15px;
  border-top: 1px solid rgb(30, 36, 71, 0.1);
  h3 {
    color: var(--dark-blue);
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
  }
  .form-field-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-right: 5px;
  }
  .form-field-row {
    display: flex;
    width: 210px;
    justify-content: space-between;
    .input-field {
      input {
        width: 100px;
      }
    }
  }
  .input-field {
    margin-bottom: 16px;
  }
  ${({ show }) =>
    show &&
    `
    padding: 20px 25px 40px 25px;
    .form-field-right {
        margin-left: 25px;
    }

  }
    `}
`;

export const SensorBasicDetail = ({ show, pointData, measurementTypesList }) => {
  const classes = useStyles();

  const { name, measurement_type_id, height_m } = pointData;

  return (
    <SensorBasicDetailWrapper className={classes.towerBodyBasic} show={show}>
      <h3>Basic Details</h3>
      <div className="form-field-list">
        <div>
          <InputField className="input-field" label="Measurement Name" value={name} />
        </div>
        <div className="form-field-right">
          <SelectField className="input-field" label="Sensor Type" value={measurement_type_id}>
            {measurementTypesList.map((type) => (
              <option>{type.id}</option>
            ))}
          </SelectField>
          <div className="form-field-row">
            {/* <DatePicker
              // onChange={(newDate) => formVal.setValue(newDate)}
              date={formVal.value}
              // error={formVal.error}
            /> */}
            <InputField className="input-field" label="Height (meters)" value={height_m} />
          </div>
        </div>
      </div>
    </SensorBasicDetailWrapper>
  );
};
