import { useState , useContext } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid , Snackbar, Typography } from "@material-ui/core";

import { InputField, Spinner } from "component_tree/shared";
import { EmailLoginIcon, LoginLockIcon, PasswordVisibleIcon, PasswordInvisibleIcon, Logo, loginCover, ErrorIcon } from "assets";
import { ErrorContext } from "contexts/misc/context";
import auth0 from "auth0-js";
import ChangePasswordModal from "pages/Authentication/ChangePassword";
import { CheckIcon } from "assets/new";
// import params from "./auth0-params.json";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loginContainer: {
    flexWrap: "unset",
  },
  loginForm: {
    padding: "3.5rem",
    paddingTop: "6.1rem",
    paddingLeft: "5rem",
    paddingRight: "5rem",
    paddingBottom: "3rem",
    height: "100vh",
    maxWidth: "62rem",
    fontFamily: "Roboto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      flexBasis: "62rem",
    },
  },
  loginRight: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "calc(100% - 62rem)",
      flexBasis: "calc(100% - 62rem)",
    },
  },
  formRoot: {
    flexGrow: 1,
    display: "flex",
    marginTop: "60px",
  },
  form: {
    flexGrow: 1,
    paddingLeft: "6rem",
    paddingRight: "6rem",
    "& h2": {
      fontSize: "3rem",
      fontWeight: "bold",
      lineHeight: "39px",
      color: theme.palette.primary.main,
    },
    "& p": {
      marginTop: "1.2rem",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      opacity: ".7",
      lineHeight: "26px",
      letterSpacing: "normal",
      color: theme.palette.primary.main,
    },
  },
  logo: {
    display: "block",
    cursor: "pointer",
    width: "5rem",
    height: "5rem",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  image: {
    height: "100vh",
    opacity: ".8",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  },
  field: {
    marginTop: "3.7rem",

    "& label": {
      fontSize: "1.3rem",
      fontWeight: "500",
      lineHeight: "18px",
      letterSpacing: "0.2px",
      color: theme.palette.primary.main,
      opacity: ".5",
    },
  },
  button: {
    marginTop: "2rem",
    opacity: "0.9",
  },
  copyright: {
    fontSize: "11px",
    fontFamily: "Roboto",
    color: theme.palette.primary.main,
    opacity: 0.5,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  loginWrap: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minWidth: "57rem",
  },
  brandBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "14px",
    paddingBottom: "50px",
    position: "relative",
    "&::before": {
      background: "rgba(243, 243, 243, 0.8)",
      width: "calc(100% + 60px)",
      height: "1px",
      content: "''",
      position: "absolute",
      bottom: "0",
      left: "-30px",
    },
    "&>.title": {
      fontSize: "44px",
      fontWeight: "600",
      letterSpacing: "4px",
      color: "#111B30",
      // opacity: ".9",
    },
    "&>.subtitle": {
      color: "#1E2447",
      opacity: "0.7",
      textTransform: "uppercase",
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: ".4px",
    },
  },
}));

const Login = ({ toggleLogin }) => {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();
  const [emailError, setEmailError] = useState("");
  const { setErrors } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('RAPT - Login')

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  var auth0Client = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH_API_AUDIENCE,
    redirectUri: process.env.REACT_APP_AUTH_CALLBACK_URL,
    scope: process.env.REACT_APP_AUTH_SCOPE,
    responseType: "token id_token",
  });

  const login = () => {
    setLoading(true);
    auth0Client.client.login(
      {
        realm: process.env.REACT_APP_AUTH_REALM,
        username: email,
        password: password,
      },
      (err, authResult) => {
        if (err) {
          toggleLogin(false, undefined);
          setErrors("Invalid Credential, Please try again");
          setLoading(false);
          return;
        }
        if (authResult) {
          setTitle('Rapt- Project Home')
          setLoading(false);
          const user = {
            token: authResult.accessToken,
          };
          localStorage.setItem("user", JSON.stringify(user));
          toggleLogin(true, authResult.accessToken);
        }
        
      }
    );
  };

  const [openModal, setOpenModal] = useState(false);
  const [createToast, setCreateToast] = useState("");
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <section className={classes.root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={createToast?.message}
        autoHideDuration={3000}
        onClose={() => setCreateToast(null)}
        message={
          <div className="cp__snack--content">
            {createToast?.type === "success" ? <CheckIcon style={{height:30,width:30}}/> : <ErrorIcon/>}
            <p>{createToast?.message}</p>
          </div>
        }
        key={createToast?.message}
        className={createToast?.type === "success" ? "cp__snack--success" : "cp__snack--error"}
      />
      <Grid container spacing={0} className={classes.loginContainer}>
        <Grid item xs={12} sm={12} md={6} lg={5} className={classes.loginForm}>
          <div className={classes.loginWrap}>
            <div className={classes.brandBox}>
              <div className={"logo"}>
                <Logo />
              </div>
              <div className={"title"}>RAPT</div>
              <div className={"subtitle"}>Resource Assesment Platform</div>
            </div>
            <div className={classes.formRoot}>
              <form onSubmit={handleSubmit(login)} className={classes.form}>
                {/* <Typography variant="h2">Login to your account</Typography> */}
                {/* <p>Login to manage your resource assessment data.</p> */}
                <div className={classes.field}>
                  <InputField
                    name="email"
                    fieldName="Email"
                    type="text"
                    icon={<EmailLoginIcon />}
                    inputRef={register({ required: "required" })}
                    placeholder="Your email address here"
                    error={errors?.email?.message}
                    setValue={setEmail}
                    // error="The input is not a valid email!"
                  />
                  <InputField
                    name="password"
                    fieldName="Password"
                    type="password"
                    icon={<LoginLockIcon />}
                    visibleEye={<PasswordVisibleIcon />}
                    hideEye={<PasswordInvisibleIcon />}
                    placeholder="Your password here"
                    // error="Bad Email or password!"
                    inputRef={register({ required: "required" })}
                    error={errors?.password?.message}
                    setValue={setPassword}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    size="large"
                    type="submit"
                    className={classes.button}
                    disabled={loading}
                    disableElevation
                  >
                   {loading ? <Spinner color="primary" /> : <Typography variant="button">Login</Typography>}
                  </Button>

                  <div style={{fontSize:14, margin:15, textAlign:'center'}}><span style={{color:'#1e2447',opacity:'0.5'}}>Forget your Password? </span><span style={{color:'#1e2447',cursor:'pointer',opacity:0.8}} onClick={handleOpenModal}>Click Here</span></div>
                  <ChangePasswordModal open={openModal} onClose={handleCloseModal} setCreateToast={setCreateToast}/>
                  {/* <div className={classes.button}>
                  <ButtonField type="submit" nameValue="Login" />
                </div> */}
                </div>
              </form>
            </div>

            <p className={classes.copyright}>Altosphere ©2021 </p>
          </div>
        </Grid>
        <Grid item xs={undefined} sm={undefined} md={6} lg={12} className={classes.loginRight}>
          {/* <Hidden smDown> */}
          <div className={classes.image}>
            <img src={loginCover} alt="windTower" />
          </div>
          {/* </Hidden> */}
        </Grid>
      </Grid>
      
    </section>
  );
};

export default Login;
