import { appTypoGraphy } from "theme/typography/typography";
import themePalette from "./palette/themePalette";

const muiTheme = (mode) => ({
  typography: appTypoGraphy,
  palette: {
    type: mode,
    primary: themePalette(mode).palette.primary,
    secondary: themePalette(mode).palette.secondary,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize",
        fontFamily: "Roboto",
        color: "var(--white)",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        height: "3rem",
        fontSize: "1.3rem",
      },
      sizeLarge: {
        height: "4.5rem",
        fontSize: "1.5rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#1E2447",
        opacity: "0.5",
        fontSize: "14px",
        "&:hover": {
          opacity: "1",
        },
      },
    },
    MuiInput: {
      root: {
        height: "28px",
        display: "flex",
        alignItems: "center",
      },
    },
    MuiInputBase: {
      root: {
        height: "28px",
        display: "flex",
        alignItems: "center",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        height: "auto",
      },
      clearIndicator: {
        position: "relative",
        top: "4px",
      },
      popupIndicator: {
        position: "relative",
        top: "4px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "4px 16px",
      },
    },
  },
});

export default muiTheme;
