import { useEffect, useRef } from "react";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function handleErrorFromGQL(error = { graphQLErrors: [], networkError: "" }, setErrors = () => null) {
  let msg = "";
  const { graphQLErrors, networkError } = error;
  if (error.message) {
    return setErrors(error.message);
  }
  if (Array.isArray(error)) {
    error.forEach(({ message }) => {
      msg += message;
    });
  } else if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      msg += message;
    });
  } else if (networkError) {
    msg += "Network Error, Check your connection";
  }

  if (msg !== "") {
    setErrors(msg);
  }
}
