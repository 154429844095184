import { useEffect, useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import { RightSideBar } from "component_tree/shared";
import {  handleErrorFromGQL } from "utils";
import { ErrorContext } from "contexts/misc/context";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { FETCH_RELEVANT_MEASUREMENT_APP_BY_LOCATION, FETCH_RELEVANT_MEASUREMENT_APP_RUN_BY_LOCATION, GET_PLANT_BY_ID, GET_TOWER_BY_ID } from "services";
import HeaderTemplate from "component_tree/shared/HeaderTemplate";
import NotFound from "pages/NotF0und";
import AppList from "pages/all_projects/project/tower/Apps/Applist";
import instance from "utils/Axios";

const useStyles = makeStyles(() => ({
  boxContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Apps = () => {
  const { projectId, towerId, app_uuid } = useParams();
  const { setErrors } = useContext(ErrorContext);
  // const [apps, setApps] = useState([]);
  const [isAppsLoading, setIsAppsLoading] = useState(false);

  const {data} = useQuery(FETCH_RELEVANT_MEASUREMENT_APP_BY_LOCATION, {
    variables: { measurement_location_uuid_with_per: "%"+ towerId +"%" }
  })

  const { data:runs } = useQuery(FETCH_RELEVANT_MEASUREMENT_APP_RUN_BY_LOCATION, {
    variables: { measurement_location_uuid_with_per: "%"+ towerId +"%", app_uuid: app_uuid },
  });

  const apps = data?.published_app_latest_versions;

  const app = apps?.find(app => app.uuid === app_uuid);

  const appruns = runs?.nodles_published_apps[0]?.nodles_app_runs;

  const getPublishedApps = async () =>{
    if(app){
      return app;
    }else{
      return [];
    }
  };
 
  // useEffect(() => {
  //     if(app){
  //       setApps(app);
  //     }else{
  //       setApps([]);
  //     }
  //       setIsAppsLoading(false);
  // },[]);


  let history = useHistory();
  const classes = useStyles();

  const {
    data: towerData,
    loading: towerLoading,
    error: towerError,
    refetch: refetchMeasurement,
  } = useQuery(GET_TOWER_BY_ID, {
    variables: { towerId }, onError: (err) => {
      handleErrorFromGQL(err, setErrors);
    },
  });

  const {loading} = useQuery(GET_PLANT_BY_ID, {
    variables: { plantId: projectId }, onError: (err) => {
      handleErrorFromGQL(err, setErrors);},
  });
  
  const towerDetails = towerData?.measurement_location?.[0];

  useEffect(() => {
    refetchMeasurement();
  }, []);

  if (towerLoading || loading || isAppsLoading) {
    return (
      <article className={classes.boxContainer}>
        <CircularProgress />
      </article>
    );
  }

  if (towerError) {
    return <NotFound />;
  }
  const dataString = `${projectId}/${towerId}/apps`;
  return (
    <>
      <div
        style={{
          width: "calc(100% - 250px)",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <HeaderTemplate
          dataString={dataString}
            name={towerDetails.name}
            pageName={"App Runs"}
            breadcrumbList={[
              {
                name: "All Projects",
                onClick: () => {
                  history.push("/");
                },
              },
              {
                name: towerDetails.plant.name,
                onClick: () => {
                  history.push(`/${projectId}`);
                },
              },
              {
                name: towerDetails?.name,
                onClick: () => {
                  history.push(`/${projectId}/${towerId}`);
                },
              },
              { name: "App Runs" },
            ]}
          />
        </div>
      {app && <AppList
      app_uuid={app_uuid}
      data={app}
      runs={appruns}
      name={towerDetails.name}
      towerDetails={towerDetails}
      />}
      </div>
      {
        <div className="rsb__pane">
          <RightSideBar towerDetails={towerDetails} />
        </div>
      }
    </>
  );
};

export default Apps;
