import Wind_Speed from "assets/icons/sensors/WindSpeed.svg";
import Wind_Direction from "assets/icons/sensors/WindDirection.svg";
import TemperatureIcon from "assets/icons/sensors/Temperature.svg";
import VoltsIcon from "assets/icons/volts.svg";
import RelativeHumidityIcon from "assets/icons/relative_humidity.svg";
import PressureIcon from "assets/icons/sensors/Pressure.svg";
import AlertCircleIcon from "assets/icons/alert_circle.svg";
import UnknownSensor from "assets/icons/unknown_sensor.svg";

export const alertsColorSettings = (flagName) => {
  let flag = flagName.toLowerCase();
  if (flag.includes("icing")) {
    return "#5eb0da";
  } else if (flag.includes("flatline")) {
    return "#f57c6b";
  } else if (flag.includes("out_of_range") || flag.includes("missing")) {
    return "#aeb6b3";
  } else {
    return "#9281c0";
  }
};

// An object to track details on each
let sensorSettings = {
  wind_speed: {
    icon: Wind_Speed,
    rank: 1,
    label: "Wind Speed",
    label_compact: "Wspd",
    boom_length: 6,
    icon_url: Wind_Speed,
  },
  wind_direction: {
    icon: Wind_Direction,
    rank: 2,
    label: "Wind Dir",
    label_compact: "WDir",
    boom_length: -4,
    icon_url: Wind_Direction,
  },
  air_temperature: {
    icon: TemperatureIcon,
    rank: 3,
    label: "Amb. Temperature",
    label_compact: "Temp",
    boom_length: 1,
    icon_url: TemperatureIcon,
  },
  air_pressure: {
    icon: PressureIcon,
    rank: 4,
    label: "Amb. Pressure",
    label_compact: "Press",
    boom_length: 0,
    icon_url: PressureIcon,
  },
  voltage: {
    icon: VoltsIcon,
    rank: 5,
    label: "Voltage",
    label_compact: "Volts",
    boom_length: 0,
    icon_url: VoltsIcon,
  },
  relative_humidity: {
    icon: RelativeHumidityIcon,
    rank: 6,
    label: "Rel. Humidity",
    label_compact: "RH",
    boom_length: 0,
    icon_url: RelativeHumidityIcon,
  },
  unknown: {
    //What to show if missing
    icon: null,
    rank: 0,
    label: "Unrecognized Sensor.",
    label_compact: "Unknown",
    boom_length: 0,
    icon_url: UnknownSensor,
  },
};

// Sequence of colors to indicate sensor changes (e.g. SensorHistoryTimeline.js)
export const sensorChangeColors = [
  "#8e9cea",
  "#c2ccff",
  "#8e9cea",
  "#416AB6",
  "#618AD5",
  "#65AFAF",
  "#80D299",
];

// Settings on how to format the timeline chart
export const timelineChartSettings = {
  axisLabelColor: "darkgray",
  axisLabelFont: "roboto",
};

// The charting library does not accept react svg url, so this has to the format
export const icon_url = {
  settings_change_icon_url: "icons/settings_change.svg",
  maintenance_icon_url: "icons/maintenance.svg",
};

/**
 * Return icon given measurementTypeId
 * @param {string} measurementTypeId - Describes what kind of measurement (e.g. wind_speed, wind_direction etc.) per Task 43 definitions
 * @return {icon} Path to icon representing the measurement type
 */
export const getSensorIcon = (measurementTypeId) => {
  return measurementTypeId in sensorSettings
    ? sensorSettings[measurementTypeId]["icon"]
    : AlertCircleIcon;
};

/**
 * Return iconURL given measurementTypeId. This is relevant for displaying the icon through charting e.g Highcharts
 * @param {string} measurementTypeId - Describes what kind of measurement (e.g. wind_speed, wind_direction etc.) per Task 43 definitions
 * @return {icon} Path to icon representing the measurement type
 */
export const getSensorIconURL = (measurementTypeId) => {
  return measurementTypeId in sensorSettings
    ? sensorSettings[measurementTypeId]["icon_url"]
    : sensorSettings["unknown"]["icon_url"];
};

/**
 * Return icon given measurementTypeId
 * @param {string} measurementTypeId - Describes what kind of measurement (e.g. wind_speed, wind_direction etc.) per Task 43 definitions
 * @return {icon} Path to icon representing the measurement type
 */
export const getSensorBoomLength = (measurementTypeId) => {
  return measurementTypeId in sensorSettings
    ? sensorSettings[measurementTypeId]["boom_length"]
    : sensorSettings["unknown"]["boom_length"];
};

/**
 * Return label given measurementTypeId
 * @param {string} measurementTypeId - Describes what kind of measurement (e.g. wind_speed, wind_direction etc.) per Task 43 definitions
 * @return {string} A label describing the path. E.g. 'wind_speed' -> 'Wind Speed'
 */
export const getSensorLabel = (measurementTypeId) => {
  return measurementTypeId in sensorSettings
    ? sensorSettings[measurementTypeId]["label"]
    : measurementTypeId;
};

/**
 * Return how the measurementTypeId should be ranked when displaying data.
 * @param {string} measurementTypeId - Describes what kind of measurement (e.g. wind_speed, wind_direction etc.) per Task 43 definitions
 * @return {number} A number describing sensor type. Rank 1 should be displayed above displayRank 2. Unknown values are 1000
 */
export const getSensordisplayRank = (measurementTypeId) => {
  return measurementTypeId in sensorSettings
    ? sensorSettings[measurementTypeId]["displayRank"]
    : 1000;
};
