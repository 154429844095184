// import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import muiTheme from "./muiTheme";

const ThemeProvider = ({ children }) => {
  const theme = createTheme(muiTheme("light"));
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
