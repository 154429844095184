import { getSideBarIconForPlant, getIconsForCreateLocation } from "utils";

export const newPlantInitialValues = {
  projectName: "",
  latitude: "",
  longitude: "",
  plantType: "",
  notes: "",
};

export const newPlantFormItems = (plantTypeList) => [
  {
    label: "Plant Type",
    type: "select",
    name: "plantType",
    options: plantTypeList,
    autoOpen: true,
    getLeftIcon: getSideBarIconForPlant,
  },
  {
    label: "Project Name",
    type: "input",
    inputType: "text",
    name: "projectName",
    placeholder: "Project Name 1",
  },
  {
    label: "Latitude",
    labelToltip: true,
    type: "input",
    inputType: "number",
    name: "latitude",
    placeholder: "Latitude",
  },
  {
    label: "Longitude",
    labelToltip: true,
    type: "input",
    inputType: "number",
    name: "longitude",
    placeholder: "Longitude",
  },
  {
    label: "Notes",
    type: "textarea",
    name: "notes",
    placeholder: "Notes",
  },
];

export const newLocationInitialValues = {
  locationName: "",
  latitude: "",
  longitude: "",
  locationType: "",
  notes: "",
};

export const newLocationFormItems = (locationTypeList) => [
  {
    label: "Location Type",
    type: "select",
    name: "locationType",
    options: locationTypeList,
    getLeftIcon: getIconsForCreateLocation,
    autoOpen: true,
  },

  {
    label: "Location Name",
    type: "input",
    inputType: "text",
    name: "locationName",
    placeholder: "Location Name",
  },

  {
    label: "Latitude",
    labelToltip: true,
    type: "input",
    inputType: "number",
    name: "latitude",
    placeholder: "Latitude",
    inputMode:"decimal",
    inputPattern:"[+-]?([0-9]*[.])?[0-9]+"
  },
  {
    label: "Longitude",
    labelToltip: true,
    type: "input",
    inputType: "number",
    name: "longitude",
    placeholder: "Longitude",
    inputMode:"decimal",
    inputPattern:"[+-]?([0-9]*[.])?[0-9]+"
  },
  {
    label: "Notes",
    type: "textarea",
    name: "notes",
    placeholder: "Notes",
  },
];
