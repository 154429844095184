import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  stagesContainer:{
    position: 'relative',
  },
  stages: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',    
    marginBottom: '20px',
    zIndex:'20',
    position: 'relative', // Added position relative
  },
  stage_item: {
    background: '#FFFFFF',
    border: '1px solid #BCBDC8',
    borderRadius: '5px',
    padding: '5px 40px',
    color:'black',
    '&:hover':{
      color:'black',
    }
  },
  line:{
    position: 'relative',
    height:1,
    background: '#BCBDC8',
    width: '100%',
    bottom: 36,
  },
});

const Stages = () => {
  const classes = useStyles();
  // const property = 'sensor';
  const type = 'success';

  const location = useLocation();
  const url = location.pathname;
  const property = url.split('/tower/')[1].split('/')[0];
  const projectId = url.split('/tower/')[0].split('/')[1];
  const measurementLocationUUID = url.split('/tower/')[0].split('/')[2];

  const activeStyle = (state, active)=>{
    const isActive = property === active;
    if(state === 'success'){
      return {
        border: '1px solid #00C48C',
        background: isActive ?'#EDFFFA':'',
        color: isActive ? '#00C48C':'black'
      }
    }
  if(state === 'warn'){
      return {
        border: '1px solid #F17413',
        background: isActive ?'#FFF5EE':'',
        color: isActive ? '#F17413':'black'
      }
    }
  };

  if(property === 'measurement')return null;
  return (
  <div className={classes.stagesContainer}>
    <div className={classes.stages}>
      <Link to={`/${projectId}/${measurementLocationUUID}/tower/sensor-data`}className={classes.stage_item} style={activeStyle('success', 'sensor-data')}>Sensor Data</Link>
      <Link to={`/${projectId}/${measurementLocationUUID}/tower/maintenance`}className={classes.stage_item} style={activeStyle('warn', 'maintenance')}>Maintenance</Link>
      <Link to={`/${projectId}/${measurementLocationUUID}/tower/calibration`}className={classes.stage_item} style={activeStyle('success', 'calibration')}>Calibration</Link>
      <Link to={`/${projectId}/${measurementLocationUUID}/tower/data_columns`}className={classes.stage_item} style={activeStyle('warn', 'data_columns')}>Data Completeness</Link>
      <Link to={`/${projectId}/${measurementLocationUUID}/tower/site_details`}className={classes.stage_item} style={activeStyle('success', 'site_details')}>Site History</Link>
    </div>
    <div className={classes.line}></div> {/* Add the line */}
  </div>
  );
};

export default Stages;
