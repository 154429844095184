import ConfirmationDialog from 'component_tree/shared/ConformationDialogue';
import React, { createContext, useContext, useState, useCallback } from 'react';
// import ConfirmationDialog from '../components/general/ConfirmationDialogue';

export const ConfirmationDialogueContext = createContext({
  confirm: (confirmOpts) => {},
});

const initialState = {
  title: '',
  content: '',
  open: false,
  onClose: () => {},
  acceptButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
};

export const ConfirmationDialogueProvider = ({ children }) => {
  const [confirmOptState, setConfirmOptState] = useState(initialState);

  const confirm = useCallback((confirmOpts) => {
    const newOnClose = (isConfirmed) => {
      setConfirmOptState(initialState);
      confirmOpts.onClose(isConfirmed);
    };
    setConfirmOptState({
      ...confirmOpts,
      open: true,
      onClose: newOnClose,
    });
  }, []);

  return (
    <ConfirmationDialogueContext.Provider value={{ confirm }}>
      <ConfirmationDialog {...confirmOptState} />
      {children}
    </ConfirmationDialogueContext.Provider>
  );
};

export const useConfirmationDialogue = () => {
  return useContext(ConfirmationDialogueContext);
};
