import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

const Progress = styled(CircularProgress)`
  position: absolute;
`;

export const Spinner = (props) => {
  return <Progress size={props.size || 22} {...props} />;
};
