export const appTypoGraphy = {
    fontFamily: 'Roboto',
    htmlFontSize: 16,
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
    h1: {
        fontWeight: "700",
        fontSize: "17px",
        lineHeight: "17px",
        letterSpacing: "0.1px",
        color: "#001122",
        mixBlendMode: "normal",
        opacity: "1"
    },
    h2: {
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF"
    },
    h3: {
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#1e2447",
        mixBlendMode: "normal",
        opacity: "0.4"
    },
    subtitle1: {
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "16px",
        color: "#1E2447",
        mixBlendMode: "normal",
        opacity: "0.6"
    },
    body1: {
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "18px",
    },
    body2: {
        fontWeight: "400",
        fontSize: "15px",
        lineHeight: "24px",
        color: "#FFFFFF"
    },
    caption: {
        fontSize: "14px", 
        lineHeight: "16px", 
        color: "#1E2447"
    },
    button: {
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "15px",
        letterSpacing: "0.2px",
        color: "#FFFFFF",
        mixBlendMode: "normal",
        textTransform: 'none'
    },
    // Table typography

    //1. For Header
    subtitle2: { 
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "14px",
        mixBlendMode: "normal",
        color: "#1E2447",
    },

    //2. For inputs and dropdown inputs
    overline: {
        fontSize: "13px", 
        lineHeight: "14px", 
        color: "#1E2447",
        textTransform: 'none',
        letterSpacing: '0.13125px',
        opacity: '0.85'
    },
};
