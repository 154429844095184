import React from 'react'

const MeasurementAccordion = ({children, heading, onClick,open, active_bg_color = 'blue' }) => {
   const getBgColor = ()=>{
    if(active_bg_color === 'green')return '#00c48c'
    if(active_bg_color === 'blue')return '#111b30'
    }
  return (
    <>
    <button
    className={`sc__list-head ${open && "sc__list-head-selected"}`}
    style={{background: open ? getBgColor() :''}}
    type="button"
    onClick={() =>onClick()}
  >
    {heading}
  </button>
  {children}
    </>
    )
}

export default MeasurementAccordion