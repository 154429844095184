import MapWindIc from "assets/new/map-wind.svg";
import MapSolarIc from "assets/new/map-solar.svg";

import MapLidarIcon from "assets/icons/Map_Lidar.svg";
import MapLidarDIcon from "assets/icons/Map_Lidar_Gray.svg";
import MapSodarIcon from "assets/icons/Map_Sodar.svg";
import MapSodarDIcon from "assets/icons/Map_Sodar_Gray.svg";
import MapSolarIcon from "assets/icons/Map_Solar.svg";
import MapSolarDIcon from "assets/icons/Map_Solar_Gray.svg";
import MapMastIcon from "assets/icons/Map_Mast.svg";
import MapMastDIcon from "assets/icons/Map_Mast_Gray.svg";
import {
  MastWhiteIcon,
  WindSpeedMIc,
  WindDirectionMIc,
  TemperatureMIc,
  PressureMIc,
  ammeterMIc,
  TowerIcon,
  VoltsIcon,
  rainMIc,
  RelativeHumidityIcon,
  GhiIcon,
  // WindSpeed,
  WindSpeedWhite,
  WindSpeedBlue,
  ThermometerWhite,
  ThermometerBlue,
  PressureBlue,
  PressureWhite,
  PyronometerWhite,
  PyronometerBlue,
  WindVaneWhite,
  WindVaneBlue,
  LidarIcon,
  SodarIcon,
  SolarWhiteIcon,
  SodarDarkIcon,
  SolarDarkIcon,
  LidarDarkIcon,
  MastDarkIcon,
  LoggerIcon,
  SensorData,
  SensorDark,
} from "assets";
import {
  SideBarSolar,
  SideBarWind,
  SideBarMast,
  SideBarLidar,
  WindDirIcon,
  WindSpeedIcon,
  TemperatureIcon,
  PressureIcon,
  // SodarWhite,
} from "assets/new";
import { SmallLidarIcon, SmallSodarIcon, SmallSolarIcon, SmallTowerIcon } from "assets/small_icon";

// import  {ReactComponent as  Voltmeter} from "assets/icons/sensors/Volts.svg"
// import  {ReactComponent as  Humidity} from "assets/icons/sensors/Humidity.svg"

import Voltmeter from "assets/icons/sensors/Volts.png"
import Humidity from "assets/icons/sensors/Humidity.png"
import WindSpeed from "assets/icons/sensors/Windspeed.png"
import Pressure from "assets/icons/sensors/Pressure.png"
import Temprature from "assets/icons/sensors/Temperature.png"
import WindDirect from "assets/icons/sensors/Winddirection.png"
import { checkIsLocationDecommissioned } from "utils";
import React from "react";

export const getIconPathForPlant = (plant) => {
  switch (plant.plant_type_id) {
    case "onshore_wind":
      return MapWindIc;
    case "solar":
      return MapSolarIc;
    default:
      return MapWindIc;
  }
};

export const getStyledSideBarIconForPlant = (plantTypeId) => {
  const style = {
    padding: "3px",
    borderRadius: "50%",
    height: "22px",
    width: "22px",
  }

  switch (plantTypeId) {
    case "onshore_wind":
      return React.cloneElement(<SideBarWind />,  {style:{...style, background:'#00c48c'}} );
    case "solar":
      return React.cloneElement(<SideBarSolar />,  {style:{...style, background:'#F6B008'}} );
    default:
      return React.cloneElement(<SideBarWind />,  {style:{...style, background:'#00c48c'}} );
  }
};
export const getSideBarIconForPlant = (plantTypeId) => {
  switch (plantTypeId) {
    case "onshore_wind":
      return <SideBarWind />;
    case "solar":
      return <SideBarSolar />;
    default:
      return <SideBarWind />;
  }
};

export const getSideBarIconForMsmtLocation = (msmtType) => {
  switch (msmtType) {
    case "mast":
      return <SideBarMast />;
    case "lidar":
      return <LidarIcon />;
    case "sodar":
      return <SodarIcon />;
    case "flidar":
      return <SideBarMast />;
    case "solar":
      return <SolarWhiteIcon />;
    default:
      return <SideBarLidar />;
  }
};
export const getIconsForMultiSelector = (msmtType, styles) => {
  const style = {
    padding: "3px",
    borderRadius: "50%",
    height: "22px",
    width: "22px",
    ...styles
  }

  // Mast and solar can be green / yellow like in the home page. For lidar use #8B59A6, sodar #A68B59
  switch (msmtType) {
    case "mast":
      return React.cloneElement(<SideBarMast />,  {style:{...style, background:'#00c48c'}} )
    case "lidar":
      return React.cloneElement(<LidarIcon />,  {style:{...style, background:'#8B59A6'}} )
    case "sodar":
      return React.cloneElement(<SodarIcon />,  {style:{...style, background:'#A68B59'}} )
    case "flidar":
      return React.cloneElement(<SideBarMast />,  {style:{...style, background:'#00c48c'}} )
    case "solar":
      return React.cloneElement(<SolarWhiteIcon />,  {style:{...style, background:'rgb(246, 176, 8)'}} )
    default:
      return React.cloneElement(<SideBarLidar />,  {style:{...style, background:'#00c48c'}} )
  }
};
export const getIconsForCreateLocation = (msmtType) => {
  switch (msmtType) {
    case "mast":
      return <MastDarkIcon />;
    case "lidar":
      return <LidarDarkIcon />;
    case "sodar":
      return <SodarDarkIcon />;
    case "flidar":
      return <MastDarkIcon />;
    case "solar":
      return <SolarDarkIcon />;
    default:
      return <SideBarLidar />;
  }
};

export const getDarkIconForMsmtLocation = (msmtType) => {
  switch (msmtType) {
    case "mast":
      return <SmallTowerIcon />;
    case "lidar":
      return <SmallLidarIcon />;
    case "sodar":
      return <SmallSodarIcon />;
    case "flidar":
      return <SmallSolarIcon />;
    case "solar":
      return <SolarDarkIcon />;
    default:
      return <TowerIcon />;
  }
};

export const getSideBarIconForTowerMeasurement = (name) => {
  if (name.includes("WSpd")) {
    return <WindSpeedIcon />;
  } else if (name.includes("WDir")) {
    return <WindDirIcon />;
  } else if (name.includes("temp")) {
    return <TemperatureIcon />;
  } else if (name.includes("press")) {
    return <PressureIcon />;
  } else {
    return <WindSpeedIcon />;
  }
};

export const getIconPathForMsmtLocation = (msmtLocation) => {
  const isLocationDecomissioned = checkIsLocationDecommissioned(msmtLocation);
  switch (msmtLocation.measurement_station_type_id) {
    case "mast":
      return isLocationDecomissioned? MapMastDIcon: MapMastIcon;
    case "lidar":
      return isLocationDecomissioned? MapLidarDIcon: MapLidarIcon;
    case "sodar":
      return isLocationDecomissioned? MapSodarDIcon: MapSodarIcon;
    case "solar":
      return isLocationDecomissioned? MapSolarDIcon: MapSolarIcon;
    default:
      return isLocationDecomissioned? MapMastDIcon: MapMastIcon;
  }
};

export const getIconForMeasurementPoint = (name, className) => {
  if (name.includes("Wind Speed")) {
    return <WindSpeedMIc className={className} />;
  } else if (name.includes("Wind Dir")) {
    return <WindDirectionMIc className={className} />;
  } else if (name.includes("Temperature")) {
    return <TemperatureMIc className={className} />;
  } else if (name.includes("Pressure")) {
    return <PressureMIc className={className} />;
  } else if (name.includes("Pressure")) {
    return <PressureMIc className={className} />;
  } else {
    <MastWhiteIcon className={className} />;
  }
};

export const getIconForSensorTypeId = (sensor_type_id) => {
  if (sensor_type_id === "anemometer") {
    return <img src={WindSpeed} height={24} width={24} style={{marginRight:"5px"}} />
  } else if (sensor_type_id === "thermometer") {
    return <img src={Temprature} height={24} width={24} style={{marginRight:"5px"}} />
  } else if (sensor_type_id === "wind_vane") {
    return <img src={WindDirect} height={24} width={24} style={{marginRight:"5px"}} />
  } else if (sensor_type_id === "barometer") {
    return <img src={Pressure} height={24} width={24} style={{marginRight:"5px"}} />
  } 
  else if (sensor_type_id === "pyranometer") {
    <GhiIcon />;
  }
  else if(sensor_type_id === 'voltmeter'){
    return <img src={Voltmeter} height={24} width={24} style={{marginRight:"0px"}} />
  }
  else if(sensor_type_id === 'hygrometer'){
    return <img src={Humidity} height={24} width={24} style={{marginRight:"0px"}} />
  }
  else return null;
};
export const getIconBlueForSensorTypeId = (sensor_type_id) => {
  if (sensor_type_id === "anemometer") {
    return <WindSpeedBlue />;
  } else if (sensor_type_id === "thermometer") {
    return <ThermometerBlue />;
  } else if (sensor_type_id === "wind_vane") {
    return <WindVaneBlue />;
  } else if (sensor_type_id === "barometer") {
    return <PressureBlue />;
  } else if (sensor_type_id === "hygrometer") {
    return <RelativeHumidityIcon />;
  } else if (sensor_type_id === "pyranometer") {
    return <PyronometerBlue />;
  } else if (sensor_type_id === "voltmeter"){
    return <VoltsIcon />;
  } else if (sensor_type_id === "ammeter"){
    return <VoltsIcon />;
  } else if (sensor_type_id === "rain_gauge"){
    return <rainMIc />;
  } 
  
};
export const getIconWhiteForSensorTypeId = (sensor_type_id) => {
  if (sensor_type_id === "anemometer") {
    return <WindSpeedWhite />;
  } else if (sensor_type_id === "thermometer") {
    return <ThermometerWhite />;
  } else if (sensor_type_id === "wind_vane") {
    return <WindVaneWhite />;
  } else if (sensor_type_id === "barometer") {
    return <PressureWhite />;
  } else if (sensor_type_id === "hygrometer") {
    return <RelativeHumidityIcon />;
  } else if (sensor_type_id === "pyranometer") {
    return <PyronometerWhite />;
  } else if (sensor_type_id === "ammeter"){
    return <VoltsIcon />;
  } else if (sensor_type_id === "voltmeter"){
    return <VoltsIcon />;} 
  else if (sensor_type_id === "rain_gauge"){
    return <rainMIc />;
  } else {
    <WindSpeedWhite />;
  }
};

export const getIconForHealthCategory = (category) => {
  if (category === "mast_properties") {
    return <MastDarkIcon />;
  }
  if (category === "measurement_location" || category === "measurement_point") {
    return <img src={WindSpeed} height={20} width={20} style={{ marginRight: "5px" }} alt={category} />;
  }
  if (category === "sensor") {
    return <SensorDark />;
  } else if(category === "timeseries"){
    return <SolarDarkIcon />;
  } else {
    return <SensorDark />;
  }
};

