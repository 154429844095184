import { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import Dashboard from "pages/Dashboard";
import { useAppApolloClient, useLogin } from "hooks/apolloClient";
import { ApolloProvider } from "@apollo/client";
import MuiThemeProvider from "../theme/ThemeProvider";
import Login from "pages/Authentication/Login";
import { NotFound } from "pages/NotF0und";
import { SnackbarProvider } from "notistack";
import { Snackbar } from "@material-ui/core";
import { ErrorIcon } from "assets";
import { ConfirmationDialogueProvider } from "contexts/misc/ConfirmationDialogueContext";

//const Login = lazy(() => import("./Authentication/Login"));

// const Dashboard = lazy(() => import("./Dashboard"));

function App() {
  const [loader, setLoader] = useState(false);
  const { setToken, setIsLogin, isLogin } = useLogin();
  const apolloClient = useAppApolloClient();
  const handleLogin = (isLoginDone, token = null) => {
    setIsLogin(isLoginDone);
    setToken(token);
  };
  useEffect(() => {
    // document.getElementById("root")?.classList.remove("loader");
    // document.getElementById("root1")?.classList.remove("container");
    // document.getElementById("root2")?.classList.remove("subcontainer");
    // document.getElementById("imglogo")?.remove();
    // document.getElementById("ipl-progress-indicator")?.remove();
    setLoader(true);
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openWarn, setOpenWarn] = useState(false);

  useEffect(() => {
    setOpenWarn(true);
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
      setOpenWarn(window.innerWidth < 768); // Adjust the screen width breakpoint as needed
    };

    handleResize(); // Check the initial screen size

    window.addEventListener('resize', handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);

  return (
    <>
          <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openWarn}
        // autoHideDuration={}
        onClose={() => setOpenWarn(false)}
        message={
          <div className="cp__snack--content">
            {<ErrorIcon />}
            <p>The RAPT application is data and visualization intensive application. It is optimized for viewing in a larger screen or window. Please try accessing it in a larger window or screen</p>
          </div>
        }
        className={"cp__snack--error small-screen"}
      />
      {loader && (
        <ApolloProvider client={apolloClient}>
          <MuiThemeProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              autoHideDuration={4000}
              style={{
                fontSize: "1.5rem",
              }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConfirmationDialogueProvider>
              <Router>
                <Suspense fallback={<div />}>
                  {isLogin ? (
                    <Switch>
                      <Route exact path="/not-found" component={NotFound} />
                      <Route path={"/login"}>
                        <Redirect to={"/"} />
                      </Route>
                      <Route path="/">
                        <Dashboard />
                      </Route>
                    </Switch>
                  ) : (
                    <Route path={["/", "/login"]} render={() => <Login toggleLogin={handleLogin} />} />
                  )}
                </Suspense>
              </Router>
              </ConfirmationDialogueProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </ApolloProvider>
      )}
    </>
  );
}

export default App;
