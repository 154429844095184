import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationDialog = (props) => {
  const {
    title,
    content,
    open,
    onClose,
    acceptButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    modalProps = {},
    color = 'primary',
  } = props;

  const handleClose = (isConfirmed) => {
    onClose(isConfirmed);
  };

  return (
    <Dialog
      onClose={() => handleClose(false)}
      maxWidth="xs"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '32vw',
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...modalProps}
      open={open}
    >
      <DialogTitle id="alert-dialog-title">
      <span onClick={() => handleClose(false)} class="cp__close-icon"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.933533 0.93451C1.24595 0.62209 1.75248 0.62209 2.0649 0.93451L6.99922 5.86882L11.9335 0.93451L12.2155 1.21646L11.9335 0.934509C12.246 0.622091 12.7525 0.622091 13.0649 0.934509C13.3773 1.24693 13.3773 1.75346 13.0649 2.06588L8.13059 7.0002L13.0649 11.9345C13.3773 12.2469 13.3773 12.7535 13.0649 13.0659L12.7114 12.7123L13.0649 13.0659C12.7525 13.3783 12.246 13.3783 11.9335 13.0659L6.99922 8.13157L2.0649 13.0659C1.75248 13.3783 1.24595 13.3783 0.933532 13.0659C0.621114 12.7535 0.621114 12.2469 0.933532 11.9345L1.28709 12.2881L0.933533 11.9345L5.86785 7.0002L0.933533 2.06588C0.621114 1.75346 0.621114 1.24693 0.933533 0.93451Z" fill="#1E2447"></path></svg></span>
        </DialogTitle>
      <DialogContent sx={{display:'flex',flexDirection: "column", alignItems:"center", justifyContent:"center",paddingBottom:0}}>
        <div className='.cp__confirm'>
            <h2 style={{marginBottom: '10px', marginTop: '5px'}}>{title}</h2>
        </div>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
        <p style={{marginBottom: '25px', fontFamily: 'Roboto', fontWeight: '400', fontSize: '14px', lineHeight: '26px', color: 'rgba(30,36,71,.7)'}}>
        {content}
        </p>    
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', gap: 2, justifyContent:"center", paddingBottom:'30px' }}>
        <button
          onClick={() => handleClose(false)}
          className='cp__confirm-btn cp__cancel-btn'
        >
          {cancelButtonText}
        </button>
        <button
          onClick={() => handleClose(true)}
          className={`cp__confirm-btn cp__delete-btn`}
          style={{ backgroundColor: color === 'error' ? '#ff647c' : color }}
        >
          {acceptButtonText}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
