import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Paper ,Typography,Box , makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    noPaper:{
        height:"50vh",
        display:"flex",
        justifyContent:'center',
        alignItems:"center",
      },
      noData:{
        color:"rgba(0, 0, 0, 0.46)"
      }
})
const NoDataPreview = () => {
    const classes = useStyles();
  return (
    <>
      <>
        <Paper className={classes.noPaper}>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignContent={"center"}>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              marginBottom={"10px"}
              alignContent={"center"}
            >
              <ErrorOutlineIcon color="disabled" fontSize="large" />
            </Box>
            <Typography variant="h1" className={classes.noData}>
              No Data found
            </Typography>
          </Box>
        </Paper>
      </>
    </>
  );
};

export default NoDataPreview;
