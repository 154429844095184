import { DetailsBox, DetailsBoxWithGrid } from "component_tree/shared";
import { Formik } from "formik";
import { Spinner } from "component_tree/shared";
import { format, parse, compareAsc } from "date-fns";
import { TextField, Menu, MenuItem, makeStyles, Typography, Select, CircularProgress } from "@material-ui/core";
import { FETCH_STANDARD_SENSOR_MODEL } from "services";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import * as Yup from "yup";
import "./SensorForm.css";
const SensorForm = ({ data, handleSubmit, formType, onSelectSensorTypeID, keyIter = 0, measurement_point_uuid }) => {
  const {
    data: data_sensor_models,
    loading: standardSensorLoading,
    // error: standardSensorError,
  } = useQuery(FETCH_STANDARD_SENSOR_MODEL);
  const standardSensorModels = data_sensor_models?.sensor || [];
  const models = standardSensorModels;

  const patchSensor = (oem, model) => {
    return oem + "-" + model;
  };

  let modelUuidMap = new Map([]);
  models.forEach((model) => modelUuidMap.set(model.uuid, patchSensor(model.oem, model.model)));

  let modelMap = new Map([]);
  models.forEach((model) => modelMap.set(model.uuid, model.model));

  const [isFormChange, setIsFormChange] = useState(false);
  const [err, setErros] = useState({});

  const validateSensorForm = (values) => {
    const errors = {};
    if (!values.serial_number || values.serial_number === "") {
      errors.serial_number = "Required";
    }

    if (!values.date_from) {
      errors.date_from = "Date Required";
    }
    if (values.date_to && values.date_from) {
      const d1 = new Date(values.date_from);
      const d2 = new Date(values.date_to);
      const result = compareAsc(d1, d2);
      if (result === 1) errors.date_from = "Date from should be less than Date to";
    }
    setErros(errors);
    return errors;
  };
  let displayVal = "Update";
  if (formType === "add") displayVal = "Add";

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      [`date_from`]: Yup.date().required("Required").typeError("Invalid Date"),
      [`date_to`]: Yup.date().nullable().typeError("Invalid Date"),
    });
    return schema;
  };

  let dataInit = Object.assign({}, data);
  dataInit.modelUuid = data.uuid;

  if (standardSensorLoading) {
    return (
      <article
        style={{
          display: "flex",
          flex: "1",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </article>
    );
  }

  return (
    <>
      <div className={"bord"}>
        <Formik
          initialValues={dataInit}
          enableReinitialize={true}
          validate={validateSensorForm}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values, setSubmitting, values.uuid, measurement_point_uuid);
            // console.log(values, setSubmitting, values.uuid);
            setIsFormChange(false);
          }}
          key={data.uuid}
          validationSchema={getValidationSchema()}
        >
          {({
            handleSubmit,
            values,
            handleChange,
            handleBlur,
            handleReset,
            errors,
            touched,
            setFieldTouched,
            setFieldValue,
            setFieldError,
            isSubmitting,
            setIsEditing,
            initialValues,
            ...props
          }) => {
            console.log("Errors", touched, errors, errors.date_from && touched.date_from ? errors.date_from : "");
            return (
              <form
                onSubmit={handleSubmit}
                onChange={() => {
                  setIsFormChange(true);
                }}
              >
                {formType === "add" ? (
                  <h3 className="tm__form-title">Add Model</h3>
                ) : (
                  // <h3 className="tm__form-title">Sensor Details</h3>
                  <></>
                )}
                {formType === "add" && (
                  <div style={{ padding: "0px 20px" }}>
                    <TextField
                      select
                      value={values.uuid}
                      name={`greenSelect-${displayVal}-${keyIter}`}
                      id={`greenSelect-id-${displayVal}`}
                      InputProps={{
                        classes: {
                          root: "green-select",
                        },
                      }}
                      defaultValue=""
                      fullWidth
                      onChange={(e) => {
                        setFieldValue(`greenSelect-${displayVal}-${keyIter}`, e.target.value);
                        setIsFormChange(true);
                        const val = onSelectSensorTypeID(e.target.value);
                        setFieldValue("oem", val.oem);
                      }}
                    >
                      {models.map((model) => {
                        return (
                          <MenuItem key={model.uuid} value={model.uuid}>
                            {patchSensor(model.oem, model.model)}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </div>
                )}

                <DetailsBoxWithGrid
                  key={formType + "SensorBox"}
                  rootStyles={`sb_mt__box sb_mt__box-expand`}
                  inputContainerStyles="
                  "
                  renderHeader={null}
                  formList={[
                    {
                      value: values.serial_number,
                      setValue: (e) => {
                        handleChange(e);
                        setIsFormChange(true);
                      },
                      //clickHandler: (e) => handleBlur(e),
                      label: "Serial Number",
                      name: "serial_number",
                      placeholder: "",
                      inputType: "input",
                      dims: { xs: 3 },
                      error: errors.serial_number && touched.serial_number ? errors.serial_number : "",
                    },
                    {
                      value: values.oem,
                      setValue: (val) => {
                        handleChange(val);
                        setFieldValue("oem", val);
                        setIsFormChange(true);
                      },
                      //clickHandler: (e) => handleBlur(e),
                      label: "OEM",
                      name: "oem",
                      dims: { xs: 3 },
                      placeholder: "",
                      tdLogSelectStyles: "log__select-input",
                      tdLogSelectIconStyles: "log__select-input-icon",
                      inputType: "select",
                      freeSolo: true,
                      options: [...new Set(models.map((oem) => oem.oem))].map((oem) => ({
                        id: oem,
                        label: oem,
                      })).map((oem) => {
                        return {
                          id: oem.id,
                          label: oem.label,
                        };
                      }),
                      error: errors.oem && touched.oem,
                    },
                    {
                      value:values.model,
                      name: "modelUuid",
                      label: "Sensor Model",
                      setValue: (val2, valObj) => {
                        // setFieldValue(`modelUuid`, val2);
                        setFieldValue("model", val2);
                        setIsFormChange(true);
                        // const val = onSelectSensorTypeID(val2);
                        // setFieldValue("oem", val.oem);
                      },
                      placeholder: "",
                      tdLogSelectStyles: "log__select-input",
                      tdLogSelectIconStyles: "log__select-input-icon",
                      inputType: "select",
                      freeSolo: true,
                      dims: { xs: 6 },
                      options: [...new Set(models.map((model) => model.model))].map((model) => ({
                        id: model,
                        label: model,
                      })),
                    },
                    {
                      value: values.date_from,
                      setValue: (val) => {
                        setFieldValue("date_from", val);
                        // setIsFormChange(true);
                      },
                      clickHandler: () => { },
                      label: "Date From",
                      name: "date_from",
                      placeholder: "",
                      inputType: "date",
                      dims: { xs: 6 },
                      pickerStyles: "sb__date-picker",
                      setFieldError: setFieldError,
                      setFieldTouched: setFieldTouched,
                      error: errors.date_from && touched.date_from ? errors.date_from : "",
                      dateRootStyles: "log__date-input",
                      logDateInputStyles: "log__date-input-styles",
                      maxDate: values.date_to ? new Date(values.date_to) : undefined,
                      required: true,
                      dateInit: initialValues.date_from,
                    },
                    {
                      value: values.date_to,
                      setValue: (val) => {
                        setFieldValue("date_to", val);
                        validateSensorForm(values);
                        // setIsFormChange(true);
                      },
                      clickHandler: () => { },
                      label: "Date To",
                      name: "date_to",
                      placeholder: "",
                      dims: { xs: 6 },
                      inputType: "date",
                      setFieldError: setFieldError,
                      setFieldTouched: setFieldTouched,
                      error: errors.date_to && touched.date_to,
                      pickerStyles: "sb__date-picker sb__date-picker-up",
                      dateRootStyles: "log__date-input",
                      logDateInputStyles: "log__date-input-styles",
                      minDate: values.date_from ? new Date(values.date_from) : undefined,
                      required: false,
                      dateInit: initialValues.date_to,
                    },
                  ]}
                  renderRight={
                    <footer className="tm__submit">
                      {(isFormChange || Object.keys(touched).length > 0 || formType === "add") && (
                        <>
                          <button type="submit" className="tm__submit-btn" disabled={isSubmitting}>
                            {isSubmitting ? (
                              <CircularProgress size={22} color="primary" />
                            ) : (
                              <Typography variant="button">{displayVal}</Typography>
                            )}
                          </button>
                          <button
                            type="button"
                            className="tm__submit-btn tm__submit-cancel"
                            onClick={() => {
                              handleReset();
                              setIsFormChange(false);
                            }}
                          >
                            <Typography variant="button" style={{ color: "#1e244780" }}>
                              Cancel
                            </Typography>
                          </button>
                        </>
                      )}
                    </footer>
                  }
                />
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
export default SensorForm;
