import ButtonBase from "@material-ui/core/ButtonBase";

import { TowerCard } from "..";
import { PlusGrayIC } from "assets/new";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
// import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
// import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
// import { SlArrowRight } from "react-icons/si";
import "./Carousel.css";
import { useState } from "react";
import { useEffect } from "react";
let slidesToShow = 6;

const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            padding: "15px 14px 15px 16px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
            borderStyle: "solid",
            borderWidth: "0.1px",
            borderColor: "#e7e9eb",
            boxShadow: "20px 10px",
          }}
          className={className}
          onClick={onClick}
        >
          <ArrowBackIosRoundedIcon style={{ color: "#8e91a3", fontSize: 17, opacity: 0.7, position: "absolute" }} />
        </div>
      )}
    </>
  );
};

const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;

  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            padding: "15px 14px 15px 16px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
            borderStyle: "solid",
            borderWidth: "0.1px",
            borderColor: "#e7e9eb",
            boxShadow: "20px 10px",
          }}
          className={className}
          onClick={onClick}
        >
          <ArrowForwardIosRoundedIcon style={{ color: "#8e91a3", fontSize: 17, opacity: 0.7, position: "absolute" }} />
        </div>
      )}
    </>
  );
};

const carouselProperties = {
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
  infinite: false,
  responsive: [
    {
      breakpoint: 670,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 792,
      settings: {
        slidesToShow: 2,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 973,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1215,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1290,
      settings: {
        slidesToShow: 5,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 6,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1950,
      settings: {
        slidesToShow: 8,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <NextBtn />,
  prevArrow: <PreviousBtn />,
};

export const Carousel = ({ projectTowerList, onNewClick }) => {
  // let maxHeight = Math.max(...projectTowerList.map((x) => { return x.mast_height_m }));
  let maxHeight = Math.max(
    ...projectTowerList
      .map((x) => {
        return x?.measurement_location_mast_properties?.[0]?.mast_property?.mast_height_m;
      })
      .filter((x) => isFinite(x))
  );
  maxHeight = Math.min(80, maxHeight);  // Need logarithmic scaling later to account for mast and remote sensing heights

  return (
    // <div style={{ width: "calc(100vw - 25rem - 40px)" }}>
    //   <ReactCarousel arrows itemWidth={160} slidesPerPage={8} offset={20}>
    //     {projectTowerList.map((tower) => {
    //       if (tower?.id === "newLocation")
    //         return (
    //           <button
    //             className="cr__new-card"
    //             onClick={onNewClick}
    //             key={tower.id}
    //             style={{ marginTop: "14px", height: 194 }}
    //           >
    //             <PlusGrayIC />
    //             <h3>Add Location</h3>
    //           </button>
    //         );
    //       return <TowerCard tower={tower} key={tower.id} maxHeight={maxHeight} />;
    //     })}
    //   </ReactCarousel>
    // </div>

    <div style={{ width: "calc(100vw - 25rem - 40px)" }}>
      <div className="carousel">
        <Slider {...carouselProperties}>
          {projectTowerList.map((tower) => {
            if (tower?.id === "newLocation")
              return (
                <button
                  className="cr__new-card"
                  onClick={onNewClick}
                  key={tower.id}
                  style={{ marginTop: "14px", height: 194, width: "140px !important" }}
                >
                  <PlusGrayIC />
                  <h3>Add Location</h3>
                </button>
              );
            return <TowerCard tower={tower} key={tower.uuid} maxHeight={maxHeight} />;
          })}
        </Slider>
      </div>
    </div>
  );
};
