import { Fragment } from "react";
import styled from "styled-components";
import { BreadcrumbArrowIcon } from "assets";
import { FETCH_PLANTS } from "services";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getSideBarIconForMsmtLocation, getSideBarIconForPlant } from "utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { orderBy } from "lodash";
import Fade from "@mui/material/Fade";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 1.3rem;
    font-weight: 300;
    font-family: "Roboto";
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #1e2447;
    opacity: 0.6;
    cursor: pointer;
  }
  .active-breadcrumb {
    font-weight: normal;
  }

  svg {
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Breadcrumb = ({ breadcrumbList, dataString }) => {
  const projectId = dataString?.split("/")[0];
  const towerId = dataString?.split("/")[1];
  const property = dataString?.split("/")[2];

  const [refetchAllPlants, { loading, error, data }] = useLazyQuery(FETCH_PLANTS);
  const plantsList = data?.plant;

  useEffect(() => {
    refetchAllPlants();
  }, [projectId, refetchAllPlants]);

  const [currentProject, setCurrentProject] = useState({});
  const [currentTower, setCurrenttower] = useState({});
  const [projects, setProjects] = useState([]);
  const [measurementLocations, setMeasurementLocations] = useState([]);
  // console.log(plantsList[projectId], 'hello measurementLocations')

  useEffect(() => {
    const sortedProjects = plantsList
      ?.map((item) => ({ name: item.name, uuid: item.uuid, plant_type_id: item.plant_type_id }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setProjects(sortedProjects || []);
    const locations = plantsList?.filter((item) => item.uuid === projectId)[0]?.measurement_locations;
    setCurrentProject(plantsList?.filter((item) => item.uuid === projectId)[0]);
    setCurrenttower(plantsList?.filter((item) => item.uuid === projectId)[0]?.measurement_locations?.filter((item) => item.uuid === towerId)[0] || {})
    setMeasurementLocations(locations || []);
  }, [plantsList, projectId, towerId, property]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [towerAnchorEl, setTowerAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openTower = Boolean(towerAnchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };  
  
  const handleTowerClick = (event) => {
    setTowerAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTowerAnchorEl(null);
  };

  return (
    <BreadcrumbWrapper>
      {breadcrumbList.map((i, index) => {
        if (index !== 1 && index !== 2) {
          return (
            <Fragment key={index}>
              <p
                key={index}
                className={breadcrumbList?.length - 1 === index ? "active-breadcrumb" : ""}
                onClick={i.onClick}
              >
                {i.name}
              </p>
              {breadcrumbList?.length - 1 !== index && <BreadcrumbArrowIcon />}
            </Fragment>
          );
        } else if (index === 1) {
          return (
            <Fragment key={index}>
              <p
                key={index}
                className={breadcrumbList?.length - 1 === index ? "active-breadcrumb" : ""}
                // onClick={i.onClick}
              >
                {projects?.length > 0 ? (
                  <>
                    <span className="header-button" onClick={handleClick}>
                      {currentProject && getSideBarIconForPlant(currentProject?.plant_type_id)}
                      {i.name}
                      <KeyboardArrowDownIcon />
                    </span>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      {orderBy(projects, ["name"], ["asc"])?.map(({ name, uuid, plant_type_id }) => {
                        // const towerUuid = plantsList?.filter((item) => item.uuid === uuid)[0]?.measurement_locations[0]?.uuid;
                        return (
                          <MenuItem onClick={handleClose} key={uuid} className="header-menu">
                            <Link to={`/${uuid}`}>{getSideBarIconForPlant(plant_type_id)}{name}</Link>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                ) : (
                  <span className="header-button" onClick={handleClick} style={{marginRight:10}}>
                      {currentProject && getSideBarIconForPlant(currentProject?.plant_type_id)}
                      {i.name}
                      <KeyboardArrowDownIcon />
                    </span>
                )}
              </p>
              {breadcrumbList?.length - 1 !== index && <BreadcrumbArrowIcon />}
            </Fragment>
          );
        } else if (index === 2) {
          return (
            <Fragment key={index}>
              <p
                key={index}
                className={breadcrumbList?.length - 1 === index ? "active-breadcrumb" : ""}
                // onClick={i.onClick}
              >
                    {measurementLocations?.length > 0 ? (
                  <>
                    <span className="header-button" onClick={handleTowerClick}>
                      {currentProject && getSideBarIconForMsmtLocation(currentTower?.measurement_station_type_id)}
                      {i.name}
                      <KeyboardArrowDownIcon />
                    </span>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={towerAnchorEl}
                      open={openTower}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      {orderBy(measurementLocations, ["name"], ["asc"])?.map(({ name, uuid, measurement_station_type_id }) => {
                        return (
                          <MenuItem onClick={handleClose} key={uuid} className="header-menu">
                            
                            <Link to={!property ? `/${currentProject.uuid}/${uuid}`: `/${currentProject.uuid}/${uuid}/tower/${property}`}>{getSideBarIconForMsmtLocation(measurement_station_type_id)}{name}</Link>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </>
                ) : (
                  <span className="header-button" onClick={handleTowerClick}>
                      {currentTower && getSideBarIconForMsmtLocation(currentTower?.measurement_station_type_id)}
                      {i.name}
                      <KeyboardArrowDownIcon />
                    </span>
                )}
              </p>
              {breadcrumbList?.length - 1 !== index && <BreadcrumbArrowIcon />}
            </Fragment>
          );
        }
        return null;
      })}
    </BreadcrumbWrapper>
  );
};
