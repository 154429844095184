import React from 'react'

const TrashIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="14" height="14" viewBox="0 0 14 14">
            <defs>
                <path id="hskxm608pa" d="M10.32 3.69c.228 0 .413-.185.413-.413v-.573c0-.86-.708-1.558-1.579-1.558H7.731l-.104-.064-.113-.223C7.244.329 6.704 0 6.103 0H4.63C4.03 0 3.49.33 3.22.86l-.113.222-.103.064H1.579C.71 1.146 0 1.845 0 2.704v.573c0 .228.185.413.414.413h.169v8.179c0 1.175.97 2.131 2.162 2.131h5.244c1.192 0 2.162-.956 2.162-2.132V3.69h.168zm-8.91 0h7.913v8.178c0 .72-.6 1.306-1.334 1.306H2.745c-.735 0-1.334-.586-1.334-1.306V3.69zm3.083.892c-.229 0-.414.185-.414.413v6.874c0 .228.185.413.414.413.228 0 .414-.185.414-.413V4.995c0-.228-.186-.413-.414-.413zm-1.748 0c-.229 0-.414.185-.414.413v6.874c0 .228.185.413.414.413.228 0 .414-.185.414-.413V4.995c0-.228-.186-.413-.414-.413zm3.496 0c-.229 0-.414.185-.414.413v6.874c0 .228.185.413.414.413.228 0 .414-.185.414-.413V4.995c0-.228-.186-.413-.414-.413zm1.748 0c-.229 0-.414.185-.414.413v6.874c0 .228.185.413.414.413.228 0 .414-.185.414-.413V4.995c0-.228-.186-.413-.414-.413zm1.916-1.878v.16H.828v-.16c0-.404.338-.732.751-.732h1.748c.156 0 .298-.087.37-.225l.26-.513c.128-.252.386-.408.674-.408h1.472c.287 0 .545.156.674.408l.26.512c.07.139.213.226.369.226h1.748c.413 0 .751.328.751.732z" />
            </defs>
            <g fill="none" fill-rule="evenodd" opacity=".5">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0H14V14H0z" transform="translate(-1393 -919) translate(745 794) translate(25 62) translate(0 49) translate(613 3) translate(10 11)" />
                                        </g>
                                        <g transform="translate(-1393 -919) translate(745 794) translate(25 62) translate(0 49) translate(613 3) translate(10 11) translate(1.867)">
                                            <path fill="#FFF" d="M6.087 0c.63 0 1.199.347 1.482.908l.111.218h1.394c.913 0 1.66.738 1.66 1.65v.563c0 .291-.235.523-.52.523h-.051v7.925c0 1.223-1.002 2.213-2.23 2.213H2.8c-1.227 0-2.23-.99-2.23-2.213V3.862H.52C.234 3.862 0 3.63 0 3.34v-.563c0-.912.746-1.65 1.66-1.65h1.393l.11-.218C3.448.347 4.016 0 4.646 0h1.44zM1.608 3.862v7.925c0 .64.533 1.167 1.192 1.167h5.133c.66 0 1.192-.526 1.192-1.167V3.862H1.608zm2.903.644c.285 0 .52.232.52.523v6.758c0 .292-.235.523-.52.523-.284 0-.519-.231-.519-.523V5.03c0-.291.235-.523.52-.523zm-1.71 0c.284 0 .518.232.518.523v6.758c0 .292-.234.523-.519.523-.284 0-.519-.231-.519-.523V5.03c0-.291.235-.523.52-.523zm3.421 0c.284 0 .52.232.52.523v6.758c0 .292-.236.523-.52.523-.284 0-.519-.231-.519-.523V5.03c0-.291.235-.523.52-.523zm1.711 0c.284 0 .52.232.52.523v6.758c0 .292-.236.523-.52.523-.284 0-.52-.231-.52-.523V5.03c0-.291.236-.523.52-.523zm1.762-1.73c0-.33-.277-.604-.621-.604H7.363c-.194 0-.373-.109-.463-.285l-.254-.504c-.106-.207-.319-.337-.559-.337h-1.44c-.241 0-.454.13-.559.337l-.255.505c-.09.175-.269.284-.462.284H1.66c-.345 0-.622.274-.622.604v.04h8.657v-.04z" />
                                            <mask id="rk3zrtaa6b" fill="#fff">
                                                <use xlinkHref="#hskxm608pa" />
                                            </mask>
                                            <use fill="red" xlinkHref="#hskxm608pa" />
                                            <g fill="red" mask="url(#rk3zrtaa6b)">
                                                <path d="M0 0H14V14H0z" transform="translate(-1.4)" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default TrashIcon