import { makeStyles } from "@material-ui/styles";
import { Breadcrumb } from "component_tree/shared";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
    backgroundColor: "var(--icon-white)",
    marginTop: "'4px'"
  }
}))

export const SubHeader = ({ breadcrumbList = [], renderRight, dataString }) => {
  const classes = useStyles();
  return (
    <header className={classes.container}>
      <div style={{ width: "90%", paddingTop: "3.6px" }}>
        <Breadcrumb breadcrumbList={breadcrumbList} dataString={dataString}/>
      </div>

      {renderRight && renderRight}
    </header>
  );
};
