import axios from 'axios';
// (Amit) This causes the user token to be taken at initalization.
// const user = JSON.parse(localStorage.getItem('user'));

const nodlesapi = `${process.env.REACT_APP_API_URL}`.replace('/api', '/nodlesapi')
const instance =  axios.create({
    baseURL: nodlesapi
  });

export const raptInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
});

export const uploadRaptInstance = axios.create({
  baseURL: `${process.env.REACT_APP_UPLOAD_URL}`
});

raptInstance.interceptors.request.use((config) => {
  // Get the use token at query time
  const user = JSON.parse(localStorage.getItem('user'));
  if(user.token){
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${user.token}`,
    };
  }
  return config;
});

uploadRaptInstance.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user.token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${user.token}`,
    };
  }
  return config;
});

instance.interceptors.request.use((config) => {
  // Get the use token at query time
  const user = JSON.parse(localStorage.getItem('user'));
  if(user.token){
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${user.token}`,
    };
  }
  return config;
});

window.instance = instance;

const errorComposer = (error) => {
  return () => {
    const statusCode = error.response ? error.response.status : null;
   if (statusCode === 401) {
    localStorage.removeItem("user");
    window.location = window.location.origin + "/login";
    alert(
      'Invalid session. Please login again.'
    );
   } else if (statusCode === 404) {
      alert(
        'Unable to reach server. It may be offline or undergoing maintenance. Please notify support if the issue persists.'
      );
    }

  };
};

instance.interceptors.response.use(
  (response) => response,
  function (error) {
    errorComposer(error)();
    return Promise.reject(error);
  }
);


export default instance;

uploadRaptInstance.interceptors.response.use(
  (response) => response,
  function (error) {
    errorComposer(error)();
    return Promise.reject(error);
  }
);
