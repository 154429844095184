import { makeStyles } from "@material-ui/core/styles";

export const toggleButtonStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  viewToggle: {
    background: "rgb(30 , 36 , 71 , .03)",
    borderRadius: "14px",
    width: "182px",
    "& button": {
      border: "none",
      background: "none",
      fontSize: "12px",
      width: "91px",
      lineHeight: "28px",
      height: "28px",
      cursor: "pointer",
      letterSpacing: "0.1px",
      textAlign: "center",
      color: "rgb(30 , 36 , 71 , .5)",
      "&.active": {
        color: "rgb(255 , 255 , 255 , .9)",
        letterSpacing: "0.2px",
        background: "#00c48c",
        borderRadius: "14px",
        boxShadow: "-3px 0 7px 3px rgba(0, 0, 0, 0.06)",
      },
    },
  },
}));

export const saveButtonStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  btnSecondary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.secondary.main,
    width: "max-content",
    height: 30,
    color: "rgba(255 , 255 , 255 , .9)",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: "400",
    fontFamily: "Roboto",
    textAlign: "center",
    border: "none",
    transition: "all .3s ease-in",
    padding: "10px 18px !important",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
    "&:focus": {
      border: "none",
    },
  },
}));

export const fieldButtonStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  btnPrimary: {
    background: theme.palette.primary.main,
    width: "100%",
    padding: "1.2rem 0",
    color: "rgba(255 , 255 , 255 , .9)",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "15px",
    fontFamily: "Roboto",
    textAlign: "center",
    border: "none",
    transition: "all .3s ease-in",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
    "&:focus": {
      border: "none",
    },
  },
}));

export const addButtonStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  btnWrapper: {
    width: "100%",
    maxHeight: "50px",
    background: "#111B30",
    borderTop: "0.4px solid rgba(255, 255, 255, 0.1)",
    padding: "15px 20px",
    marginTop: "7px",
  },
  btnSubWrapper: {
    display: "flex",
    alignItems: "center",
  },
  btnText: {
    marginLeft: "10px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "15px",
    letterSpacing: "0.2px",
    color: "rgba(0, 196, 140, 0.9)",
    cursor: "pointer",
  },
  btn: {
    width: "2rem",
    height: "2rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    border: "none",
    borderRadius: "1px",
    background: "rgba(0, 196, 140, 0.1)",
    transition: "all .3s ease-in",
    "& svg": {
      width: "1.6rem",
      height: "1.6rem",
    },
  },
}));
