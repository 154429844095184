export const getDate = (date = new Date(), spliter = ".") => {
  let d = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
  let m = getMonth(date.getMonth());
  let y = date.getFullYear();
  return d + spliter + m + spliter + y;
};

export const getTime = (d = new Date(), is24h = true) => {
  if (!is24h) {
    const actual_h = d.getHours();
    let suffix = "AM";
    let h = actual_h;
    if (actual_h === 0) {
      h = 12;
    } else if (actual_h > 12) {
      h = actual_h - 12;
      suffix = "PM";
    } else if (actual_h === 12) {
      suffix = "PM";
    }
    const m = d.getMinutes();
    return h + ":" + m + " " + suffix;
  }
};

export const dateToWord = (date) => {
  // Date in ms
  const unit = ["s", "min", "hr", "d", "w", "m", "y"];
  const mag = [1000, 60, 60, 24, 7, 4, 12];
  let value = 1;
  let index = 0;
  for (let k in mag) {
    if (value * mag[k] > date) {
      break;
    }
    value = value * mag[k];
    index++;
  }
  return Math.round(date / value) + "" + unit[index - 1];
};

export const getMonth = (month) => {
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "Aug";
    case 8:
      return "Sept";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return null;
  }
};

export const isEqualDate = (date1 = new Date(), date2 = new Date()) => {
  if (date1.getDate() === date2.getDate()) {
    if (date1.getMonth() === date2.getMonth()) {
      if (date1.getFullYear() === date2.getFullYear()) {
        return true;
      }
    }
  }
  return false;
};
