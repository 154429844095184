import { CircularProgress, Snackbar, Typography } from "@material-ui/core";
import { CheckIcon, ErrorIcon } from "assets/new";
import { Spinner } from "component_tree/shared/Spinner";
import React, { useState } from "react";
import { useEffect } from "react";
import {sortBy} from "lodash";

export const SplitMeasurements = ({ measurementPointId, measurementTypesList,towerId, selectedData, setSelectedData, initialSelValues, setShowCheckBox, handleSaveClicked, isLoading }) => {
  const sensorTypes = sortBy(measurementTypesList, ['id']).map((val) => {
    return (
      <option value={val.id} key={val.id}>
        {val.id}
      </option>
    );
  });
  
  useEffect(()=>{
    if(selectedData.target_measurement_type_id == ""){
      setSelectedData({ ...selectedData,current_measurement_uuid: measurementPointId, target_measurement_type_id: measurementTypesList[0].id })
    }
},[setSelectedData, selectedData, measurementTypesList, measurementPointId])

  return (
    <article className="sc__body">
      <div className="sc__head" style={{ paddingBottom: "20px" }}>
        <h3>Split Measurements</h3>
      </div>
      <div>
        <div style={{ fontWeight: 400, color: "rgb(0, 0, 0, 0.5)", fontSize: 13, marginBottom: 5 }}>
          <span className={"col_col"}>Measurement Type</span>
        </div>
        <select
          className={"select-box"}
          style={{ lineHeight: 2 }}
          value={selectedData.target_measurement_type_id}
          onChange={(e) => setSelectedData({ ...selectedData, target_measurement_type_id: e.target.value })}
        >
          {sensorTypes}
        </select>
      </div>

      <div className={`db__input-wrap`} style={{ margin: "15px 0px", width: "100%" }}>
        <Typography variant="subtitle1" style={{ marginBottom: "6px" }}>
          Height
        </Typography>

        <div className={`db__input-inner`}>
          <input
            style={{ background: "#f2f2f4", fontSize: 13, padding: 5, lineHeight: 1.5 }}
            className="db__input-view"
            type={"number"}
            value={selectedData.target_measurement_height_m}
            onChange={(e) => setSelectedData({ ...selectedData, target_measurement_height_m: e.target.value })}
          />
        </div>
      </div>

      <div className={`db__input-wrap`} style={{ marginBottom: "15px", width: "100%" }}>
        <Typography variant="subtitle1" style={{ marginBottom: "6px" }}>
          Orientation
        </Typography>

        <div className={`db__input-inner`} style={{ width: "100%" }}>
          <input
            style={{ background: "#f2f2f4", fontSize: 13, padding: 5, lineHeight: 1.5 }}
            className="db__input-view"
            type={"number"}
            value={selectedData.target_measurement_orientation}
            onChange={(e) => setSelectedData({ ...selectedData, target_measurement_orientation: e.target.value })}
          />
        </div>
      </div>
      <footer className="ma__submit">
        <div style={{ display: "flex", alignItems: "center" }}>
          <button type="submit" className="ma__submit-btn" onClick={handleSaveClicked}>
          {isLoading ? <Spinner color="white" /> : "Save"}
          </button>
          <button type="button" className="ma__submit-btn ma__submit-cancel" onClick={() => {setSelectedData(initialSelValues);setShowCheckBox(false)}}>
            Cancel
          </button>
        </div>
      </footer>
    </article>
  );
};
