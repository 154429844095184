/**
 * validateEmail
 *
 * @returns {boolean} Email correct or not
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getBooleanFromString = (val) => {
  if (val === "true") return true;
  if (val === "false") return false;
  return null;
};

export const getStringFromBoolean = (val) => {
  if (val === true) return "true";
  if (val === false) return "false";
  return null;
};

export const directionsList = [
  "North",
  "North-East",
  "East",
  "South-East",
  "South",
  "South-West",
  "West",
  "North-West",
];

export const capUnderSentence = (text = "") => {
  let wordsArray = text?.toLowerCase().split("_") || [];
  let capsArray = [];
  wordsArray.forEach((word) => {
    if(word[0] !== undefined) capsArray.push(word[0].toUpperCase() + word.slice(1));
  });

  return capsArray.join(" ");
};
export const getMeasurementPointTitle = (item) => {
  let { measurement_type_id, height_m, orientation } = item;
  let orient_str = orientation < 0 ? "Unknown" : Math.round(orientation, 1);
  //return `${capUnderSentence(measurement_type_id)} @ ${height_m} m ${directionsList[direction]}`;
  return `${capUnderSentence(measurement_type_id)} at ${height_m} m   ( ${orient_str} deg )`;
};

export const getDirection = (orientation) => {
  const direction = orientation
    ? directionsList[Math.round(((orientation %= 360) < 0 ? orientation + 360 : orientation) / 45) % 8]
    : "";
  return direction;
};
