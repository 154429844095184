import React from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
export function NotFound() {
  const navigate = useHistory();
  useEffect(() => {
    setTimeout(() => {
      navigate.push("/");
    }, 10000);
  }, []);
  return (
    <>
      <div className="Container404">
        <div className="Heading404">
          <h1>404</h1>
        </div>
        <h2 style={{ fontSize: "32px" }}>The page ( project or tower ) does not exist.</h2>
        <h2 style={{ fontSize: "32px", paddingTop: "20px", paddingBottom: "25px" }}>Redirecting to Homepage (in 10 seconds)....</h2>
        <Link to="/" style={{ textDecoration: "none" }}>
          {" "}
          <h4 id="homeLink404">Or click here to be taken to the main page </h4>{" "}
        </Link>
      </div>
    </>
  );
}

export default function BypassNotFound() {
  return null;
}
