import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Layout } from "component_tree/shared";
import Spinner2 from "component_tree/shared/spinner/Spinner2";
import AppFlow from "pages/all_projects/project/tower/Apps/AppFlow";
import Apps from "pages/all_projects/project/tower/Apps";
const ProjectsHome = lazy(() => import("./all_projects"));
const ProjectDetails = lazy(() => import("./all_projects/project"));
const ProjectSettings = lazy(() => import("./all_projects/project/settings"));
const TowerDetails = lazy(() => import("./all_projects/project/tower"));
const ProjectExport = lazy(() => import("./all_projects/project/export-data"));
const TowerPropertyOptions = lazy(() => import("./all_projects/project/tower/property-options"));
const CreateProject = lazy(() => import("./all_projects/create"));
const CreateLocation = lazy(() => import("./all_projects/project/create-location"));
const AlertPage = lazy(() => import("./alerts"));
// import AlertPage from "./alerts"

const Dashboard = ({ progress, setProgress }) => {

  return (
    <>
      <Router>
        <Switch>
          <Suspense
            fallback={
              <div>
                <Spinner2 />
              </div>
            }
          >
            <Route path="/">
              <Layout>
                <Switch>
                  <Route exact path="/" component={ProjectsHome} />
                  <Route exact path="/view_data" component={ProjectsHome} />
                  <Route exact path={"/create"} component={CreateProject} />
                  <Route exact path="/:projectId/create" component={CreateLocation} />
                  <Route exact path="/:projectId/settings" component={ProjectSettings} />
                  <Route exact path="/:projectId/map-view" component={ProjectDetails} />
                  <Route exact path="/:projectId/export-data" component={ProjectExport} />
                  <Route exact path="/alerts" component={AlertPage} />
                  <Route exact path="/:projectId" component={ProjectDetails} />
                  <Route exact path="/:projectId/:towerId/tower/:property" component={TowerPropertyOptions} />
                  <Route exact path="/:projectId/:towerId/tower/:property/timeline" component={TowerPropertyOptions} />
                  <Route exact path="/:projectId/:towerId/tower/apps/:app_uuid" component={Apps} />
                  <Route path="/:projectId/:towerId/tower/:property/:app_uuid/:run_uuid" component={AppFlow}/>
                  <Route path="/:projectId/:towerId" component={TowerDetails} />
                  <Route path={"*"} component={() => <Redirect to={"/not-found"} />} />
                </Switch>
              </Layout>
            </Route>
          </Suspense>
        </Switch>
      </Router>
    </>
  );
};

export default Dashboard;
