import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Spinner } from "component_tree/shared";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment, IconButton, Checkbox, Snackbar } from "@material-ui/core";
import { sortBy } from "lodash";
import { SensorConfiguration, MountingArrangement, SensorCalibration } from ".";
import { CheckIcon, ErrorIcon, TrashRed } from "assets/new";
import { capUnderSentence, getIconForMeasurementPoint, getMeasurementPointTitle, handleErrorFromGQL } from "utils";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  ADD_DATA_COLUMN,
  DELETE_MEASUREMENT_POINT_SENSOR_DETAILS,
  GET_MEASURMENT_TYPES_USING_SENSOR_TYPE,
  GET_TOWER_BY_ID,
  UPDATE_DATA_COLUMNS,
  UPDATE_DATA_COLUMNS_STATS,
  UPDATE_MEASUREMENT_POINT,
  UPDATE_MEASUREMENT_POINT_SENSOR_DETAILS,
  UPDATE_SENSOR,
} from "services";
import "./SensorDetail.css";
import { DataColumns } from "component_tree/shared/Measurement/DataColumns";
import SensorForm from "./SensorForm";
import { useContext, useEffect, useState } from "react";
import { ErrorContext } from "contexts/misc/context";
import MeasurementTabs from "component_tree/shared/Measurement/MeasurementTabs";
import clsx from "clsx";
import { MoreVertOutlined } from "@material-ui/icons";
import { format } from "date-fns";
import { Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { SplitMeasurements } from "component_tree/shared/Measurement/SplitMeasurements";
import { raptInstance } from "utils/Axios";
import DeleteIcon from '@material-ui/icons/Delete';
import { useConfirmation } from "hooks/useModal";

const useStyles = makeStyles((theme) => ({
  senDetails: {
    padding: "20px 25px 30px",
  },
  root: {
    flexGrow: 1,
  },
  toolDiv: {
    "&.bgdark": {
      background: theme.palette.primary.main + "05",
    },
  },
  sensorRows: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: "0 20px",
    paddingBottom: "20px",
    marginTop: 20,
    marginBottom: 10,
    borderBottom: "1px solid rgba(30, 36, 71, 0.1)",
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    gap: "10px",
    flex: 1,
    "& > .MuiFormControl-root": {
      backgroundColor: "rgb(242,242,244)",
      width: "100px",
      padding: "4px",
      borderRadius: "4px",
    },
    "& input": {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
    },
  },
  googleMap: {
    width: "100%",
    "& iframe": {
      width: "100%",
      height: "100%",
      border: "none",
    },
  },
  towerBody: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",
  },
  towerHead: {
    backgroundColor: "var(--white)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 1.5rem",
    height: "4.2rem",
    fontFamily: "Roboto",
  },
  arrowIcon: {
    width: "8px",
    height: "8px",
  },
  towerBodyLeft: {
    width: "calc(100% - 25rem)",
  },
  towerBodyRight: {
    width: "100%",
    transition: "all .3s ease-in",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  sensorDetails: {
    width: "100%",
    paddingBottom: "35px",
  },
  towerStatus: {
    display: "flex",
    marginBottom: "40px",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  towerStatusName: {
    "& p": {
      fontFamily: "Roboto",
      color: theme.palette.primary.main,
      fontSize: "11px",
      lineHeight: "15px",
      letterSpacing: "0.3px",
      opacity: ".3",
    },
    "& h3": {
      fontFamily: "Roboto",
      marginTop: "3px",
      fontSize: "2rem",
      letterSpacing: "0.2px",
      fontWeight: 500,
      lineHeight: "20px",
      objectFit: "contain",
      color: theme.palette.primary.main,
    },
  },
  towerStatusBtn: {
    "& button": {
      width: "13.3rem",
      "& MuiButton-startIcon": {
        fontSize: "1.3rem",
      },
    },
  },
  towerDetailsCard: {
    "& .items-wrap": {},
  },
  towerDetailsItem: {
    background: "#fff",
    borderRadius: "6px",
    color: theme.palette.primary.main,
    transition: "all .3 ease-in",
    "&:not(:first-child)": {
      marginTop: "2.5rem",
    },
    "&:hover": {
      "& h3": {
        opacity: "1",
      },
    },
    "& h3": {
      fontSize: "14px",
      padding: "14px 15px",
      display: "flex",
      alignItems: "center",
      opacity: "0.8",
      "& .icon": {
        marginRight: "10px",
      },
    },
    "& ul": {
      "& li": {
        fontSize: "12px",
        borderTop: "1px solid rgba(0, 0, 0, 0.02)",
        padding: "14px 0",
        paddingLeft: "3rem",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        trnasition: "all .3s ease-in",
        "&:hover": {
          color: "#fff",
          transform: " scale(1.05)",
          borderRadius: "4px",
          background: theme.palette.secondary.main,
          "& .icon-add,& strong, & p": {
            color: "#fff",
            opacity: "1",
          },
        },
        "& strong": {
          fontSize: "13px",
          color: "#1e2447",
          opacity: "0.8",
          letterSpacing: "1.08",
          marginRight: "8px",
        },
        "& p": {
          color: "#1e2447",
          opacity: "0.5",
        },
        "& .icon-add": {
          color: "#00c48c",
        },
      },
    },
  },
  measurementDetailsHeader: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: "30px 15px 24px",
    fontFamily: "Roboto",
    color: theme.palette.primary.main,
    borderBottom: "1px solid rgba(30, 36, 71, 0.1)",
    "& h2": {
      fontWeight: 700,
      letterSpacing: "0.2px",
      lineHeight: "23px",
      // marginBottom: "10px",
    },
    "& h3": {
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "15px",
      letterSpacing: "0.1px",
      opacity: "0.6",
    },
  },
  towerRightHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "25px",
    fontFamily: "Roboto",
    color: theme.palette.primary.main,
    borderBottom: "1px solid rgba(30, 36, 71, 0.1)",
    "& h2": {
      fontSize: "20px",
      fontWeight: 700,
      letterSpacing: "0.2px",
      lineHeight: "23px",
      // marginBottom: "10px",
    },
    "& h3": {
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "15px",
      letterSpacing: "0.1px",
      opacity: "0.6",
    },
  },

  logoIcon: {
    boxShadow: "0 0 26px 6px rgba(0, 0, 0, 0.05)",
    background: "#ffffff",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    cursor: "pointer",
    "& .icon": {
      width: "20px",
      height: " 20px",
    },
  },
  towerBodyBasic: {
    padding: "20px 20px 30px 20px",
    borderTop: "1px solid rgb(30 , 36 , 71 , .1)",
    "&.active": {
      paddingRight: "210px",
    },
    "& h3": {
      color: theme.palette.primary.main,
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: "1.07",
      letterSpacing: "normal",
      marginBottom: "1.4rem",
    },
  },
  towerBodyBasicForm: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formField: {
    marginTop: "16px",
    "& p": {
      fontSize: "13px",
      color: "#000",
      opacity: "0.5",
      lineHeight: "1.23",
    },

    "& .date": {
      width: "100px",
      "&:last-child": {
        marginLeft: "10px",
      },
      "& input": {
        width: "100%",
      },
    },
  },
  formFieldDate: {
    display: "flex",
    justifyContent: "space-between",
  },
  towerBodyClose: {
    position: "absolute",
    top: "23px",
    left: "-28px",
    padding: "12px",
    cursor: "pointer",
    background: "#fff",
    borderRadius: "4px",
    zIndex: theme.spacing(2),
    boxShadow: " 0 2px 12px -2px rgba(0, 0, 0, 0.04)",
    transition: "all .3s ease-in",
    "&:hover svg": {
      opacity: "1",
    },
    "& svg": {
      opacity: "0.7",
    },
  },
  towerBodySensor: {
    padding: (props) => (props.show ? "20px 25px 32px 25px" : "20px 20px 30px 15px"),
    "& h3": {
      fontSize: "15px",
      letterSpacing: "1.07px",
      color: theme.palette.primary.main,
    },
    "& button": {
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      lineHeight: "1.08",
      cursor: "pointer",
      background: "unset",
      border: "unset",
      color: theme.palette.primary.main,
      "&.delete": {
        color: "#FF647C",
      },
      "& svg": {
        fill: "#FF647C",
      },
    },
  },
  towerBodySensorHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& .delete": {
      "& svg": {
        marginRight: "8px",
      },
      "& span": {
        opacity: "0.7",
        fontFamily: "Roboto",
        fontSize: "11px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1",
        letterSpacing: "normal",
        color: "var(--dark-bue)",
      },
    },
  },
  towerBodySensorTable: {
    marginTop: "2rem",
    "& table ": {
      borderCollapse: "collapse",
      width: "100%",

      "& th": {
        padding: "10px 5px",
        textAlign: "center",
        fontSize: "12px",
        marginBottom: "10px",
        color: "rgba(0 , 0 , 0 , .3)",
        borderBottom: " solid 1px rgb(30 , 36 , 71 , .1)",
        "&:first-child": {
          textAlign: "left",
        },
      },
      "& td": {
        padding: "5px",
        "&:first-child": {
          "& .control-form": {
            width: "123px",
            textAlign: "left",
          },
        },
        "& .control-form": {
          width: "100%",
          "&.date": {
            width: "107px",
            padding: "4px 0px 4px 8px",
          },
        },
      },
    },
    "& .calibration": {
      "& td": {
        "&:first-child": {
          "& .control-form": {
            width: "153px",
          },
        },
        "& .control-form": {
          height: "34px",
          lineHeight: "34px",
        },
      },
    },
  },
  towerBodySensorForm: {
    marginTop: "8px",
    "& .control-form_border": {
      marginTop: "10px",
      width: "220px",
      fontSize: "13px",
      padding: "9px 8px",
      borderRadius: "2px",
      color: "rgba(0 , 0 , 0 , .7)",
      border: "solid 1px rgba(30 , 36 , 71 , .15)",
    },
  },
  pdf: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "3px 4px",
    width: "150px",
    fontSize: "11px",
    borderRadius: "2px",
    marginTop: "4px",
    color: "rgba(0 , 0 , 0 , .8)",
    border: " solid 1px rgba(30, 36, 71, 0.15)",
    "& img": {
      width: "27px",
      marginRight: "5px",
    },
    "& input": {
      display: "none",
    },
  },
  topInputs: {
    background: "#1E24470F",
    padding: "6px 8px",
    "& input": {
      fontSize: "12px",
      width: "90px",
      padding: "2px 0 0 0 !important",
    },
  },
}));

const useSensorRowStyles = makeStyles(() => {
  return {
    sensorRowContainer: {
      display: "flex",
      backgroundColor: "",
      padding: "2px 10px",
      cursor: "pointer",
      // borderRadius: "4px",
      border: "1px solid #cfcfcf",
      marginTop: "10px",
      // boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
      alignItems: "center",
      justifyContent: "space-between",
      "&:hover": { backgroundColor: "#f1f2f4", color: "black" },
    },
    sensorRowDescription: {
      display: "flex",
      alignItems: "center",
      width: '100%',
      justifyContent: 'space-between',
      "& > p": {
        fontSize: 12,
      },
      padding: "5px",
    },
    activeRow: {
      backgroundColor: "#00c48c",
      color: "#fff",
      "&:hover": { backgroundColor: "#00c48c", color: "white" },
    },
    leftAlign: {
      display: "flex",
      alignItems: "center",
    },
  };
});

const validateSensorForm = (values) => {
  const errors = {};

  if (!values.sensor_type_id) {
    errors.sensor_type_id = "Sensor Type Required";
  }
  if (!values.date_from) {
    errors.date_from = "Required";
  }

  return errors;
};
let isFormChange = false;

const SensorRow = function ({
  data,
  isActive,
  onClickMenu,
  handleUpdateSensor,
  // model,
  onSelectSensorTypeID,
  keyIter,
  onSetActiveSensor,
  uniqueWord,
  showCheckbox,
  onCheckboxChange,
  selectedSensorUuids,
  measurement_point_uuid,
  deleteEntry
}) {
  const classes = useSensorRowStyles();
  const { oem, model, date_from, date_to } = data;
  let sensorHeaderStr = oem ? oem + " - " : "";
  sensorHeaderStr += model ? model + "" : "Unknown model ";
  // sensorHeaderStr += data.serial_number ? " , S/N: " + data.serial_number : " , S/N: Serial unknown ";
  sensorHeaderStr += ',      '
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {showCheckbox && (
          <Checkbox
            style={{ marginTop: 10, fontSize: 20 }}
            checked={selectedSensorUuids.includes(data.uuid)}
            onChange={() => onCheckboxChange(data.uuid)}
          />
        )}
        <div
          style={{ width: "100%" }}
          onClick={() => {
            if (isActive) {
              onSetActiveSensor(null, uniqueWord);
            } else {
              onSetActiveSensor(data.uuid, uniqueWord);
            }
          }}
          className={classes.sensorRowContainer + `${isActive ? " " + classes.activeRow : ""} `}
        >
          <div className={classes.sensorRowDescription}>
            <h2 className={"ca__sensor-row-tile-oem-model"}>
              {`${sensorHeaderStr}`}
              {/* <p className={"oem-model"}>{oem} {" "}</p> - <p className={"oem-model"}>{'  '} {model} {'  '}</p> */}
              {/* <p className={"date"}> */}[ {date_from ? format(new Date(date_from), "yyyy-MM-dd") : ""}
              {date_to ? ` to ${format(new Date(date_to), "yyyy-MM-dd")}` : ""} ]
            </h2>
            <DeleteIcon className="icon" onClick={(e) => deleteEntry(e, data.uuid)} />
          </div>
          <div className={classes.sensorRowIcon}>
            <IconButton size="small" onClick={onClickMenu}>
              {/* <MoreVertOutlined /> */}
            </IconButton>
          </div>
        </div>
      </div>
      {isActive && (
        <>
          <SensorForm
          measurement_point_uuid={measurement_point_uuid}
            data={data}
            handleSubmit={handleUpdateSensor}
            formType="update"
            onSelectSensorTypeID={onSelectSensorTypeID}
            keyIter={keyIter}
          />
        </>
      )}
    </>
  );
};

export const SensorDetail = ({
  show = true,
  towerDetails,
  onDeleteMeasurement,
  measurementPoints,
  onDeleteSensor,
  measurementTypesList,
  sensorTypesList,
  statsDropdown,
  onCreateNewMeasurement,
  onUpdateMeasurement,
  colsDropdown,
  selectedRow,
  selectedSensorIndex,
  onSelectSensorTypeID,
  setActiveSensor2,
  handlingUpdate,
  updateMeasurementPage
}) => {
  const { setErrors } = useContext(ErrorContext);
  const [isEditing, setIsEditing] = useState();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const classes = useStyles({ show });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [fetchMeasurement, { data: measurementType }] = useLazyQuery(GET_MEASURMENT_TYPES_USING_SENSOR_TYPE);
  const mTypes = measurementType?.measurement_types_per_sensor || [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCheckbox, setShowCheckBox] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const confirm = useConfirmation();
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const initialSelValues = {
    sensor_uuids: [],
    sensor_config_uuids: [],
    mounting_arrangement_uuids: [],
    data_column_ids: [],
    target_measurement_type_id: '',
    target_measurement_height_m: '',
    target_measurement_orientation: '',
    current_measurement_uuid: '',
  };
  
  const [selectedData, setSelectedData] = useState(initialSelValues);
  const [toast, setToast] = useState({type:'', message:''});
  const [isLoading, setIsLoading] = useState(false);
  const handleSaveClicked = async () => {
    setIsLoading(true);
    await raptInstance.post(`/${towerId}/change-measurement-point`, selectedData)
    .then(response => {
      updateMeasurementPage();
      setToast({type:'success', message:'Successfully updated measurement point'});
    }).catch(error => {
      setToast({type:'error', message:error.message});
    }).finally(() => {
      setSelectedData(initialSelValues);
      setIsLoading(false);
      setShowCheckBox(false);
    })
  }

  const handleCheckboxChange = (section, uuid) => {
    setSelectedData((prevData) => ({
      ...prevData,
      [section]: prevData[section].includes(uuid)
        ? prevData[section].filter((id) => id !== uuid)
        : [...prevData[section], uuid],
    }));
  };
  const {
    measurements,
    height: height_m,
    uuids,
    sensor_type: sensor_type_id,
    sensorRows: { data: measurement_point_sensors },
    orientationOrg: orientation,
  } = selectedRow;
  const { name: towerName, uuid: towerId } = towerDetails;
  const [updateDataColumn] = useMutation(UPDATE_DATA_COLUMNS, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const [updateDataColumnStats] = useMutation(UPDATE_DATA_COLUMNS_STATS, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const [addDataColumn] = useMutation(ADD_DATA_COLUMN, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const handleCreateDataColumn = async (uuid, newStats, newCol) => {
    const data = await addDataColumn({
      variables: {
        measurement_point_uuid: uuid,
        column_name: `${newCol}`,
        statistic_type_id: `${newStats}`,
      },
    });

    if (data.errors) {
      //handleErrorFromGQL(data.errors, setErrors);
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  };

  const [updateSensorDetail] = useMutation(UPDATE_SENSOR, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const [updateMeasurementSensorDetail] = useMutation(UPDATE_MEASUREMENT_POINT_SENSOR_DETAILS, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const [deleteMeasurementPointSensor] = useMutation(DELETE_MEASUREMENT_POINT_SENSOR_DETAILS, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

const handleUpdateSensor = async (values, setSubmitting, uuid, meas_uuid) => {
  try {
    await Promise.all([
      updateSensorDetail({
        variables: {
          sensorId: uuid,
          sensor_type_id: values.sensor_type_id,
          model: values.model,
          oem: values.oem,
          serial_number: values.serial_number,
        },
      }),
      meas_uuid &&
        updateMeasurementSensorDetail({
          variables: {
            measurementPointId: meas_uuid,
            sensorId: uuid,
            date_from: values.date_from,
            date_to: values.date_to,
          },
        }),
    ]);
  } catch {
    console.log('error occurred while updating');
  } finally {
    setTimeout(() => {
      handlingUpdate();
      setSubmitting(false);
      setIsEditing(false);
    }, 1000);
  }
};

  // const handleUpdateMeasurement = async (values, setSubmitting) => {
  //   const data = await updateMeasurementPoint({
  //     variables: {
  //       measurementPointId: pointData.uuid,
  //       height: values.measurementHeight,
  //       measurementTypeId: values.measurementType,
  //       name: values.measurementName,
  //       orientation: Number(values.measurementOrientation),
  //     },
  //   });

  //   if (data.errors) {
  //     handleErrorFromGQL(data.errors, setErrors);
  //   } else {
  //     refetchMeasurement();
  //   }
  //   setTimeout(() => {
  //     setSubmitting(false);
  //     setIsEditing(false);
  //   }, 1000);
  // };

  const validateMeasurementForm = (values) => {
    const errors = {};

    if (!values.measurementHeight) {
      errors.measurementHeight = "measurementHeight Required";
    }

    return errors;
  };

  const renderMeasurementProfileDetails = () => {
    // const { height_m, orientation } = pointData;

    const tabs = measurements.map(({ measurement_type_id }) => {
      return {
        id: measurement_type_id,
        label: capUnderSentence(measurement_type_id),
      };
    });

    const handleMenuClick = (action = "") => {
      switch (action) {
        case "edit":
        case "delete":
        default:
          return null;
      }
      setMenuAnchorEl(null);
    };
    const deleteEntry = async (e, uuid) => {
      console.log("delete entry", uuid);
      e.stopPropagation();
      const measurementPointId = measurements[activeTabIndex]?.uuid;
      confirm({
        title: 'Delete Sensor Details',
        content: 'This will delete clicked sensor detail. It cannot be reversed. Are you sure you want to proceed',
        onConfirm: async (isAgreed) => {
            if(isAgreed){
              try {
                const response = await deleteMeasurementPointSensor({
                  variables: {
                    measurementPointId,
                    sensorId:uuid
                  }
                });
                if (response.data.delete_measurement_point_sensor.affected_rows > 0) {
                  console.log("Sensor entry deleted successfully.");
                } else {
                  console.log("No entry was deleted.");
                }
              } catch (error) {
                console.error("Error deleting sensor entry:", error);
              }
            }

        }
    })

    }
    return (
      <>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={() => handleMenuClick("edit")}>Edit</MenuItem>
          <MenuItem onClick={() => handleMenuClick("delete")}>Delete</MenuItem>
        </Menu>
        <div className={classes.measurementDetailsHeader}>
          <div>
            <h2>{capUnderSentence(sensor_type_id)}</h2>
          </div>
          <div style={{ flex: 1 }}>
            <Formik
              initialValues={{
                measurementHeight: height_m,
                measurementOrientation: orientation,
              }}
              enableReinitialize={true}
              validate={validateMeasurementForm}
              onSubmit={(values, { setSubmitting }) => {
                onUpdateMeasurement(
                  values.measurementHeight,
                  values.measurementOrientation,
                  { height_m, orientation, sensor_type_id, uuids },
                  setSubmitting,
                  () => {
                    setIsEditing(false);
                  }
                );
                // handleUpdateMeasurement(values, setSubmitting);
              }}
            >
              {({
                isSubmitting,
                handleSubmit,
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className={classes.inputContainer}>
                    <TextField
                      name="measurementHeight"
                      value={values.measurementHeight}
                      size={"small"}
                      type={"number"}
                      onChange={(e) => {
                        handleChange(e);
                        setIsEditing(true);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      onBlur={handleBlur}
                      placeholder="Height (m)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"> m </InputAdornment>,
                        disableUnderline: true,
                      }}
                      className={classes.topInputs}
                      error={errors.measurementHeight && touched.measurementHeight}
                    />

                    <TextField
                      name="measurementOrientation"
                      size={"small"}
                      type={"number"}
                      value={values.measurementOrientation}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        handleChange(e);
                        setIsEditing(true);
                      }}
                      onBlur={handleBlur}
                      placeholder="Orientation (deg)"
                      InputProps={{
                        endAdornment: <InputAdornment position="end"> deg </InputAdornment>,
                        disableUnderline: true,
                      }}
                      className={classes.topInputs}
                      error={errors.measurementOrientation && touched.measurementOrientation}
                    />
                    <div>
                      <IconButton aria-label="more" aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
                        <MoreVertIcon style={{ fontSize: 20 }} />
                      </IconButton>
                      <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <MenuItem
                          onClick={() => {
                            setShowCheckBox(true);
                            setAnchorEl(null);
                          }}
                        >
                          <CallSplitIcon />
                          Split measurements
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <div>
                    {isEditing && (
                      <footer className="tm__submit">
                        <button type="submit" className="tm__submit-btn" disabled={isSubmitting}>
                          {isSubmitting ? <Spinner color="white" /> : "Save"}
                        </button>
                        <button
                          type="button"
                          className="tm__submit-btn tm__submit-cancel"
                          onClick={() => {
                            resetForm();
                            setIsEditing(false);
                          }}
                        >
                          Cancel
                        </button>
                      </footer>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
          {/* <div className={classes.logoIcon}>{getIconForMeasurementPoint(measurementPointTitle, "icon")}</div> */}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={toast?.message}
          autoHideDuration={3000}
          onClose={() => setToast(null)}
          message={
            <div className="cp__snack--content">
              {toast?.type === "success" ? <CheckIcon /> : <ErrorIcon />}
              <p>{toast?.message}</p>
            </div>
          }
          key={toast?.message}
          className={toast?.type === "success" ? "cp__snack--success" : "cp__snack--error"}
        />
        {/* <div className={classes.sensorRows}></div> */}
        <div className={classes.senDetails}>
          <h3 className="" style={{ fontWeight: "500", fontSize: "15px" }}>
            Sensor Details
          </h3>
          {measurement_point_sensors
            .sort((a, b) => new Date(a.date_from).getTime() - new Date(b.date_from).getTime())
            .map((data, index) => {
              return (
                <SensorRow
                  selectedSensorUuids={selectedData.sensor_uuids}
                  showCheckbox={showCheckbox}
                  onCheckboxChange={(uuid) => handleCheckboxChange("sensor_uuids", uuid)}
                  data={{ ...data.sensor, date_from: data.date_from, date_to: data.date_to }}
                  isActive={selectedSensorIndex === data.sensor.uuid}
                  onClickMenu={(e) => {
                    setMenuAnchorEl(e.currentTarget);
                  }}
                  key={data.sensor.uuid}
                  handleUpdateSensor={handleUpdateSensor}
                  onSelectSensorTypeID={onSelectSensorTypeID}
                  keyIter={index}
                  onSetActiveSensor={setActiveSensor2}
                  measurement_point_uuid={measurement_point_sensors[0].measurement_point_uuid}
                  uniqueWord={selectedRow.activeRowInfo.mp}
                  deleteEntry={deleteEntry}
                />
              );
            })}
        </div>
        <div className={classes.leftAlign}>
          <MeasurementTabs
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            measurementTypes={mTypes
              .filter(({ measurement_type_id }) => {
                return !mpSet.has(measurement_type_id);
              })
              .map(({ measurement_type_id }) => measurement_type_id)}
            onChangeActiveTabIndex={(newActiveIndex) => setActiveTabIndex(newActiveIndex)}
            onAddMeasurement={(newMeasurement) => {
              onCreateNewMeasurement(newMeasurement, {
                sensorHeight: height_m,
                sensorOrientation: orientation,
                sensorType: sensor_type_id,
                uuid: measurement_point_sensors[0]?.sensor?.uuid,
              });
            }}
          />
        </div>
        <div className={clsx(classes.toolDiv)}>
          <DataColumns
            showCheckbox={showCheckbox}
            selectedDataColumnIds={selectedData.data_column_ids}
            onCheckboxChange={(uuid) => handleCheckboxChange("data_column_ids", uuid)}
            data={measurements[activeTabIndex]?.data_columns || []}
            isMeasurement={false}
            onCreate={(newStat, newCol) => {
              return handleCreateDataColumn(measurements[activeTabIndex]?.uuid, newStat, newCol);
            }}
            updateDataColumn={updateDataColumn}
            statsDropdown={statsDropdown}
            colsDropdown={colsDropdown}
            updateDataColumnStats={updateDataColumnStats}
          />
          <SensorConfiguration
            showCheckbox={showCheckbox}
            selectedLoggerSensorConfigUuids={selectedData.sensor_config_uuids}
            onCheckboxChange={(uuid) => handleCheckboxChange("sensor_config_uuids", uuid)}
            show="Logger Sensor Configuration"
            sensor_configs={measurements[activeTabIndex]?.sensor_configs || []}
            towerId={towerId}
            measurementPointId={measurements[activeTabIndex]?.uuid}
          />
          <MountingArrangement
            showCheckbox={showCheckbox}
            selectedMountingArrangementUuids={selectedData.mounting_arrangement_uuids}
            onCheckboxChange={(uuid) => handleCheckboxChange("mounting_arrangement_uuids", uuid)}
            show={show}
            // isCollapsed={Boolean(document.getElementById("right-preview-sidebar")?.getAttribute("data-isexpanded"))}
            mounting_arrangements={measurements[activeTabIndex]?.mounting_arrangements || []}
            towerId={towerId}
            measurementPointId={measurements[activeTabIndex]?.uuid}
          />
          <div>
            <h3
              style={{
                paddingLeft: 20,
                paddingTop: 20,
                fontWeight: 500,
                fontSize: 15,
                fontStyle: "normal",
                fontFamily: "Roboto",
                color: "#000",
              }}
            >
              Calibrations
            </h3>
            <div>
              {measurement_point_sensors
               .sort((a, b) => new Date(a.date_from).getTime() - new Date(b.date_from).getTime())
                .map((data, index) => {
                  // .map(({ sensor }, index) => {
                  return (
                    <SensorCalibration
                      dis="sca__upload-documentation2"
                      show={show}
                      key={data.sensor.uuid}
                      oem={data.sensor.oem}
                      model={data.sensor.model}
                      serial={data.sensor.serial_number}
                      date_from={data.date_from}
                      date_to={data.date_to}
                      calibrations={data.sensor.calibrations}
                      sensorId={data.sensor.uuid}
                      towerId={towerId}
                      isMeasurement={false}
                      isActive={selectedSensorIndex === data.sensor.uuid}
                    />
                  );
                })}
            </div>
          </div>
          {showCheckbox && (
            <SplitMeasurements
              measurementPointId={measurements[activeTabIndex]?.uuid}
              measurementTypesList={measurementTypesList}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              towerId={towerId}
              initialSelValues={initialSelValues}
              setShowCheckBox={setShowCheckBox}
              handleSaveClicked={handleSaveClicked}
              isLoading={isLoading}
            />
          )}
        </div>
      </>
    );
  };
  const mpSet = new Set((measurementPoints || []).map(({ measurement_type_id }) => measurement_type_id));
  return <div className={classes.towerBodyRight}>{renderMeasurementProfileDetails()}</div>;
};
