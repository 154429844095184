import { makeStyles } from "@material-ui/core";

import { Header } from "./Header";
import { SideBar } from "./SideBar";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    display: "block",
    marginRight: "6px",
  },
  tower: {
    marginLeft: "3.5rem",
    display: "block",
    padding: "1.1rem 2.2rem",
    textAlign: "center",
    background: "rgb(255,255 ,255,  .05)",
  },
  title: {
    fontSize: "14px",
    letterSpacing: "0.3px",
  },
  dashboardWrap: {
    display: "flex",
    flex: 1,
    minHeight: "100vh",
  },
  header: {
    background: "#0e1625",
    boxShadow: "none",
  },
  headerWrap: {
    minHeight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoWrap: {
    display: "flex",
    alignItems: "center",
  },
  userName: {
    "& a": {
      textDecoration: "none",
      width: "28px",
      height: "28px",
      color: "#fff",
      fontSize: "14px",
      textTransform: "uppercase",
      borderRadius: "14px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgb(255,255 ,255,  .1)",
    },
  },
  bodyWrap: {
    display: "flex",
    flex: 1,
    minHeight: "100%",
    height: "calc(100vh - 50px)",
    marginTop: "50px",
  },
  main: {
    display: "flex",
    width: "calc(100% - 25rem)",
    backgroundColor: "#F1F2F4",
    // backgroundColor: "rgba(30,36,71,.03)",
    position:'relative',
    height: "100%",
    // overflow: "scroll !important",
  },
}));

export const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <section className={classes.dashboardWrap}>
      <Header />
      <div className={classes.bodyWrap}>
        <SideBar />
        <main className={classes.main}>{children}</main>
      </div>
    </section>
  );
};
