import { useQuery } from "@apollo/client";
import { ErrorContext } from "contexts/misc/context";
import { useMemo } from "react";
import { useContext } from "react";
import { FETCH_MAINTENANCE_ENTRIES, GET_TOWER_BY_ID } from "services";
import { handleErrorFromGQL } from "utils";

export const useFullTowerData = (towerId)=>{
    const {setErrors} = useContext(ErrorContext);
    const {
        data: towerData,
        loading: towerLoading,
        error: towerError,
        // refetch: refetchTower,
      } = useQuery(GET_TOWER_BY_ID, {
        variables: { towerId: towerId },
        onError: (err) => {
          handleErrorFromGQL(err, setErrors);
        },
      });
    const towerDetails = towerData?.measurement_location?.[0];
    const { data: entriesData, loading: fetchingEntries } = useQuery(FETCH_MAINTENANCE_ENTRIES, {
        variables: { id: towerId },
    });

    return useMemo(()=>{
        return(
            {
                loading: towerLoading || fetchingEntries,
                error: towerError,
                data: towerDetails? {
                    ...towerDetails,
                    maintenance_entries: entriesData?.maintenance_entries || []
                }: null
            }
        )
    }, [towerDetails, entriesData, towerLoading, fetchingEntries, towerError])
}