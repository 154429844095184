import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";

import * as Yup from "yup";

import { ArrowWhiteUp, ArrowGrayDown, TickWhite, TrashRed } from "assets/new";
import { PlusGreenIcon } from "assets";
import { DetailsBox } from "component_tree/shared/DetailsBox";
import { Spinner } from "component_tree/shared";
import {
  UPDATE_SENSOR_CONFIG,
  DELETE_SENSOR_CONFIG,
  CREATE_SENSOR_CONFIG,
  GET_TOWER_BY_ID,
  FETCH_MEASUREMENT_UNIT,
} from "services";
import "./SensorConfig.css";
import { ErrorContext, RightSidebarContext } from "contexts/misc/context";
import { v4 as uuidv4 } from "uuid";
import { handleErrorFromGQL } from "utils";
import { format } from "date-fns";
import MeasurementAccordion from "component_tree/shared/Measurement/MeasurementAccordion";
import { Checkbox } from "@material-ui/core";
import { useConfirmationDialogue } from "contexts/misc/ConfirmationDialogueContext";

const getValidationSchema = (uuid) => {
  const schema = Yup.object().shape({
    [`slope-${uuid}`]: Yup.number().required("Required").typeError("Number required"),
    [`date_from-${uuid}`]: Yup.date().required("Required"),
    [`date_to-${uuid}`]: Yup.date("Invalid date format").notRequired().nullable(),
    [`offset-${uuid}`]: Yup.number().required("Required").typeError("Number required"),
    [`height_m-${uuid}`]: Yup.number().required("Required").typeError("Number required"),
  });
  return schema;
};

export const SensorConfiguration = ({
  show,
  sensor_configs,
  onDeleteLoggerConfig,
  towerId,
  measurementPointId,
  title,
  val,
  showCheckbox,
  selectedLoggerSensorConfigUuids,
  onCheckboxChange
}) => {
  const [showForm, setShowForm] = useState(null);
  const [hide, setHide] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const { isCollapsed } = useContext(RightSidebarContext);
  let [sensorConfigsList, setSensorConfigsList] = useState(() => sensor_configs);
  const { setErrors } = useContext(ErrorContext);
  const { data: measurement_units, error: measurement_units_error, loading: measurement_units_loading } = useQuery(
    FETCH_MEASUREMENT_UNIT
  );
  // console.log(sensor_configs, val);
  const [updateSensorConfig] = useMutation(UPDATE_SENSOR_CONFIG, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const [deleteSensorConfig] = useMutation(DELETE_SENSOR_CONFIG, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  const [createSensorConfig] = useMutation(CREATE_SENSOR_CONFIG, {
    errorPolicy: "all",
    refetchQueries: [{ query: GET_TOWER_BY_ID, variables: { towerId } }],
  });

  useEffect(() => {
    if (sensor_configs !== sensorConfigsList) {
      setSensorConfigsList(sensor_configs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensor_configs]);

  const handleNewItem = async () => {
    if (!sensorConfigsList.find((config) => config.uuid === "newItem")) {
      const newConfig = {
        measurementPointId,
        uuid: "newItem",
        name: "New Config",
        date_from: "",
        date_to: "",
        connection_channel: "",
        slope: 0,
        offset: 0,
        height_m: 0,
      };
      setSensorConfigsList([...sensorConfigsList, newConfig]);
      const uuid = newConfig.uuid;
      // setSensorConfigsList([...[{ uuid: "newItem" }], ...sensorConfigsList]);
      setShowForm({ [uuid]: true });
      setHide(true);
      setNewItem(true);
    }
  };

  const handleSensorConfig = async (values, setSubmitting, configId) => {
    if (configId !== "newItem") {
      const data = await updateSensorConfig({
        variables: {
          sensorConfigId: configId,
          name: values[`name-${configId}`],
          date_from: values[`date_from-${configId}`],
          date_to: values[`date_to-${configId}`],
          connection_channel: values[`connection_channel-${configId}`],
          slope: values[`slope-${configId}`],
          offset: values[`offset-${configId}`],
          height_m: values[`height_m-${configId}`],
          serial_number: values[`serial_number-${configId}`],
          notes: values[`notes-${configId}`],
          measurement_units_id: values[`measurement_units_id-${configId}`],
        },
      });
      if (data.errors) {
        //handleErrorFromGQL(data.errors, setErrors);
      }
    } else {
      const res = await createSensorConfig({
        variables: {
          measurementPointId,
          name: values[`name-${configId}`],
          date_from: values[`date_from-${configId}`],
          date_to: values[`date_to-${configId}`],
          connection_channel: values[`connection_channel-${configId}`],
          slope: values[`slope-${configId}`],
          offset: values[`offset-${configId}`],
          height_m: values[`height_m-${configId}`],
        },
      });
      if (res.errors) {
        //handleErrorFromGQL(res.errors, setErrors);
      }
    }

    setTimeout(() => {
      setEditing(false);
      setSubmitting(false);
      setHide(false);
      setNewItem(false);
    }, 1000);
  };

  const handleDeleteConfig = async (configId) => {
    await deleteSensorConfig({
      variables: {
        sensorConfigId: configId,
      },
    }).then((res) => setHide(false));
  };
  const { confirm } = useConfirmationDialogue();
  const validateSensorConfigForm = (values, configId) => {
    const errors = {};

    if (!values[`date_from-${configId}`]) {
      errors[`date_from-${configId}`] = "Date From Required";
    }

    return errors;
  };

  const getMeasurementUnitOptions = () => {
    const options = [];
    measurement_units &&
      measurement_units.measurement_units.forEach(({ id }) => {
        options.push({ id });
      });
    return options;
  };

  return (
    <article className="sc__body" style={{ overflow: "auto" }}>
      <div className="sc__head" style={{ paddingBottom: "20px" }}>
        <h3>{show}</h3>
        {!hide && sensorConfigsList.length > 0 && show != "Sensor Details" && (
          <button onClick={handleNewItem}>
            <PlusGreenIcon />
            {show && <span>New configuration</span>}
          </button>
        )}
      </div>
      {/* {console.log(sensorConfigsList)} */}
      {sensorConfigsList
        .slice()
        // .sort((a, b) => a.uuid.localeCompare(b.uuid))
        .sort((a, b) => new Date(a.date_from).getTime() - new Date(b.date_from).getTime())
        .map((config) => {
          return (
            <div key={config.uuid} style={{display:'flex',alignItems:'center'}}>
            {showCheckbox && (
              <Checkbox
              style={{marginBottom:5}}
                checked={selectedLoggerSensorConfigUuids.includes(config.uuid)}
                onChange={() => onCheckboxChange(config.uuid)}
              />
            )}
            <section
            className={`hover_details sc__list-wrap ${showForm?.[config.uuid] && "sc__list-expand"} ${
              showForm?.[config.uuid] && !isCollapsed && "sc__list-uncollapsed"
            }`}
            key={config.uuid}
          >
              <Formik
                initialValues={{
                  [`name-${config.uuid}`]: config.name || null,
                  [`date_from-${config.uuid}`]: config.date_from || null,
                  [`date_to-${config.uuid}`]: config.date_to || null,
                  [`connection_channel-${config.uuid}`]: config.connection_channel || null,
                  [`slope-${config.uuid}`]: config.slope || null,
                  [`offset-${config.uuid}`]: config.offset || null,
                  [`height_m-${config.uuid}`]: config.height_m || null,
                  [`measurement_units_id-${config.uuid}`]: config.measurement_units_id || null,
                  [`serial_number-${config.uuid}`]: config.serial_number || null,
                  [`notes-${config.uuid}`]: config.notes || null,
                }}
                validationSchema={getValidationSchema(config.uuid)}
                enableReinitialize={true}
                validate={(v) => validateSensorConfigForm(v, config.uuid)}
                onSubmit={(values, { setSubmitting }) => handleSensorConfig(values, setSubmitting, config?.uuid)}
              >
                {({
                  isSubmitting,
                  handleSubmit,
                  values,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  setFieldError,
                  resetForm,
                  initialValues,
                }) => {
                  const heading = <>
                        <h3>
                          <span className={`sc_head_span ${showForm?.[config.uuid] && 'sc_head_span-active'}`}>
                            {config.date_from ? format(new Date(config.date_from), "yyyy-MM-dd") : ""}{" "}
                          </span>
                          <span className={`sc_head_span ${showForm?.[config.uuid] && 'sc_head_span-active'}`}>
                            {config.slope ? `Slope: ${config.slope}` : "Slope: N/A"} 
                          </span>
                          <span className={`sc_head_span ${showForm?.[config.uuid] && 'sc_head_span-active'}`}>
                            {config.offset ? `Offset: ${config.offset}` : "Offset: N/A"} 
                          </span>
                          {config.connection_channel && <span className={`sc_head_span ${showForm?.[config.uuid] && 'sc_head_span-active'}`}>
                            {config.connection_channel ? `Ch: ${config.connection_channel.length < 8 ? config.connection_channel : config.connection_channel.slice(0, 8) + "..."}` : ""} 
                          </span>}
                        </h3>

                        <aside>{showForm?.[config.uuid] ? <ArrowWhiteUp /> : <ArrowGrayDown />}</aside>
                  </>
                  const handleAccordianClick = () => {
                    setShowForm({ [config.uuid]: !showForm?.[config.uuid] });
                  };
                  return (
                    <>
                  <MeasurementAccordion 
                    heading={heading}
                    active_bg_color="blue"
                    onClick={handleAccordianClick}
                    open={showForm?.[config.uuid]}
                    >
                       <form onSubmit={handleSubmit} key={config.uuid} id={config.uuid}>
                        <DetailsBox
                          renderHeader={<div />}
                          key={"basicDetails"}
                          rootStyles={isCollapsed ? "sc__box-collapsed" : "sc__box"}
                          inputContainerStyles={!isCollapsed ? "sc__collapsed" : " "}
                          formList={[
                            {
                              value: values[`slope-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                handleChange(val);
                              },
                              clickHandler: (e) => {},
                              label: "Slope",
                              name: `slope-${config.uuid}`,
                              placeholder: "",
                              inputType: "customNum",
                              numType: "decimal",
                              type: "text",
                              error:
                                errors[`slope-${config.uuid}`] && touched[`slope-${config.uuid}`]
                                  ? errors[`slope-${config.uuid}`]
                                  : "",
                            },
                            {
                              value: values[`offset-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                handleChange(val);
                              },
                              clickHandler: (e) => {},
                              label: "Offset",
                              name: `offset-${config.uuid}`,
                              placeholder: "",
                              error:
                                errors[`offset-${config.uuid}`] && touched[`offset-${config.uuid}`]
                                  ? errors[`offset-${config.uuid}`]
                                  : "",
                              inputType: "customNum",
                              numType: "decimal",
                              type: "text",
                            },
                            {
                              value: values[`height_m-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                handleChange(val);
                              },
                              clickHandler: (e) => {},
                              label: "Height",
                              name: `height_m-${config.uuid}`,
                              placeholder: "",
                              inputType: "customNum",
                              numType: "decimal",
                              type: "text",
                              error:
                                errors[`height_m-${config.uuid}`] && touched[`height_m-${config.uuid}`]
                                  ? errors[`height_m-${config.uuid}`]
                                  : "",
                            },
                            {
                              value: values[`connection_channel-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                handleChange(val);
                              },
                              clickHandler: (e) => {},
                              label: "Channel",
                              name: `connection_channel-${config.uuid}`,
                              placeholder: "",
                              inputType: "input",
                              error:
                                errors[`connection_channel-${config.uuid}`] &&
                                touched[`connection_channel-${config.uuid}`]
                                  ? errors[`connection_channel-${config.uuid}`]
                                  : "",
                            },
                            {
                              value: values[`serial_number-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                handleChange(val);
                              },
                              clickHandler: (e) => {},
                              label: !isCollapsed ? "Serial Number" : "S No",
                              name: `serial_number-${config.uuid}`,
                              placeholder: "",
                              inputType: "input",
                              error:
                                errors[`serial_number-${config.uuid}`] && touched[`serial_number-${config.uuid}`]
                                  ? errors[`serial_number-${config.uuid}`]
                                  : "",
                            },
                            {
                              value: values[`measurement_units_id-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                handleChange({ target: { name: `measurement_units_id-${config.uuid}`, value: val } });
                              },
                              clickHandler: (e) => {},
                              label: "Units",
                              name: `measurement_units_id-${config.uuid}`,
                              placeholder: "",
                              tdLogSelectStyles: "log__select-input",
                              tdLogSelectIconStyles: "log__select-input-icon",
                              inputType: "select",
                              // inputType: "input",
                              options: getMeasurementUnitOptions(),
                            },
                            {
                              value: values[`date_from-${config.uuid}`],
                              setValue: (val) => {
                                setFieldValue(`date_from-${config.uuid}`, val);
                                setFieldTouched(`date_from-${config.uuid}`);
                                setEditing(true);
                                // handleChange(val);
                              },
                              clickHandler: () => {},
                              label: "Date From",
                              name: `date_from-${config.uuid}`,
                              placeholder: "",
                              inputType: "date",
                              setFieldError: setFieldError,
                              setFieldTouched: setFieldTouched,
                              error:
                                errors[`date_from-${config.uuid}`] && touched[`date_from-${config.uuid}`]
                                  ? errors[`date_from-${config.uuid}`]
                                  : "",
                              dateRootStyles: "sc__date-root-style",
                              required: true,
                              dateInit: initialValues[`date_from-${config.uuid}`],
                            },
                            {
                              value: values[`date_to-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                // handleChange(val);
                                setFieldValue(`date_to-${config.uuid}`, val);
                              },
                              clickHandler: () => {},
                              label: "Date To",
                              name: `date_to-${config.uuid}`,
                              placeholder: "",
                              inputType: "date",
                              setFieldError: setFieldError,
                              setFieldTouched: setFieldTouched,
                              error:
                                errors[`date_to-${config.uuid}`] && touched[`date_to-${config.uuid}`]
                                  ? errors[`date_to-${config.uuid}`]
                                  : "",
                              dateRootStyles: "sc__date-root-style",
                              required: false,
                              dateInit: initialValues[`date_to-${config.uuid}`],
                            },
                            {
                              value: values[`notes-${config.uuid}`],
                              setValue: (val) => {
                                setEditing(true);
                                handleChange(val);
                              },
                              clickHandler: (e) => {},
                              label: "Additional Notes",
                              name: `notes-${config.uuid}`,
                              placeholder: "",
                              inputStyles: `textbox-full`,
                              inputType: "textarea",
                              error:
                                errors[`notes-${config.uuid}`] && touched[`notes-${config.uuid}`]
                                  ? errors[`notes-${config.uuid}`]
                                  : "",
                            },
                          ]}
                          renderRight={
                            <>
                              {(editing || newItem) && (
                                <div className="sc__footer-container">
                                  <footer className="tm__submit">
                                    <button type="submit" className="ma__submit-btn" disabled={isSubmitting}>
                                      {isSubmitting ? <Spinner color="white" /> : "Save"}
                                    </button>
                                    <button
                                      type="button"
                                      className="ma__submit-btn ma__submit-cancel"
                                      onClick={() => {
                                        resetForm();
                                        setEditing(false);
                                        setNewItem(false);
                                        let newList = sensorConfigsList.filter((s) => s.uuid !== "newItem");
                                        setSensorConfigsList(newList);
                                        setHide(false);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </footer>
                                  {config.update_at && (
                                    <div className={"updated-at-text"}>
                                      Last Updated at : {format(new Date(config.update_at), "dd MMMM yyyy")}
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          }
                        />
                      </form>
                    </MeasurementAccordion>
                    </>
                  );
                }}
              </Formik>

              {!newItem && (
                <button type="button" className="sc__delete-btn" onClick={(e) => {
                  confirm({
                    color: 'error',
                    title: `Delete Configuration`,
                    acceptButtonText: 'Delete',
                    content:
                      `Are you sure you want to delete this Configuration?`,
                    onClose: async (isConfirmed) => {
                      if (isConfirmed) {
                        handleDeleteConfig(config.uuid)
                      }
                    },
                  });
                  }}>
                  <TrashRed /> <span> Delete Configuration</span>
                </button>
              )}
                
              </section>
            </div>
          );
        })}

      {!hide && sensorConfigsList.length === 0 && (
        <button onClick={handleNewItem} className="new-config-row">
          <PlusGreenIcon />
          {show && <span>New configuration</span>}
        </button>
      )}
    </article>
  );
};
