import { darkPalette, lightPalette } from "./colorPalette";

const themePalette = (mode) => {
  if (mode === "dark") {
    return darkPalette;
  }
  return lightPalette;
};

export default themePalette;
