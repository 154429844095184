import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "pages/App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "./theme/ThemeProvider";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { LoginProvider } from "hooks/apolloClient";
import { ErrorProvider } from "contexts/misc/context";
import { ModalProvider } from "hooks/useModal";
import SentryFallback from "../src/component_tree/shared/SentryFallback";

// Sentry.init({
//   dsn: "https://d576d033fd384792aaf8337d6daf3577@o1126669.ingest.sentry.io/6167812",
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.2,
// });

const domain = process.env.REACT_APP_AUTH_DOMAIN;
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const isDev = process.env.NODE_ENV === "development";

const application = (
  <ThemeProvider>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}/`}
      useRefreshTokens
      cacheLocation="localstorage"
      audience={`https://${domain}/api/v2/`}
      scope="read:current_user update:current_user_metadata"
    >
      <ErrorProvider>
        <ModalProvider>
          <BrowserRouter>
            <LoginProvider>
              <App />
            </LoginProvider>
          </BrowserRouter>
        </ModalProvider>
      </ErrorProvider>
    </Auth0Provider>
  </ThemeProvider>
);

ReactDOM.render(
  isDev ? (
    // <Sentry.ErrorBoundary fallback={SentryFallback} onError={(e) => console.log("We have an error", e)} showDialog>
      application
    // </Sentry.ErrorBoundary>
  ) : (
    application
  ),
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
