import FilterForm from "component_tree/shared/FilterForm";
import Stages from "component_tree/shared/Stages/Stages";
import { SubHeader } from "component_tree/shared/SubHeader";
import React from "react";

const HeaderTemplate = ({
  pageName,
  name,
  control,
  breadcrumbList,
  sensorTypes,
  measurementTypes,
  isFilterApplied,
  handleClearFilter,
  handleTableFilter,
  setIsFilterApplied,
  renderRight, // optional
  isFilterShown, // this is a boolean value to show/hide filter
  isBottomHeaderShown = true, // pass false to hide bottom header
  dataString = "",
}) => {
  return (
    <>
      <SubHeader dataString = {dataString} breadcrumbList={breadcrumbList} renderRight={renderRight} />
      {isBottomHeaderShown && (
        <div
          style={{
            margin: "0 35px",
            marginTop: "30px",
          }}
        >
          {/* <Stages /> */}
          <FilterForm
            pageName={pageName}
            name={name}
            control={control}
            sensorTypes={sensorTypes}
            measurementTypes={measurementTypes}
            isFilterApplied={isFilterApplied}
            setIsFilterApplied={setIsFilterApplied}
            handleTableFilter={handleTableFilter}
            handleClearFilter={handleClearFilter}
            isFilterShown={isFilterShown}
          />
        </div>
      )}
    </>
  );
};

export default HeaderTemplate;
