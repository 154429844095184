import {
  Typography,
  makeStyles,
  Select,
  MenuItem,
  Icon,
} from "@material-ui/core";
import { getIconForSensorTypeId } from "utils";
import { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const useStyles = makeStyles({
  listItems: {
    "& li": {
      color: "#1E2447",
      opacity: "0.5",
      fontSize: "14px",
      overflowX: "hidden",
      "&:hover": {
        // opacity: "1",
      },
    },
  },
  selectedStyles: {
    backgroundColor: "white",
    // backgroundImage: 'linear-gradient(white, #F1F2F4)',
    "& input": {
      color: "#1E2447 !important",
      opacity: 0.6,
      fontSize: 13,
    },
    // border: "none !important",
    borderRadius: "4px",
    border: '0.5px solid rgba(208, 213, 221, 1)',
    "& label": {
      color: "rgb(131, 132, 133) !important",
      backgroundColor: '#F1F2F4 !important',
      backgroundImage: 'linear-gradient(#F1F2F4, white)',
      opacity: 1,
      fontSize: 13,
      padding: '0 5px 0 2px',
    },
    "& div": {
      "&::before": {
        borderBottom: "none !important",
      },
      "&::after": {
        borderBottom: "none !important",
      },
    },
  },
  mainSelect: {
    marginRight: "10px",
  },
  dropdowncross: {
    top: "16px",
  },
  dropdowncross2: {},
});

const CustomFilter = ({
  handleValueChange,
  handleFilterModelChange,
  label,
  text,
  options,
  labelFormatFunction,
  value,
  style,
  renderOptionFunction,
  width,
  getOptionLabel,
}) => {
  // const [text, setText] = useState("");
  const classes = useStyles();
  // console.log("text fil", text);
  return (
    <>
      {/* <Autocomplete
        onChange={(e, value) => {
          handleValueChange(value);
          handleFilterModelChange(value);
        }}
        value={value}
        label={label}
        openOnFocus
        style={style}
        autoComplete
        options={options.map((id) => id)}
        renderOption={(option) => renderOptionFunction(option)}
        getOptionLabel={(option) => getOptionLabel(option)}
        PaperComponent={({ children }) => <Paper className={classes.listItems}>{children}</Paper>}
        classes={{
          root: classes.mainSelect,
          endAdornment: text ? classes.dropdowncross : classes.dropdowncross2,
        }}
        renderInput={(props) => (
          <TextField
            {...props}
            variant={value !== null && value !== "" ? "filled" : "outlined"}
            label={label}
            size={"small"}
            style={{ width: width }}
            InputProps={{
              ...props.InputProps,
              startAdornment: (
                <InputAdornment style={{ marginRight: "-5px", marginTop: "-3px" }}>
                  {getIconForSensorTypeId(text)}
                </InputAdornment>
              ),
            }}
            className={`auto-complete-input-label-1 ${value !== null && value !== "" ? classes.selectedStyles : ""}`}
          />
        )}
      /> */}
      <Autocomplete
        onChange={(e, value) => {
          handleValueChange(value);
          handleFilterModelChange(value);
        }}
        value={value}
        openOnFocus
        autoComplete
        options={options.map((id) => id)}
        // renderOption={(option) => { renderOptionFunction(option) }}
        getOptionLabel={(option) => getOptionLabel(option)}
        id="combo-box-demo"
        style={style}
        classes={{
          root: classes.mainSelect
          // endAdornment: text ? classes.dropdowncross : classes.dropdowncross2,
        }}
        size={"small"}
        PaperComponent={({ children }) => <Paper className={classes.listItems}>{children}</Paper>}
        renderInput={(props) =>
          <TextField
            size={"small"}
            {...props}
            InputLabelProps={{ classes: { root: classes.label } }}
            label={label}
            style={{ color: 'text.primary', fontSize: 21, fontWeight: 'bold', width: width }}
            // InputProps={{
            //   ...props.InputProps,
            //   startAdornment: (
            //     <InputAdornment style={{ marginRight: "-5px", marginTop: "-3px" }}>
            //       {getIconForSensorTypeId(text)}
            //     </InputAdornment>
            //   ),
            // }}
            className={classes.selectedStyles} />}
            
        popupIcon={<ExpandMoreIcon sx={{ fontSize: "25px" }} />}
      />
    </>
  );
};

export default CustomFilter;