import { useEffect, useState } from "react";
import clsx from "clsx";
import { format, parseISO, parse, isValid } from "date-fns";
import { DateRange, Calendar } from "react-date-range";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import { makeStyles, TextField } from "@material-ui/core";
import { DarkBlueCalendarIcon } from "assets";

import { KeyboardDatePicker } from "@material-ui/pickers";

import { ReactComponent as CalendarIcon } from "assets/icons/calendarIcon.svg";
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles";

import moment from "moment";
import { IconButton, InputAdornment } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { getDate } from "utils/calendarUtils";
const themex = createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        color: 'black',
        fontSize: '16px'
      },
      colorPrimary: {
        Color: '#1E2447 !important'
      },
      body2: {
        fontSize: '15px'
      },
      subtitle1: {
        fontSize: '13px'
      },
      h4:
      {
        fontSize: '24.2857px'

      },
      h5: {
        fontSize: '17.1429px'
      },
      caption: {
        fontSize: '14px'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#1E2447'
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#1E2447'
      }
    },
    MuiButton: {
      root: {
        fontSize: '13px'
      }
    }
  }
});

const useStyles = makeStyles(() => {
  return {
    dateRangePicker: {
      background: "#fff",
      borderRadius: 2,
      position: "relative",
    },
    datePicker: {
      position: "relative",
      "& .Mui-error": {
        width: "100%",
        whiteSpace: "normal",
      },

      // width: 240,
    },
    rootError: {
      border: "1px solid #ff647c",
    },
    body1: {
      color: 'black !important',
      fontSize: '2rem'
    },
    dateRangePickerBoxContainer: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
      border: "0.8px solid #E6E6E9",
      borderRadius: "2px",
    },
    dateRangePickerInputField: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.1px",
      color: "rgba(30, 36, 71, 0.8)",
      // borderRight: "0.8px solid #E6E6E9",
      borderTopLeftRadius: "2px",
      borderBottomLeftRadius: "2px",
      width: "100%",
    },
    inputField: {
      "& input": {
        padding: "3px 0 3px",
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    dateRangePickerIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderTopRightRadius: "2px",
      borderBottomRightRadius: "2px",
      padding: "6px 10px",
      cursor: "pointer",
    },
    dateRangePickerContainer: {
      position: "absolute",
      zIndex: "10001",
      boxShadow: "0 2px 10px rgba(0,0,0,.2)",
      background: "white",

      "& .rdrMonthAndYearWrapper": {
        height: "20px",
        paddingTop: 0 + " !important",
      },
      "& .rdrMonthAndYearPickers": {
        padding: "0px 8px",
        justifyContent: "flex-start",
      },
      "& .rdrCalendarWrapper": {},
      "& .rdrMonth": {
        width: "240px !important",
        padding: "8px",
      },
      "& .rdrNextPrevButton": {
        display: "none !important",
      },
    },
    rootDateInput: {
      padding: "4px 0",
      borderRadius: "2px",
      height: "100%",
      "& > input": {
        padding: "1.8px 8px",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "normal",
        height: "100%",
      },
    },
    calendarButton: {
      background: "transparent !important",
    },
  };
});



export function DateRangePicker(props) {
  const [showPicker, setShowPicker] = useState(false);
  const classes = useStyles();

  return (
    <div className={clsx(classes.dateRangePicker)}>
      <div className={classes.dateRangePickerBoxContainer}>
        <div className={classes.dateRangePickerInputField}>
          {getDate(props.date.startDate, "  ") + " - " + getDate(props.date.endDate, "  ")}
        </div>
        <div
          className={classes.dateRangePickerIcon}
          onClick={() => {
            if (showPicker) {
              props.onClose && props.onClose();
            }
            setShowPicker(!showPicker);
          }}
        >
          <DarkBlueCalendarIcon />
        </div>
      </div>
      {showPicker && (
        <div className={classes.dateRangePickerContainer}>
          <DateRange
            onChange={(item) => {
              props.onClose && props.onClose();
              props.onChange({
                startDate: item.selection.startDate,
                endDate: item.selection.endDate,
              });
            }}
            moveRangeOnFirstSelection={true}
            ranges={[{ ...props.date, key: "selection", showDateDisplay: false }]}
          />
        </div>
      )}
    </div>
  );
}

export function DatePicker(props) {
  const {
    setFieldError,
    setFieldTouched,
    date,
    dateInit,
    error,
    rootStyle,
    pickerStyles,
    name,
    disabled,
    maxDate,
    minDate,
    required,
    handleGlobalError,
  } = props;

  const [new_date, setDate] = useState(date ? parseISO(date) : null);
  const [dateError, setDateError] = useState(error);
  const [localMaxDate, setLocalMaxDate] = useState(maxDate);
  const [localMinDate, setLocalMinDate] = useState(minDate);
  const [isErrorPresent, setIsErrorPresent] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const parsedDate = date ? parseISO(date) : null;
    setDate(parsedDate && isValid(parsedDate) ? parsedDate : null);

    const delayDebounceFn = setTimeout(() => {
      if (parsedDate && !isValid(parsedDate)) {
        setDateError("Invalid Date Format");
        setFieldError(name, "Invalid Date");
        setFieldTouched(name, true);
      } else {
        setDateError("");
        setFieldError(name, undefined);
      }
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, [date]);

  useEffect(() => {
    setLocalMaxDate(maxDate);
  }, [maxDate]);

  useEffect(() => {
    setLocalMinDate(minDate);
  }, [minDate]);

  const onValChange = (inputDate) => {
    if (!inputDate) {
      if (required) {
        setDateError("Required");
        handleGlobalError && handleGlobalError("Required");
        setIsErrorPresent(true);
      } else {
        setIsErrorPresent(false);
      }
      props.onChange(null);
      setFieldTouched(name, true);
      setDate(inputDate);
      return;
    }

    let dateValue = inputDate.toDate();
    if (!inputDate.isValid()) {
      setDateError("Invalid Date");
      handleGlobalError && handleGlobalError("Invalid Date");
      setIsErrorPresent(true);
      props.onChange(null);
    } else if (dateValue > new Date(localMaxDate)) {
      setIsErrorPresent(true);
      setDateError(`Max Date allowed is ${format(new Date(localMaxDate), "yyyy-MM-dd")}`);
      handleGlobalError && handleGlobalError(`Max Date allowed is ${format(new Date(localMaxDate), "yyyy-MM-dd")}`);
    } else if (dateValue < new Date(localMinDate)) {
      setIsErrorPresent(true);
      setDateError(`Min Date allowed is ${format(new Date(localMinDate), "yyyy-MM-dd")}`);
      handleGlobalError && handleGlobalError(`Min Date allowed is ${format(new Date(localMinDate), "yyyy-MM-dd")}`);
    } else {
      if (dateValue && dateValue.getHours() === 0 && dateValue.getMinutes() === 0 && dateValue.getSeconds() === 0) {
        dateValue.setHours(0, 0, 0);
      }
      props.onChange(format(dateValue, "yyyy-MM-dd'T'HH:mm:ss"));
      setIsErrorPresent(false);
    }

    setFieldTouched(name, true);
    setDate(inputDate);
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        className={clsx(classes.datePicker, rootStyle, {
          [classes.rootError]: error,
        })}
        style={{ height: "100%", width: "100%" }}
      >
        <div
          className={clsx("", {
            [props.logDateInputStyles]: props.logDateInputStyles,
          })}
          style={{ border: "none !important", height: "100%" }}
        >
          <div
            className={clsx(classes.dateRangePickerInputField, classes.inputField)}
            style={{ borderRight: "0 ", height: "100%" }}
          >
            <ThemeProvider theme={props.theme}>
              <KeyboardDateTimePicker
                value={new_date}
                onChange={onValChange}
                format={"yyyy-MM-DD HH:mm"}
                disabled={disabled}
                placeholder={"YYYY-MM-DD HH:mm"}
                InputProps={{
                  fullWidth: true,
                  disableUnderline: true,
                  classes: {
                    root: classes.rootDateInput,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <DateRangeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                KeyboardButtonProps={{
                  className: classes.calendarButton,
                }}
                maxDate={localMaxDate}
                minDate={localMinDate}
                style={{ width: "100%", height: "24px", padding: 0 }}
                helperText={dateError}
                FormHelperTextProps={{ error: true, style: { display: "none" } }}
                keyboard
                clearable
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export function NewDatePicker(props) {
  const {
    setFieldError,
    setFieldTouched,
    date,
    error,
    rootStyle,
    pickerStyles,
    name,
    disabled,
    maxDate,
    minDate,
    required,
  } = props;

  const [newDate, setNewDate] = useState(date ? parseISO(date) : null);
  const [isErrorPresent, setIsErrorPresent] = useState(Boolean(error));
  const classes = useStyles();

  useEffect(() => {
    setNewDate(date ? parseISO(date) : null);
    setIsErrorPresent(Boolean(error));
  }, [date, error]);

  const handleDateChange = (newDate) => {
    if (isValid(newDate)) {
      if (newDate > parseISO(maxDate)) {
        setFieldError(name, `Max Date allowed is ${maxDate}`);
      } else if (newDate < parseISO(minDate)) {
        setFieldError(name, `Min Date allowed is ${minDate}`);
      } else {
        setFieldError(name, undefined);
      }
    } else {
      setFieldError(name, "Invalid Date");
    }
    setFieldTouched(name, true);
    setNewDate(newDate);
    const date = new Date(newDate);
    const pad = (num) => String(num).padStart(2, "0");
    props.onChange(newDate ? `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}` : null);
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        className={clsx(classes.datePicker, rootStyle, {
          [classes.rootError]: isErrorPresent,
        })}
        style={{ height: "100%", width: "100%" }}
      >
        <div
          className={clsx("", {
            [pickerStyles]: pickerStyles,
          })}
          style={{ border: "none !important", height: "100%" }}
        >
          <div
            className={clsx(classes.inputField)}
            style={{ borderRight: "0", height: "100%" }}
          >
            <ThemeProvider theme={props.theme}>
              <KeyboardDateTimePicker
                value={newDate}
                onChange={handleDateChange}
                format={"yyyy-MM-DD HH:mm"}
                disabled={disabled}
                placeholder={"YYYY-MM-DD HH:mm"}
                InputProps={{
                  fullWidth: true,
                  disableUnderline: true,
                  classes: {
                    root: classes.rootDateInput,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <DateRangeIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                KeyboardButtonProps={{
                  className: classes.calendarButton,
                }}
                maxDate={parseISO(maxDate)}
                minDate={parseISO(minDate)}
                style={{ width: "100%", height: "24px", padding: 0 }}
                helperText={error}
                FormHelperTextProps={{ error: true, style: { display: "none" } }}
                keyboard
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ControlledDatePicker(props) {
  const {
    date,
    error,
    disabled,
    datePickerClassName=""
  } = props;

  const [new_date, setDate] = useState(date ? date : null);
  const isErrorPresent = Boolean(error);
  const classes = useStyles();
  useEffect(() => {
    // Remove any timezone information from the date string prior to converting to dateobj
    //    This is necessary because all dates are kept in local time and the datetime shown 
    //    should be the same for all users regardless of timezone
    let dateObj = null;
    if (date) {
      // Ensure date is converted without regards to timezone
      const date_TZ = new Date(date);  // This is the date with timezone
      dateObj = new Date( date_TZ.valueOf() + date_TZ.getTimezoneOffset() * 60000 ); // This is the date without timezone
    } 

    let parsedDate = moment(dateObj);
    if(parsedDate){
      if(parsedDate._isValid){
        parsedDate._i = format(parsedDate._d, `yyyy-MM-dd`);  // This uses date-fns
        parsedDate._f = "YYYY-MM-DD"; // This needs to be consistent moment JS
      }
      setDate(parsedDate);

    }
  }, [date]);



  // useEffect(() => {
  //   if (date !== "Invalid Date") setDate(date);
  //   else setDate(null);
  // }, [date]);



  const onValChange = (inputDate, value = "") => {
    if (inputDate && inputDate._isValid) {
      const parsedDate = parse(value, `yyyy-MM-dd`, new Date());  // This uses date-fns
      // props.onChange(format(parsedDate, `yyyy-MM-ddTHH:mm:ss`));
      props.onChange(format(parsedDate, `yyyy-MM-dd`));   // This uses date fns
    } else {
      props.onChange(null);
      
    }
    setDate(inputDate);
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        className={clsx(classes.datePicker, props.rootStyle, {
          [classes.rootError]: isErrorPresent,
        })}
        style={{ height: "100%", width: "100%" }}
      >
        {/* <div className={classes.dateRangePickerInputField}> */}
        <div
          className={clsx("", {
            [props.logDateInputStyles]: props.logDateInputStyles,
          })}
          style={{ border: "none !important", height: "100%" }}
        >
          <div
            className={clsx(classes.dateRangePickerInputField, classes.inputField)}
            style={{ borderRight: "0 ", height: "100%" }}
          >
            <ThemeProvider theme={themex}>
              <KeyboardDatePicker
                keyboardIcon={<CalendarIcon />}
                value={new_date}
                onChange={(d, value) => onValChange(d, value)}
                format={"yyyy-MM-DD"}
                disabled={disabled}
                placeholder={"YYYY-MM-DD"}
                InputProps={{
                  fullWidth: true,
                  disableUnderline: true,
                  classes: {
                    root: classes.rootDateInput  + " "+datePickerClassName,

                  },
                }}
                KeyboardButtonProps={{
                  className: classes.calendarButton,
                }}
               
                style={{ width: "100%", height: "24px", padding: 0 }}
                helperText={error}
                FormHelperTextProps={{ error: true, style: { display: "none" } }}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
