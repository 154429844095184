import React, { useState } from "react";
import { Tabs, Tab, makeStyles, Button, Menu, MenuItem, ClickAwayListener } from "@material-ui/core";
import { PlusCTAGreen } from "assets";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { capUnderSentence } from "utils";
const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: "0",
    textTransform: "capitalize",
    marginLeft: "0px",
  },
  selectedTab: {
    color: theme.palette.secondary.main,
  },
  tabContainer: {
    // padding: theme.spacing(1),
    // paddingLeft: theme.spacing(3),
  },
}));

export default function MeasurementTabs({
  tabs = [],
  measurementTypes = [],
  activeTabIndex,
  onChangeActiveTabIndex,
  onAddMeasurement,
}) {
  //
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  return (
    <div
      style={{ display: "flex", flexWrap: "wrap", alignItems: "left", justifyContent: "start", paddingLeft: "25px" }}
    >
      {tabs.length === 0 ? null : (
        <Tabs
          value={activeTabIndex}
          onChange={(_, newTab) => onChangeActiveTabIndex(newTab)}
          className={classes.tab}
          variant={tabs.length > 1 ? "scrollable" : ""}
          scrollButtons={"on"}
        >
          {tabs.map(({ label, id }, index) => {
            return <Tab label={label} key={id} className={classes.tab} classes={{ selected: classes }} />;
          })}
        </Tabs>
      )}
      {measurementTypes.length !== 0 && (
        <div style={{ marginTop: 10 }}>
          <span
            style={{
              cursor: "pointer",
              color: "#999",
              display: "flex",
              alignItems: "center",
              fontSize: 14,
              paddingLeft: 8,
              borderLeft: "1px solid #eee",
            }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <PlusCTAGreen />
            <span style={{ marginLeft: 6 }}>Add</span>
            <ArrowDropDown color={"inherit"} />
          </span>
        </div>
      )}
      {/* <ClickAwayListener onClickAway={() => setAnchorEl(null)}> */}
      <Menu
        elevation={2}
        // zIndex={anchorEl === null ? -1 : 1300}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
      >
        {measurementTypes.map((mid, index) => {
          return (
            <MenuItem
              key={mid}
              onClick={() => {
                setAnchorEl(null);
                onAddMeasurement(mid);
              }}
            >
              {capUnderSentence(mid)}
            </MenuItem>
          );
        })}
      </Menu>
      {/* </ClickAwayListener> */}
    </div>
  );
}
