import React from 'react'
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        overflow: "auto",
        height: '100vh',
        background: '#00000094',
    },
    boxContainer: {
        display: "flex",
        flex: "1",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default function Spinner2() {
    const classes = useStyles();
    return (
        <article className={classes.container}>
            <div className={classes.boxContainer}>
                <CircularProgress style={{ color: "white" }} />
            </div>
        </article>
    )
}
