import { Tooltip } from "@material-ui/core";
import { Card as ReactCard } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { checkIsLocationDecommissioned } from "utils";
import { LocationElevDrawing } from "..";

import "./TowerCard.css";

export const TowerCard = ({ tower, maxHeight }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const isLocationDecomissioned = checkIsLocationDecommissioned(tower);
  // console.log('isLocationDecomissioned', isLocationDecomissioned);
  const name = tower.name + (isLocationDecomissioned ? " (D)" : "");
  //

  return (
    <ReactCard key={tower.uuid} className="tc__card">
      {maxHeight != "" && tower.name !== "" ? (
        <LocationElevDrawing
          measurementLocationData={tower}
          chartHeight={150}
          chartWidth={110}
          maxHeight={maxHeight + 5}
        />
      ) : (
        // <p className="tc__none">None</p>
        <></>
      )}

      <ReactCard.Body className={isLocationDecomissioned?"tc__name-decommissioned" : "tc__name"} style={{ borderBottomLeftRadius: "3px", borderBottomRightRadius: "3px" }}>
        <Tooltip title={name} arrow>
          <ReactCard.Title
            className={"tc__text"}
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/" + projectId + "/" + tower.uuid)}
          >
            {name}
          </ReactCard.Title>
        </Tooltip>
      </ReactCard.Body>
    </ReactCard>
  );
};
