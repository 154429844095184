import { useEffect, useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import {  handleErrorFromGQL } from "utils";
import { ErrorContext } from "contexts/misc/context";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { GET_PLANT_BY_ID } from "services";


const useStyles = makeStyles(() => ({
  boxContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AppFlow = () => {
  const classes = useStyles();
  const { projectId, towerId, app_uuid, run_uuid } = useParams();
  const { setErrors } = useContext(ErrorContext);

  const {loading} = useQuery(GET_PLANT_BY_ID, {
    variables: { plantId: projectId }, onError: (err) => {
      handleErrorFromGQL(err, setErrors);},
  });
  

  if (loading) {
    return (
      <article className={classes.boxContainer}>
        <CircularProgress />
      </article>
    );
  }

  return (
    <div style={{position:'relative',width:'100%' }}>
    <iframe
      src={`${process.env.REACT_APP_NODLES_URL}/app/${app_uuid}/${run_uuid}`}
      style={{ width:'100%', height:'100%'}}
      title='Nodle App'
    />
  </div>
  );
};

export default AppFlow;
