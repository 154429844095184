import moment from "moment";
import clsx from "clsx";

import { makeStyles, Menu, MenuItem } from "@material-ui/core";

import { PlusGreenIcon } from "assets";
import { DetailsBox } from "component_tree/shared/DetailsBox";
import { Spinner } from "component_tree/shared";
import { getIconForMeasurementPoint } from "utils";
import "./SensorBox.css";
import { useState } from "react";
import { ExpandLessSharp, ExpandMoreSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  inactiveRoot: {
    // opacity: "0.7",
  },
  header: {
    height: "4.5rem",
    display: "flex",
    alignItems: "center",
    background: "unset",
    border: "unset",
    padding: "0 1.5rem",
    borderBottom: "1px solid #eeeeee",
    width: "100%",
    cursor: "pointer",
    "& h2": {
      fontFamily: "Roboto",
      fontSize: "1.4rem",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "17px",
      letterSpacing: "0.0823529px",
      color: "rgba(0, 17, 34, 0.8)",
      marginLeft: "1rem",
    },
  },
  sensorList: {
    listStyleType: "none",
    padding: 0,
    "& li": {
      // borderBottom: 'solid 0.3px rgba(30,36,71,.2)',
      borderBottom: "1px solid #eeeeee",
      cursor: "pointer",
      height: "3.5rem",
      paddingLeft: "2.5rem",
      display: "flex",
      alignItems: "center",
      transition: "all .1s ease-in",
      // '&:hover': {
      //   height: '4.5rem',
      //   color: '#fff',
      //   transform: ' scale(1.03)',
      //   borderRadius: '4px',
      //   background: theme.palette.secondary.main,
      //   '& .icon-add,& h4, & p': {
      //     color: '#fff',
      //     opacity: '1',
      //   },
      // },

      "& h2": {
        fontFamily: "Roboto",
        fontSize: "1.3rem",
        lineHeight: "1",
        marginRight: "8px",
        fontWeight: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        fontStyle: "normal",
        color: "rgba(30, 36, 71, 0.8)",
      },
      "& p": {
        fontFamily: "Roboto",
        fontSize: "1.2rem",
        lineHeight: "1.08",
        fontWeight: "normal",
        fontStretch: "normal",
        letterSpacing: "normal",
        fontStyle: "normal",
        color: "rgba(30, 36, 71, 0.5)",
      },
    },
  },
  itemActive: {
    height: "4.5rem !important",
    color: "#fff !important",
    transform: " scale(1.03)",
    borderRadius: "4px",
    background: theme.palette.secondary.main,
    "& h2, & p": {
      color: "#fff !important",
      opacity: "1 !important",
    },
  },
  titleActive: {
    height: "4.5rem !important",
    color: "#fff !important",
    transform: " scale(1.03)",
    borderRadius: "4px",
    background: theme.palette.secondary.main,
    "& h2, & p": {
      color: "#fff !important",
      opacity: "1 !important",
    },
  },
}));

export const SensorBox = ({
  title,
  activeMeasurementPoint,
  setActiveMeasurementPoint,
  sensorList,
  activeSensor,
  setActiveSensor,
  className,
  formProps,
  sensorTypesList,
  setShowSensorForm,
  standardSensorModels = [],
  showSensorForm,
  measurementPoint,
  onSelectSensorTypeID,
  uid,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOEMDropdown = (uuid = "") => {
    setShowSensorForm(true);
    setAnchorEl(null);
    onSelectSensorTypeID(uuid);
  };

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    initialValues,
    ...props
  } = formProps;
  return (
    <div
      className={`${clsx(
        className,
        activeMeasurementPoint === uid || activeSensor?.measurement_point_uuid === measurementPoint.uuid
          ? ""
          : classes.inactiveRoot
      )} sb__root `}
    >
      <button
        className={`${clsx(classes.header, activeMeasurementPoint === uid && classes.titleActive)}`}
        onClick={() => {
          setActiveMeasurementPoint(activeMeasurementPoint === uid ? null : uid);
          setActiveSensor(null);
        }}
      >
        {getIconForMeasurementPoint(title)}
        <h2> {title}</h2>
      </button>
      <ul className={classes.sensorList}>
        {sensorList.map((i, index) => (
          <li
            key={`${i.name}${index}`}
            className={activeSensor?.uuid === i.uuid ? classes.itemActive : ""}
            onClick={() => {
              setActiveMeasurementPoint(null);
              setActiveSensor(activeSensor?.uuid === i.uuid ? null : i);
            }}
          >
            <h2>{i.name}</h2>
            <p>
              [{i.date_from ? moment(i.date_from).format("DD MMM YYYY") : ""}
              {i.date_to ? ` to ${moment(i.date_to).format("DD MM YYYY")}` : ""}]
            </p>
          </li>
        ))}
      </ul>

      <section className={`sb__new-form ${showSensorForm && "sb__expand"}`}>
        {!showSensorForm ? (
          <button
            className="sb__footer"
            style={{ width: "100%" }}
            type={"button"}
            onClick={(e) => {
              e.preventDefault();
              if (anchorEl) {
                setAnchorEl(null);
                return;
              }
              setAnchorEl(e.currentTarget);
            }}
          >
            <PlusGreenIcon />
            <span className="text"> Add Sensor </span>
            <span className="icon">{!Boolean(anchorEl) ? <ExpandMoreSharp /> : <ExpandLessSharp />}</span>
            <Menu
              elevation={2}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
              }}
              transformOrigin={{
                vertical: "top",
              }}
              style={{ marginTop: 40, marginLeft: 120 }}
            >
              {standardSensorModels.map(({ uuid, oem, model }) => {
                return (
                  <MenuItem key={uuid} onClick={() => handleOEMDropdown(uuid)}>
                    {oem + " - " + model}
                  </MenuItem>
                );
              })}
              <MenuItem key={"other_item"} onClick={() => handleOEMDropdown("other_item")}>
                Other
              </MenuItem>
            </Menu>
          </button>
        ) : (
          // <div className="floating-box">
          <DetailsBox
            key={"addMeasurement"}
            rootStyles={`sb__box ${showSensorForm && "sb__box-expand"}`}
            renderHeader={<h3 className="sb__form-title">Add Sensor</h3>}
            formList={[
              {
                value: values.serial_number,
                setValue: (val) => handleChange(val),
                clickHandler: (e) => handleBlur(e),
                label: "Serial Number",
                name: "serial_number",
                placeholder: "",
                inputStyles: "tm__input-box",
                inputType: "input",
                error: errors.serial_number && touched.serial_number,
              },
              {
                value: values.oem,
                setValue: (val) => handleChange(val),
                clickHandler: (e) => handleBlur(e),
                label: "Oem",
                name: "oem",
                placeholder: "",
                inputStyles: "tm__input-box",
                inputType: "input",
                error: errors.oem && touched.oem,
              },
              {
                value: values.date_from,
                setValue: (val) => setFieldValue("date_from", val),
                clickHandler: () => { },
                label: "Date From",
                name: "date_from",
                placeholder: "",
                inputStyles: "tm__input-box",
                inputType: "date",
                pickerStyles: "sb__date-picker",
                setFieldError: setFieldError,
                setFieldTouched: setFieldTouched,
                error: errors.date_from && touched.date_from,
                dateRootStyles: "log__date-input",
                logDateInputStyles: "log__date-input-styles",
                required:true,
                dateInit: initialValues.date_from
              },
              {
                value: values.date_to,
                setValue: (val) => setFieldValue("date_to", val),
                clickHandler: () => { },
                label: "Date To",
                name: "date_to",
                placeholder: "",
                inputStyles: "tm__input-box",
                inputType: "date",
                setFieldError: setFieldError,
                setFieldTouched: setFieldTouched,
                error: errors.date_to && touched.date_to,
                pickerStyles: "sb__date-picker sb__date-picker-up",
                dateRootStyles: "log__date-input",
                logDateInputStyles: "log__date-input-styles",
                required:false,
                dateInit: initialValues.date_to
              },
              {
                value: values.model,
                setValue: (val) => handleChange(val),
                clickHandler: (e) => handleBlur(e),
                label: "Sensor Model",
                name: "model",
                placeholder: "",
                inputStyles: "tm__input-box",
                inputType: "input",
              },
              {
                value: values.sensor_type_id,
                setValue: (val) => setFieldValue("sensor_type_id", val),
                clickHandler: () => { },
                label: "Sensor Type",
                name: "sensor_type_id",
                placeholder: "",
                inputStyles: "tm__input-box",
                inputType: "select",
                tdLogSelectStyles: "log__select-input",
                tdLogSelectIconStyles: "log__select-input-icon",
                options: sensorTypesList,
                selectListStyles: "sb__select",
                error: errors.sensor_type_id && touched.sensor_type_id,
              },
            ]}
            renderRight={
              <footer className="tm__submit">
                <button type="submit" className="tm__submit-btn" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner color="white" /> : "Create"}
                </button>
                <button
                  type="button"
                  className="tm__submit-btn tm__submit-cancel"
                  onClick={() => setShowSensorForm(false)}
                >
                  Cancel
                </button>
              </footer>
            }
          />
        )}
      </section>
    </div>
  );
};
