import { Typography } from "@material-ui/core";
import { PlusGreenIcon } from "assets";

import { toggleButtonStyles, saveButtonStyles, fieldButtonStyles, addButtonStyles } from "./ButtonStyles";

export const ToggleBtn = ({ isBtn1Active, handleChange, btn1Name, btn2Name }) => {
  const classes = toggleButtonStyles();

  return (
    <div className={classes.viewToggle}>
      <button className={isBtn1Active ? "active" : ""} onClick={handleChange}>
        {btn1Name}
      </button>
      <button className={isBtn1Active ? "" : "active"} onClick={handleChange}>
        {btn2Name}
      </button>
    </div>
  );
};

export const ButtonPrimary = ({ nameValue, onClick, renderLeft, renderRight, btnStyle, disabled=false, textColor }) => {
  const classes = saveButtonStyles();

  return (
    <button onClick={onClick} className={classes.btnSecondary} style={btnStyle} disabled={disabled}>
      {renderLeft}
      <Typography variant="button" style={{ color: textColor }}>{nameValue}</Typography>
      {renderRight}
    </button>
  );
};

export const ButtonField = ({ nameValue, type }) => {
  const classes = fieldButtonStyles();
  return (
    <button type={type} className={classes.btnPrimary}>
      {nameValue}
    </button>
  );
};

export const ButtonAdd = ({ onClick, btnTitle }) => {
  const classes = addButtonStyles();

  return (
    <div className={classes.btnWrapper}>
      <div className={classes.btnSubWrapper} onClick={onClick}>
        <button className={classes.btn}>
          <PlusGreenIcon />
        </button>
        <span className={classes.btnText}>{btnTitle}</span>
      </div>
    </div>
  );
};
