import React, { useState } from "react";
import { sortBy, orderBy } from "lodash";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsXrange from "highcharts/modules/xrange";
import "fonts/font.css";
import "./index.css";

import wind_speed_logo from "assets/icons/wind_speed.svg";
import MastIcon from "assets/icons/mast_icon.svg";
import { getSensorIcon, getSensorRank, makeMeasurementLabel } from "utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreTwoTone";
import ExpandLessIcon from "@material-ui/icons/ExpandLessTwoTone";
import mast_logo from "assets/AlertPage/svg/mast_icon.svg";
import { useFullTowerData } from "hooks/tower";
import { Box, CircularProgress } from "@material-ui/core";
import MaintenanceIcon from "assets/icons/MaintenanceMenu-Dark.svg";
// import { isNull } from "lodash";
// import settings_logo from "assets/settings-change.svg";

/* Icon in highcharts is tricky. Here is the approach used:
    1. Used icomoon to convert standard and specific sensor icons to font.
    2. Uploaded font to fonts directory
    3. Fonts loads with chart.css
    Here is a discussion and example in this forum (https://www.highcharts.com/forum/search.php?st=0&sk=t&sd=d&sr=posts&keywords=custom+icon&fid%5B%5D=9)
    */
highchartsXrange(Highcharts);

// Sequence of colors for sensor changes
let seqColors = ["#8e9cea", "#c2ccff", "#8e9cea", "#416AB6", "#618AD5", "#65AFAF", "#80D299"];
seqColors = ["#8E9CEA", "8e9cea", "#8e9cea", "#416AB6", "#618AD5", "#65AFAF", "#80D299"];

let chartSettings = {
  axisLabelColor: "darkgray",
  axisLabelFont: "roboto",
  marginLeft: 200,
  showChartBoundaries: false,
};

// The charting library does not accept react svg url, so this has to the format
let icon_url = {
  settings_change_icon_url: "icons/settings_change.svg",
  maintenance_icon_url: "icons/maintenance.svg",
};

//-----------------------------------------------

// Return list of Y Category labels given a list of measurement points
//  The list reflects the rank ordering
const getMeasurementLabelsAndIcons = (measLocation) => {

  const measPoints  =measLocation["measurement_points"];
  let labels = [
    {   // Add in a maintenance label
      label: "Maintenance",
      sensor_rank: getSensorRank("maintenance", ""),
      height_m: 0,
      orientation: 0,     // Adding orientation for consistency
      icon: getSensorIcon("maintenance", ""),
      type: "maintenance"
    }, {  // Add in a logger label
      label: "Logger",
      sensor_rank: getSensorRank("logger", ""),
      height_m: 0,
      orientation: 0,     // Adding orientation for consistency
      icon: getSensorIcon("logger", ""),
      type: "logger"
    }
  
  ];  // Push in any maintenance and information labels

  measPoints?.forEach((measPoint) => {
    // Ignore any unknowns
    if (measPoint.measurement_type_id?.toLowerCase().includes('unknown')){ return; }
    let sensors = measPoint.measurement_point_sensors.map((x) => x.sensor);
    sensors.forEach((sens, sensIdx) => {
      let measLabel = makeMeasurementLabel(sens, measPoint);
      if (!labels.find((x) => x.label == measLabel)) {
        labels.push({
          label: measLabel,
          sensor_rank: getSensorRank(sens["sensor_type_id"], measPoint["measurement_type_id"]),
          height_m: measPoint["height_m"],
          orientation: measPoint["orientation"],
          icon: getSensorIcon(sens["sensor_type_id"], measPoint["measurement_type_id"]),
          sensor_type: sens["sensor_type_id"],
          measurement_type: measPoint["measurement_type_id"],
        });
      }
    });
  });

  if (measLocation.measurement_station_type_id == 'lidar') {
      labels = orderBy(
              labels,
              ["sensor_rank", "height_m", "orientation", "sensor_type", "measurement_type"], 
              ["desc", "asc", "asc", "asc", "asc"]
              // ["height_m", "orientation", "sensor_rank", "sensor_type", "measurement_type"], 
              // ["desc", "desc", "desc", "asc", "asc"]
            );
  } else {
    // Sort by rank (lowest rank first) and then highest height
    labels = orderBy(
                    labels, 
                    ["sensor_rank", "height_m", "orientation", "sensor_type", "measurement_type"], 
                    ["desc", "asc", "asc", "asc", "asc"]
                  );
  }

  return labels;
};

export const SensorHistoryTimelineCompactWithMaintenance = ({
  measLocation: location, ...props
}) => {
  const {data: measLocation} = useFullTowerData(location.uuid);

  if (!measLocation) {

    return  (
    <Box style={{width: '100%', display: 'flex', justifyContent:'center'}}>
      <CircularProgress 
        size={40}
      />
    </Box>
    );
  }

  return <SensorHistoryTimelineCompact {...props} measLocation={measLocation} />;
}

export const SensorHistoryTimelineCompact = ({
  measLocation,
  towerName,
  showXAxis,
  clickHandler,
  startDate,
  endDate,
}) => {
  let mPoints = measLocation["measurement_points"];
  let maintenance_entries = measLocation['maintenance_entries'];
  const chartHeight = 45 + (showXAxis == true ? 25 : 0);

  // If there is maintenance data. Add it to the chart
  // let maintScatterSeries = getMaintenanceScatterSeries(maintenance_entries, 0);
  let maintScatter = [];
  // let maintScatterBackground = [];
  if (maintenance_entries) {
      const maintLabel = "Maintenance";
      const maintLabelIndex = 0;
      maintenance_entries.forEach((entry) => {      
          maintScatter.push({
            x: new Date(entry["maintenance_date"]) * 1.0,
            y: maintLabelIndex,
            info: {
              title: entry["title"].trim(),
              notes: entry["notes"].trim().length > 100 ? entry["notes"].trim().substring(0, 100) + "..." : entry["notes"].trim(),
            }
          });

          // maintScatterBackground.push({
          //   x: new Date(entry["maintenance_date"]) * 1.0,
          //   y: maintLabelIndex,
          //   fillColor: "lightgray"
          // })
    })

  }

  let xRangeData = [];
  mPoints.forEach((point) => {
    let sensors = point.measurement_point_sensors.map((x) => x.sensor);
    point.measurement_point_sensors.forEach((mpoint_sensor, sensIdx) => {
      xRangeData.push({
        x: new Date(mpoint_sensor["date_from"]) * 1.0,
        x2: mpoint_sensor["date_to"] ? new Date(mpoint_sensor["date_to"]) * 1.0 : new Date() * 1.0,
        y: 0,
        color: seqColors[0],
      });
    });
  });

  let YSensorBands = [];
  YSensorBands.push({
    from: -0.35,
    to: 0.35,
    color: "#f7f7f8",
  });

  let chartOptions = {
    chart: {
      type: "xrange",
      backgroundColor: "white",
      height: chartHeight,
      marginLeft: chartSettings.marginLeft,
      marginBottom: 5,
      marginTop: showXAxis ? 35 : 5,
      borderWidth: 0,
      plotBorderWidth: 0,
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: showXAxis,
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%b '%y",
      },
      min: startDate ? startDate * 1.0 : null,
      max: endDate ? endDate * 1.0 : null,
      lineWidth: showXAxis ? 1 : 0,
      gridLineWidth: 0.51,
      gridLineDashStyle: "LongDash",
      gridLineColor: "lightgray",
      showLastLabel: true,
      labels: {
        align: "left",
        rotation: 0,
        style: {
          color: chartSettings["axisLabelColor"],
          fontFamily: chartSettings["axisLabelFont"],
        },
      },
      opposite: true,
    },
    yAxis: {
      type: "category",
      lineColor: "lightgray",
      lineWidth: 0.5,
      gridLineWidth: 0.0,
      gridLineDashStyle: "LongDash",
      gridLineColor: "lightgray",
      categories: [towerName],
      plotBands: YSensorBands,
      useHTML: true,
      title: "",
      labels: {
        reserveSpace: true,
        useHTML: true,
        align: "left",
        y: -4,
        style: {
          color: chartSettings["axisLabelColor"],
          fontfamily: chartSettings["axisLabelFont"],
        },
        formatter: function () {
          var value = this.value + "                      ";
          let cat_logo = MastIcon;

          return `<div style="text-align:left; width: 250px">
                        <img src=${cat_logo} style="color: red; width: 25px; height: 25px; padding-left: 0px;padding-right: 5px; padding-top: 0px;"/>
                        <span style="font-size: 12px; font-family:Roboto; position: relative; top: -8px; color: #1E2447;">
                          ${value}
                        </span>

                      </div>`;
        },
      },
    },
    series: [
      {
        name: "",
        borderRadius: 1.1,
        pointPadding: 0.285,
        groupPadding: 0,
        borderColor: "white",
        borderWidth: 1,
        pointWidth: null,
        data: xRangeData,
        turboThreshold: 5000,
        events: {
          click: function (evt) {
            clickHandler(true); //The compact timeline should be expanded
          },
        },
        tooltip: {
          xDateFormat: "%Y-%m-%d",
          dateTimeLabelFormats: { day: "%Y" },
          // headerFormat: "Test",
          pointFormat: "{point.description}",
          headerFormat:
            '<span style="font-size: 10px; font-family: Roboto;">Data from {point.x:%Y-%m-%d} to {point.x2:%Y-%m-%d} <a>( Click to expand )</a></span>{point.description} <br/>',
          // pointFormat:
          //   '<span style="color:{point.color}">●</span> <b>{point.yCategory}: <span style="color:{point.color}">{point.name}</span></b><br/>'
        },
      },
      {
        name: "",
        type: "scatter",
        data: maintScatter,
        opacity: 1,
        marker: {
          enabled: true,
          symbol: "circle",
          // color: "#f4f4f4",
          fillColor: "white", //"#f4f4f4",
          // lineColor: "lightgray",
          radius: 12,
          shadow: true,
          states: {
            hover: { enabled: false },
            select: { enabled: false },
          }
        },
        tooltip: {
          enabled: false,
        },
        enabled: {
          hover: {
            enabled: false,
            halo: {
              enabled: false,
            },
          },
        },
        enableMouseTracking: false,
      },
      {
        name: "",
        type: "scatter",
        data: maintScatter,
        snap: 500,
        color: "white",
        marker: {
          color: "#FF800B",
          symbol: `url(${MaintenanceIcon})`,
        },
        stickyTracking: false,  // 
        tooltip: {
          enabled: false,
          outside: true,
          xDateFormat: "%Y-%m-%d",
          hideDelay: 500,
          shared: true,
          useHTML: true,
          pointFormat: '<b><br/><br>( {point.x:%Y-%m-%d} )  -   {point.info.title}:</b>', //<br/><br/>{point.info.notes}',

          headerFormat: ''
          // pointFormat: '',
          // headerFormat: '<span style="font-size: 10px">{point.x:%Y-%m-%d}:{point.info.title} </span><br/>',
        }
      }
    ],

    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };
  return (
    <div
      className="chartContainer"
      style={{
        margin: "10px 0px",
        // boxShadow: "0px 0px 10px 7px rgba(199,199,199,0.59)",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "white",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );

  // return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export const SensorHistoryTimelineExpandedWithMaintenance = ({
  measLocation: location, ...props
}) => {
  const {data: measLocation} = useFullTowerData(location.uuid);

  if (!measLocation) {

    return  (
    <Box style={{width: '100%', display: 'flex', justifyContent:'center'}}>
      <CircularProgress 
        size={40}
      />
    </Box>
    );
  }

  return <SensorHistoryTimelineExpanded {...props} measLocation={measLocation} />;
}

export const SensorHistoryTimelineExpanded = ({ measLocation, measPoints, towerName, startDate, endDate }) => {
  let mPoints = measLocation["measurement_points"];
  let loggerConfigs = measLocation['logger_main_configs'];
  let maintenance_entries = measLocation['maintenance_entries'];

  // console.log("SensorHistoryTimelineExpanded", mPoints, loggerConfigs, maintenance_entries);


  // Track how each measurement point should be expressed in labels & icons
  let measLabelsAndIcons = getMeasurementLabelsAndIcons(measLocation);
  let YCategoriesLabels = measLabelsAndIcons.map((x) => {
    return x["label"];
  });

  let plotSeriesData = [];
  let sensorChangePoints = [];
  let YSensorBands = []; // Data to plot bands
  let settingsChangePoints = [];
  // Chartheight is Height of xaxis, Height per y entry + padding top-bottom
  let chartHeight = 25 + 30 * YCategoriesLabels.length + 135;
  let sensor_configs = [];

  // If there is maintenance data. Add it to the chart
  let maintScatter = [];
  let maintScatterBackground2 = [];
  if (maintenance_entries) {
      const maintLabel = "Maintenance";
      const maintLabelIndex = YCategoriesLabels.indexOf(maintLabel);
      maintenance_entries.forEach((entry) => {      
          maintScatter.push({
            x: new Date(entry["maintenance_date"]) * 1.0,
            y: maintLabelIndex,
            info: {
              title: entry["title"].trim(),
              notes: entry["notes"].trim().length > 100 ? entry["notes"].trim().substring(0, 100) + "..." : entry["notes"].trim(),
            }
          });

          // Maintain sensor Bands
          YSensorBands.push({
            from: maintLabelIndex - 0.35,
            to: maintLabelIndex + 0.35,
            color: "#f7f7f8",
          });

          // Background for maintenance scatter
          maintScatterBackground2.push({
            x: new Date(entry["maintenance_date"]) * 1.0,
            y: maintLabelIndex,
            fillColor: "white"
          })
    })
  }

  // If there is logger data. Add it to the chart
  if (loggerConfigs) {
    const loggerLabel = "Logger";
    const loggerLabelIndex = YCategoriesLabels.indexOf(loggerLabel);
    loggerConfigs.forEach((entry) => {      
        // Track sensor start/stop
        plotSeriesData.push({
          x: new Date(entry["date_from"]) * 1.0,
          x2: entry["date_to"] ? new Date(entry["date_to"]) * 1.0 : new Date() * 1.0,
          // x2: mpoint_sensor["date_to"] ? new Date(mpoint_sensor["date_to"]) * 1.0 : new Date() * 1.0,
          y: loggerLabelIndex,
          color: "#EBD498", //"#8447FF", //seqColors[2],
          description: entry["logger_model_name"] + " - " + entry["logger_serial_number"],
        });

        // Maintain sensor Bands
        YSensorBands.push({
          from: loggerLabelIndex - 0.35,
          to: loggerLabelIndex + 0.35,
          color: "#f7f7f8",
        });
  })
}


  mPoints?.forEach((point, pointIdx) => {
    let measurementLabel = "";
    let measurementLabelIndex = null;

    if (point.measurement_type_id?.toLowerCase().includes('unknown')){
      return;
    }
    // Track sensor start / stop
    let sensors = point.measurement_point_sensors.map((x) => x.sensor);
    // sensors.forEach((sens, sensIdx) => {
    point.measurement_point_sensors.forEach((mpoint_sensor, idx) => {
      let sens = mpoint_sensor.sensor;
      let sensIdx = idx;
      let sensorDescription = "Unknown Sensor";
      // if (sens["oem"]) sensorDescription = `${sens["oem"]}`;
      if (sens["model"]) sensorDescription = sens["model"]; //`${sensorDescription} : ${sens["model"]}`;
      if (sens["serial_number"]) sensorDescription = `${sensorDescription}, S/N: ${sens["serial_number"]}`;

      measurementLabel = makeMeasurementLabel(sens, point);
      measurementLabelIndex = YCategoriesLabels.indexOf(measurementLabel);
      // Track sensor start/stop
      plotSeriesData.push({
        x: new Date(mpoint_sensor["date_from"]) * 1.0,
        x2: mpoint_sensor["date_to"] ? new Date(mpoint_sensor["date_to"]) * 1.0 : new Date() * 1.0,
        y: measurementLabelIndex,
        color: seqColors[0], //"#f7f7f8",
        description: sensorDescription,
      });

      // Maintain sensor Bands
      YSensorBands.push({
        from: measurementLabelIndex - 0.35,
        to: measurementLabelIndex + 0.35,
        color: "#f7f7f8",
      });

    });
    //
    // Track changes to sensor configuration
    if ("sensor_configs" in point && point["sensor_configs"].length > 0) {
      point["sensor_configs"].forEach((config, configIdx) => {
        // sensor_configs.push({
        //     x: new Date( config['date_from'] )*1.0,
        //     x2: sens["date_to"] ? new Date(config["date_to"]) * 1.0 : new Date() * 1.0,
        //     y: measurementLabelIndex,
        //     color: seqColors[0], //"#f7f7f8", //seqColors[0], //[sensIdx],
        //     description: sensorDescription,
        // })
        if (configIdx > 0) {
          settingsChangePoints.push({
            x: new Date(config["date_from"]),
            y: measurementLabelIndex,
            marker: {
              borderColor: "white",
              color: "white",
              fillcolor: "red",
              width: 20,
              height: 20,
              radius: 3.5,
              shadow: true,
              // symbol: "diamond"
              // symbol: wind_speed_logo
              symbol: `url(${MaintenanceIcon})`,
            },
          });
        }
      });
    }

    if (
      "mounting_arrangement" in point &&
      !(point["mounting_arrangement"] == null) &&
      point["mounting_arrangement"].length > 0
    ) {
      point["mounting_arrangement"].forEach((config, configIdx) => {
        if (configIdx > 0) {
          settingsChangePoints.push({
            x: new Date(config["date_from"]),
            y: measurementLabelIndex,
            marker: {
              borderColor: "white",
              color: "white",
              fillcolor: "red",
              width: 20,
              height: 20,
              radius: 3.5,
              shadow: true,
              // symbol: "diamond"
              // symbol: wind_speed_logo
              // symbol: `url(${icon_url["maintenance_icon_url"]})`,
            },
          });
        }
      });
    }
  });

  // Get the min max dates using minMaxDates function and use it if start and end dates are not provided
  const dateRangeforLoc = minMaxDates([measLocation]);
  let startDateLoc = startDate ? startDate : dateRangeforLoc['startDate'];
  let endDateLoc = endDate ? endDate : dateRangeforLoc['endDate'];

  let chartOptions = {
    chart: {
      type: "xrange",
      backgroundColor: "white",
      marginLeft: chartSettings.marginLeft,
      height: chartHeight,
      borderWidth: 0,
      plotBorderWidth: 0,
      borderWidth: 0,
      marginBottom: 40,
      marginTop: 65,
    },
    title: {
      text: `<div style="text-align:center; ">
                        <span style="color: #1e2447;font-size: 14px; font-family:Roboto; font-weight:800 ">
                        <img src=${mast_logo} width: 20px; height: 15px; "/>
                          ${towerName}
                        </span>
                      </div>`,
      // text: `${towerName}`,
      useHTML: true,
      verticalAlign: "top",
      align: "left",
      style: {
        color: chartSettings["axisLabelColor"],
        fontfamily: chartSettings["axisLabelFont"],
      },
      x: 0,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          inside: true,
          style: {
            fontWeight: "normal",
          },
        },
      },
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        month: "%b '%y",
      },
      lineWidth: 1,
      gridLineWidth: 0.51,
      gridLineDashStyle: "LongDash",
      gridLineColor: "lightgray",
      min: startDateLoc ? startDateLoc * 1.0 : null,
      max: endDateLoc ? endDateLoc * 1.0 : null,
      labels: {
        align: "left",
        rotation: 0,
        style: {
          color: chartSettings["axisLabelColor"],
          fontFamily: chartSettings["axisLabelFont"],
        },
      },
      opposite: true,
    },
    yAxis: {
      title: "Test",
      type: "category",
      alternateGridColor: "white",
      lineColor: "lightgray",
      lineWidth: 0.5,
      gridLineWidth: 0.0,
      gridLineDashStyle: "LongDash",
      gridLineColor: "lightgray",
      categories: YCategoriesLabels,
      plotBands: YSensorBands,
      useHTML: true,
      labels: {
        reserveSpace: true,
        useHTML: true,
        // animate: true,
        align: "left",
        y: -4,
        style: {
          color: chartSettings["axisLabelColor"],
          fontfamily: chartSettings["axisLabelFont"],
        },
        formatter: function () {
          var value = this.value;
          let cat_logo = measLabelsAndIcons.filter((x) => x["label"] === value)[0]["icon"];
          let expand_icon;
          return `<div style="text-align:left; width: 250px">
                        <img src=${cat_logo} style="color: red; width: 25px; height: 25px; padding-left: 0px;padding-right: 5px; padding-top: 0px;"/>
                        <span style="font-size: 12px; font-family:Roboto; position: relative; top: -7px;  color: #1E2447;">
                          ${value}
                        </span>
                      </div>`;
        },
      },
    },
    series: [
      {
        name: "",
        borderRadius: 2.1, //4.1,
        pointPadding: 0.18, //.285,
        groupPadding: 0,
        borderColor: "white",
        borderWidth: 1,
        pointWidth: null,
        data: plotSeriesData,
        turboThreshold: 5000,
        dataLabels: [
          {
            enabled: true,
            align: "left",
            format: "({point.description})",
            fill: "#534c4c",
            backgroundColor: "",
            style: { color: "#534c4c", textOutline: 0, fontWeight: "normal", fontFamily: "roboto" },
          },
        ],
        tooltip: {
          enabled: true,
          xDateFormat: "%Y-%m-%d",
          // dateTimeLabelFormats: { day: "%Y" },
          useHTML: true,
          // snap: 1,
          // headerFormat: "Test",
          // pointFormat: "{point.description}",
          headerFormat:'<span style="font-size: 10px">{point.x:%Y-%m-%d} to {point.x2:%Y-%m-%d}</span>{point.description}<br/>',
          pointFormat: '<span style="color:{point.color}">●</span> <b>{point.yCategory} - <span style="color:{point.color}">{point.description}</span></b><br/>'

          // pointFormat:
          //   '<span style="color:{point.color}">●</span> <b>{point.yCategory}: <span style="color:{point.color}">{point.name}</span></b><br/>'
        },
      },
      {
        name: "",
        type: "scatter",
        data: maintScatterBackground2,
        opacity: 1,
        shadow: true,
        marker: {
          symbol: "circle",
          fillColor: "#E0E0E0",
          lineColor: "lightgray",
          radius: 14,
        },
        tooltip: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
      {
        name: "",
        type: "scatter",
        data: maintScatter,
        snap: 500,
        color: "white",
        marker: {
          color: "#FF800B",
          symbol: `url(${MaintenanceIcon})`,
        },
        stickyTracking: false,  // 
        tooltip: {
          enabled: false,
          outside: false,
          xDateFormat: "%Y-%m-%d",
          hideDelay: 500,
          shared: true,
          useHTML: true,
          pointFormat: '<b><br/><br>{point.info.title}:</b>', //<br/><br/>{point.info.notes}',
          headerFormat: '<span style="font-size: 10px">{point.x:%Y-%m-%d}</span><br/>',
          // headerFormat: '<table width="30%"><tr><th colspan="2">{point.key}</th></tr>',
          // pointFormat: '<tr><td style="color: {series.color}">{point.info.title} </td>' +
          //     '<td style="text-align: right"><b>{point.info.title}:</b> <br><br><br>{point.info.notes}</td></tr>' + 
          //     ''
          //     ,
          // footerFormat: '</table>',

          // formatter: function () {
          //   return '<b>' + this.info.title + ':</b><br/>' + this.info.notes;
          // }

          // pointFormat: " {point.info.title}",
          // split: true,
          // dateTimeLabelFormats: { day: "%Y" },
          // pointFormat: "<br>{point.info.notes}<br/>",
          // headerFormatter: '<span style="font-size: 10px; font-weight: bold;">{point.x:%Y-%m-%d} -  {point.info.title}</span>',
        }
      },
      // {
      //   name: "",
      //   type: "scatter",
      //   data: sensorChangePoints,
      //   color: "white",
      //   stickyTracking: false,
      //   tooltip: {
      //     enabled: false,
      //     // xDateFormat: "%Y-%m-%d",
      //     // dateTimeLabelFormats: { day: "%Y" },
      //     // headerFormat:
      //     //   '<span style="font-size: 10px">{point.x:%Y-%m-%d}</span><br/>'
      //   },
      // },
      // {
      //   name: "",
      //   type: "scatter",
      //   data: settingsChangePoints,
      //   color: "red",
      //   marker: {
      //     symbol: wind_speed_logo,
      //   },
      // },
    ],
    tooltip: {
      borderColor: "white",
      borderWidth: 0.5,
      // callout: 'rect',
      // outside: true,
      backgroundColor: "white",
    },
    // tooltip: {
    //   xDateFormat: "%Y-%m-%d",
    //   dateTimeLabelFormats: { day: "%Y" },
    //   headerFormat:
    //     '<span style="font-size: 10px">{point.x:%Y-%m-%d} - {point.x2:%Y-%m-%d}</span><br/>',
    //   pointFormat:
    //     '<span style="color:{point.color}">●</span> <b>{point.yCategory}: <span style="color:{point.color}">{point.name}</span></b><br/>'
    // },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <div
      className="chartContainer"
      style={{
        margin: "10px 0px",
        // boxShadow: "0px 0px 10px 7px rgba(199,199,199,0.59)",
        paddingLeft: "20px",
        paddingRight: "20px",
        backgroundColor: "white",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export const SensorHistoryTimelineRow = (props) => {
  const [expandChart, setExpandChart] = useState(props.expanded || false);

  const span_properties = {
    float: "left",
    position: "absolute",
    zIndex: 100,
    paddingLeft: "150px",
    paddingTop: props.showXAxis ? "34px" : "10px",
  };
  return (
    <div style={{ zIndex: "50", position: "relative" }}>
      <span style={span_properties}>
        {expandChart ? (
          <ExpandLessIcon
            className="toggle-btn"
            onClick={(e) => setExpandChart((expandChart) => !expandChart)}
          // ghost
          />
        ) : (
          <ExpandMoreIcon
            className="toggle-btn"
            onClick={(e) => setExpandChart((expandChart) => !expandChart)}
          // ghost
          />
        )}
      </span>

      {expandChart ? (
        <SensorHistoryTimelineExpandedWithMaintenance {...props} />
      ) : (
        <SensorHistoryTimelineCompactWithMaintenance {...props} clickHandler={setExpandChart} />
      )}
    </div>
  );
};

function adjustDates(startDate, endDate) {
  const millisecondsInMonth = 1000 * 60 * 60 * 24 * 30;
  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
  const monthsDifference = differenceInMilliseconds / millisecondsInMonth;

  if (monthsDifference < 12) {
    // Less than 1 year
    startDate.setMonth(startDate.getMonth() - 1);
    // startDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(1);
  } else {
    // More than 1 year
    const adjustStartMonths = Math.ceil(monthsDifference * 0.1);
    startDate.setMonth(startDate.getMonth() - adjustStartMonths);
    // startDate.setDate(1);
    const adjustEndMonths = Math.floor(monthsDifference * 0.025);
    endDate.setMonth(endDate.getMonth() + adjustEndMonths);
    endDate.setDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate());
  }
  
  return [startDate, endDate];
}

const minMaxDates = (locations) => {
  let startDate = null;
  let endDate = null;
  locations.forEach((loc) => {
    loc.measurement_points.forEach((point) => {
      let sensors = point.measurement_point_sensors; //.map((x) => x.sensor);
      sensors.forEach((sens, sensIdx) => {
        if (!startDate && sens["date_from"]) {
          startDate = new Date(sens["date_from"]);
        }
        if (startDate && sens["date_from"] && new Date(sens["date_from"]) < startDate) {
          startDate = new Date(sens["date_from"]);
        }

        if (!endDate && sens["date_to"]) {
          endDate = new Date(sens["date_to"]);
        }
        if (endDate && sens["date_to"] && new Date(sens["date_to"]) > endDate) {
          endDate = new Date(sens["date_to"]);
        }

        // startDate = ~isNull(startDate) ? Math.min(startDate, 1.0 * new Date(sens['date_from'])) : 1.0 * new Date(sens['date_from'])
        // endDate = Math.max(endDate, 1.0 * new Date(sens['date_to']), new Date() * 1.0);
      });
    });
  });

  // The min max should also consider the maintenance entries
  locations.forEach((loc) => {
    // If 'maintenance_entries is in loc , the process below
    if (!loc['maintenance_entries']) {
      return;
    }
    loc['maintenance_entries'].forEach((entry) => {
      if (!startDate && entry["maintenance_date"]) {
        startDate = new Date(entry["maintenance_date"]);
      }
      if (startDate && entry["maintenance_date"] && new Date(entry["maintenance_date"]) < startDate) {
        startDate = new Date(entry["maintenance_date"]);
      }

      if (!endDate && entry["maintenance_date"]) {
        endDate = new Date(entry["maintenance_date"]);
      }
      if (endDate && entry["maintenance_date"] && new Date(entry["maintenance_date"]) > endDate) {
        console.log("Setting End Date: ", endDate, " ---> Maintenance Date: ", new Date(entry["maintenance_date"]));
        endDate = new Date(entry["maintenance_date"]);
      }
    });
  });
  
  endDate = endDate ? new Date(endDate) : new Date();
  if (startDate & endDate) {
    // Adjust dates adds some margine around start / end time so icons are visible
    [startDate, endDate] = adjustDates(startDate, endDate);
  }
  return { startDate: startDate*1.0, endDate: endDate*1.0 };
};

export const SensorHistoryTimeline = (data) => {
  let locations = data.projectData.plant[0].measurement_locations;
  let dateRange = minMaxDates(locations);

  return (
    <div className="sensorHistoryTimelineBox">
      <h5 className="towerList">
        {" "}
        <b> Measurement Locations</b>{" "}
      </h5>

      {orderBy(locations, ['name'], ['asc']).map((loc, idx) => {
        return (
          <SensorHistoryTimelineRow
            measLocation={loc}
            // measPoints={loc.measurement_points}
            expand={false}
            towerName={loc.name}
            showXAxis={idx == 0 ? true : false}
            startDate={dateRange["startDate"]}
            endDate={dateRange["endDate"]}
            key={idx}
          />
        );
      })}
    </div>
  );
};
