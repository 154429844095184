import { gql } from "@apollo/client";

export const FETCH_PLANT_TYPES = gql`
  query GetPlantTypes {
    plant_type {
      id
    }
  }
`;

export const FETCH_PLANTS = gql`
  query GetPlants {
    plant {
      latitude_ddeg
      longitude_ddeg
      plant_type_id
      notes
      name
      update_at
      updated_by
      uuid
      plant_type_id
      measurement_locations {
        notes
        plant_uuid
        update_at
        updated_by
        latitude_ddeg
        longitude_ddeg
        uuid
        name
        is_new_created
        measurement_station_type_id
      }
    }
  }
`;

export const GET_PLANT_BY_ID = gql`
  query GetPlant($plantId: uuid!) {
    plant(where: { uuid: { _eq: $plantId } }) {
      latitude_ddeg
      longitude_ddeg
      plant_type_id
      notes
      name
      update_at
      updated_by
      uuid
      plant_type {
        id
      }
      measurement_locations {
        longitude_ddeg
        latitude_ddeg
        name
        measurement_station_type_id
        uuid
        is_active
        decommissioned_date
        logger_main_configs {
          uuid
          averaging_period_minutes
          clock_is_auto_synced
          data_transfer_details
          date_from
          date_to
          enclosure_lock_details
          encryption_pin_or_key
          measurement_location_uuid
          logger_id
          logger_model_name
          logger_name
          logger_oem_id
          logger_serial_number
          notes
          offset_from_utc_hrs
          sampling_rate_sec
          timestamp_is_end_of_period
          from_standard_model
        }
        measurement_points {
          height_m
          uuid
          name
          orientation
          height_reference_id
          notes
          measurement_type_id
          data_columns {
            uuid
            column_name
            statistic_type_id
          }
          sensor_configs {
            name
            measurement_units_id
            notes
            serial_number
            update_at
            date_from
            date_to
            connection_channel
            slope
            offset
            height_m
            uuid
          }
          mounting_arrangements {
            notes
            uuid
            mast_section_geometry_uuid
            boom_diameter_mm
            boom_length_mm
            boom_model
            boom_oem
            boom_orientation_deg
            date_to
            date_from
            distance_from_mast_to_sensor_mm
            mounting_type_id
            tilt_angle_deg
            updated_by
            update_at
            upstand_diameter_mm
            upstand_height_mm
            vane_dead_band_orientation_deg
            height_m
          }
          measurement_point_sensors {
            date_from
            date_to
            measurement_point_uuid
            sensor {
              date_to
              date_from
              instrument_poi_height_mm
              is_heated
              model
              notes
              oem
              sensor_type_id
              serial_number
              update_at
              updated_by
              uuid
              calibrations {
                name
                uuid
                slope
                offset
                sensitivity
                calibration_organisation
                uncertainty_k_factor
                place_of_calibration
                date_of_calibration
                notes
                report_file_name
                report_link
                update_at
              }
            }
          }
        }
        measurement_location_mast_properties {
          mast_properties_uuid
          mast_property {
            mast_height_m
            mast_oem
            mast_geometry_id
            mast_model
            mast_serial_number
            notes
            uuid
            lattice_number_of_diagonal_bracing_members
            lattice_leg_width_mm
            lattice_leg_is_round_cross_section
            lattice_has_horizontal_member
            lattice_face_width_at_top_mm
            lattice_face_width_at_bottom_mm
            lattice_bracing_member_height_mm
            lattice_bracing_member_diameter_mm
            pole_diameter_mm
          }
        }
      }
    }
  }
`;

export const FETCH_LOCATION_TYPES = gql`
  query GetLocationTypes {
    measurement_station_type {
      id
    }
  }
`;

export const GET_TOWER_BY_ID = gql`
  query GetTower($towerId: uuid!) {
    measurement_location(where: { uuid: { _eq: $towerId } }) {
      longitude_ddeg
      latitude_ddeg
      uuid
      name
      notes
      measurement_station_type_id
      is_new_created
      decommissioned_date
      is_active
      measurement_points {
        height_m
        uuid
        name
        measurement_type_id
        orientation
        notes
        data_columns {
          uuid
          column_name
          statistic_type_id
          __typename
        }
        sensor_configs {
          name
          measurement_units_id
          notes
          serial_number
          update_at
          date_from
          date_to
          connection_channel
          slope
          offset
          height_m
          uuid
          __typename
        }
        measurement_point_sensors {
          date_from
          date_to
          measurement_point_uuid
          sensor {
            date_to
            date_from
            instrument_poi_height_mm
            is_heated
            model
            notes
            oem
            sensor_type_id
            serial_number
            update_at
            updated_by
            uuid
            calibrations {
              name
              uuid
              slope
              offset
              sensitivity
              calibration_organisation
              uncertainty_k_factor
              place_of_calibration
              date_of_calibration
              notes
              report_file_name
              report_link
              update_at
              is_consensus_calibration
              __typename
            }
            __typename
          }
          measurement_point {
            uuid
            measurement_type_id
            height_m
            orientation
            data_columns {
              column_name
              statistic_type_id
              uuid
              __typename
            }
            sensor_configs {
              connection_channel
              slope
              uuid
              offset
              date_from
              date_to
              height_m
              measurement_units_id
              serial_number
              __typename
            }
            mounting_arrangements {
              notes
              uuid
              mast_section_geometry_uuid
              boom_diameter_mm
              boom_length_mm
              boom_model
              boom_oem
              boom_orientation_deg
              date_to
              date_from
              distance_from_mast_to_sensor_mm
              mounting_type_id
              tilt_angle_deg
              updated_by
              update_at
              upstand_diameter_mm
              upstand_height_mm
              vane_dead_band_orientation_deg
              height_m
              __typename
            }
            __typename
          }
          __typename
        }
        mounting_arrangements {
          notes
          uuid
          mast_section_geometry_uuid
          boom_diameter_mm
          boom_length_mm
          boom_model
          boom_oem
          boom_orientation_deg
          date_to
          date_from
          distance_from_mast_to_sensor_mm
          mounting_type_id
          tilt_angle_deg
          updated_by
          update_at
          upstand_diameter_mm
          upstand_height_mm
          vane_dead_band_orientation_deg
          height_m
          __typename
        }
        __typename
      }
      measurement_location_mast_properties {
        mast_properties_uuid
        mast_property {
          mast_height_m
          mast_oem
          mast_geometry_id
          mast_model
          mast_serial_number
          notes
          uuid
          lattice_number_of_diagonal_bracing_members
          lattice_leg_width_mm
          lattice_leg_is_round_cross_section
          lattice_has_horizontal_member
          lattice_face_width_at_top_mm
          lattice_face_width_at_bottom_mm
          lattice_bracing_member_height_mm
          lattice_bracing_member_diameter_mm
          pole_diameter_mm
          __typename
        }
        __typename
      }
      logger_main_configs {
        uuid
        averaging_period_minutes
        clock_is_auto_synced
        data_transfer_details
        date_from
        date_to
        enclosure_lock_details
        encryption_pin_or_key
        measurement_location_uuid
        logger_id
        logger_model_name
        logger_name
        logger_oem_id
        logger_serial_number
        notes
        offset_from_utc_hrs
        sampling_rate_sec
        timestamp_is_end_of_period
        from_standard_model
        __typename
      }
      plant {
        name
        __typename
      }
      __typename
    }
  }
`;

export const GET_LOGGER_OEM = gql`
  query GetLoggerOem {
    logger_oem {
      id
    }
  }
`;

export const GET_MAST_GEOMETRY = gql`
  query GetMastGeometry {
    mast_geometry {
      id
    }
  }
`;

export const FETCH_MEASUREMENT_TYPES = gql`
  query MyQuery {
    measurement_type(where: { is_standard: { _eq: true } }) {
      id
      is_standard
    }
  }
`;

export const FETCH_SENSOR_TYPES = gql`
  query GetSensorTypes {
    sensor_type {
      id
    }
  }
`;

export const FETCH_MOUNTING_TYPE = gql`
  query GetMountingType {
    mounting_type {
      id
    }
  }
`;

export const FETCH_DATA_COLUMNS = gql`
  query FetchDataColumns($m_id: uuid!) {
    column_name(where: { sensor_config: { measurement_point_uuid: { _eq: $m_id } } }) {
      column_name
      statistic_type_id
      uuid
    }
  }
`;

export const FETCH_SITE_DETAILS = gql`
  query FetchSiteDetails($towerId: uuid!) {
    measurement_location(where: { uuid: { _eq: $towerId } }) {
      name
      latitude_ddeg
      longitude_ddeg
      site_id
      latitude_ddeg
      longitude_ddeg
      address
      state_province
      county
      time_zone
      time_offset_from_utc_hours
      elevation
      nearest_town
      magnetic_declination
      uuid
    }
  }
`;

export const GET_TOWER_OEM_MODELS = gql`
  query GetOEMModel {
    mast_properties(where: { is_standard_model: { _eq: true } }) {
      uuid
      mast_oem
      mast_model
      mast_height_m
      mast_oem
      mast_geometry_id
      mast_model
      mast_serial_number
      notes
      lattice_number_of_diagonal_bracing_members
      lattice_leg_width_mm
      lattice_leg_is_round_cross_section
      lattice_has_horizontal_member
      lattice_face_width_at_top_mm
      lattice_face_width_at_bottom_mm
      lattice_bracing_member_height_mm
      lattice_bracing_member_diameter_mm
      pole_diameter_mm
    }
  }
`;

export const FETCH_OEM_MODEL = gql`
  query FetchOEMModel($id: uuid!) {
    mast_properties(where: { uuid: { _eq: $id } }) {
      mast_height_m
      mast_oem
      mast_geometry_id
      mast_model
      mast_serial_number
      notes
      lattice_number_of_diagonal_bracing_members
      lattice_leg_width_mm
      lattice_leg_is_round_cross_section
      lattice_has_horizontal_member
      lattice_face_width_at_top_mm
      lattice_face_width_at_bottom_mm
      lattice_bracing_member_height_mm
      lattice_bracing_member_diameter_mm
      pole_diameter_mm
    }
  }
`;

export const FETCH_LANDOWNER_CONTACTS = gql`
  query FetchLandownerContact($towerId: uuid!) {
    landowner_contacts(where: { measurement_location_id: { _eq: $towerId } }) {
      name
      address
      city
      state
      zip
      phone
      email
      fax
      id
    }
  }
`;

export const FETCH_LANDOWNER_INFO = gql`
  query FetchLandownerInfo($towerId: uuid!) {
    landowner_info(where: { measurement_location_uuid: { _eq: $towerId } }, limit: 1) {
      land_id
      measurement_location_uuid
      notes
    }
  }
`;

export const FETCH_LANDOWNER_CONTACT = gql`
  query FetchLandownerContact($Id: Int!) {
    landowner_contacts(where: { id: { _eq: $Id } }) {
      name
      address
      city
      state
      zip
      phone
      email
      fax
    }
  }
`;

export const FETCH_MAINTENANCE_ENTRIES = gql`
  query FetchMaintenanceEntries($id: uuid!) {
    maintenance_entries(where: { measurement_location_uuid: { _eq: $id } }, order_by: { createdAt: asc }) {
      id
      createdAt
      title
      maintenance_date
      notes
      docGrp
      measurement_location_uuid
      purpose
    }
  }
`;

export const FETCH_MAINTENANCE_ENTRY = gql`
  query FetchMaintenanceEntry($id: uuid!) {
    maintenance_entries(where: { id: { _eq: $id } }) {
      id
      maintenance_date
      createdAt
      title
      notes
      docGrp
      measurement_location_uuid
      report_form
      purpose
    }
  }
`;

export const GET_LOGGER_STANDARD_MODELS = gql`
  query FetchLoggerMainConfigEntries {
    logger_main_config(where: { is_standard_model: { _eq: true } }) {
      uuid
      averaging_period_minutes
      clock_is_auto_synced
      data_transfer_details
      date_from
      date_to
      enclosure_lock_details
      encryption_pin_or_key
      logger_id
      logger_model_name
      logger_name
      logger_oem_id
      logger_serial_number
      notes
      offset_from_utc_hrs
      sampling_rate_sec
      timestamp_is_end_of_period
    }
  }
`;

export const FETCH_MEASUREMENT_UNIT = gql`
  query FetchMeasurementUnits {
    measurement_units {
      id
    }
  }
`;

export const FETCH_STANDARD_SENSOR_MODEL = gql`
  query FetchStandardSensorModel($sensor_type_id: String) {
    sensor(where: { is_standard_model: { _eq: true } }) {
      uuid
      oem
      model
      serial_number
      sensor_type_id
      date_from
      date_to
    }
  }
`;

export const FETCH_SOLAR_STATS = gql`
  query FetchSolarStats {
    portfolio_summary(where: { plant_type_id: { _eq: "solar" } }) {
      projects
      locations
      years_of_data
      sensor_count
      years_of_data
    }
  }
`;

export const FETCH_WIND_STATS = gql`
  query FetchWindStats {
    portfolio_summary(where: { plant_type_id: { _eq: "onshore_wind" } }) {
      projects
      locations
      years_of_data
      sensor_count
      years_of_data
    }
  }
`;

export const FETCH_DATA_FILES = gql`
  query FetchDataFiles($id: uuid!) {
    data_files(where: { measurement_location_id: { _eq: $id } }) {
      id: uuid
      file_name
      file_type
      date_from
      date_to
      uploaded_on
      upload_status
      status_message
    }
  }
`;

export const FETCH_UPLOAD_SESSION = gql`
  query FetchUploadSession($id: uuid!) {
    upload_session(where: { uuid: { _eq: $id } }) {
      uuid
      upload_status
      total_files
      status_message
      files_completed
    }
  }
`;

export const FETCH_MAIN_DATA_COLUMNS = gql`
  query MyQuery($id: uuid!) {
    measurement_point(where: { measurement_location_uuid: { _eq: $id } }) {
      measurement_point_sensors {
        date_from
        date_to
        sensor {
          sensor_type_id
          oem
          model
          serial_number
          date_from
          date_to
          uuid
          calibrations {
            date_of_calibration
            name
            notes
            offset
            place_of_calibration
            report_file_name
            report_link
            sensitivity
            slope
            uncertainty_k_factor
          }
        }
        measurement_point {
          uuid
          measurement_type_id
          height_m
          orientation
          data_columns {
            column_name
            statistic_type_id
            uuid
          }
          sensor_configs {
            connection_channel
            slope
            offset
            date_from
            date_to
            height_m
            measurement_units_id
            serial_number
          }
        }
      }
    }
  }
`;

export const GET_INGESTION_LOGS = gql`
  query GetIngestionLogs {
    data_upload_summary(where: { start_date: { _is_null: false } }) {
      project_name
      project_id
      tower_name
      tower_id
      start_date
      end_date
      ingestion_date
      is_success
      data_type
      message
    }
  }
`;

export const GET_ISSUES_WITH_SUMMARY = gql`
  query GetIssuesWithSummary($start_date: timestamp!, $end_date: timestamp!) {
    issues(
      where: {
        _or: [{ start: { _gte: $start_date, _lte: $end_date } }, { end: { _gte: $start_date, _lte: $end_date } }]
      }
    ) {
      project_id
      tower_id
      flag_group_id
      project_name
      tower_name
      start
      end
      hours
      flags
      flag_types
      raw_channel_names
      channels
      measurement_types
      measurement_uuids
    }
    issues_project_summary(
      where: {
        _or: [
          { start_date: { _gte: $start_date, _lte: $end_date } }
          { end_date: { _gte: $start_date, _lte: $end_date } }
        ]
      }
    ) {
      project_id
      project_name
      normal
      warning
      failure
      issue_count
      sensor_count
      hours
      flag_type_count
      start_date
      end_date
      channels
      flags
      flag_types
      raw_channel_names
      latitude
      longitude
    }
    issues_tower_summary(
      where: {
        _or: [
          { start_date: { _gte: $start_date, _lte: $end_date } }
          { end_date: { _gte: $start_date, _lte: $end_date } }
        ]
      }
    ) {
      tower_id
      tower_name
      project_id
      project_name
      latitude
      longitude
      flags
      channels
      raw_channel_names
      flag_types
      flag_type_count
      sensor_count
      warning
      failure
      normal
      issue_count
      hours
      start_date
      end_date
    }
    issues_overall_summary(
      where: {
        _or: [
          { start_date: { _gte: $start_date, _lte: $end_date } }
          { end_date: { _gte: $start_date, _lte: $end_date } }
        ]
      }
    ) {
      normal
      warning
      failure
      issue_count
      sensor_count
      hours
      flag_type_count
      start_date
      end_date
      channels
      flags
      flag_types
      raw_channel_names
    }
  }
`;

export const GET_ISSUES_BY_TOWER_ID = gql`
  query GetIssuesWithSummary($start_date: timestamp!, $end_date: timestamp!, $tower_id: uuid!) {
    issues(
      where: {
        _or: [{ start: { _gte: $start_date, _lte: $end_date } }, { end: { _gte: $start_date, _lte: $end_date } }]
        tower_id: { _eq: $tower_id }
      }
    ) {
      project_id
      tower_id
      flag_group_id
      project_name
      tower_name
      start
      end
      hours
      flags
      flag_types
      raw_channel_names
      channels
    }
  }
`;

export const GET_PROFILER = gql`
  query getProfiler($id: uuid!) {
    vertical_profiler_properties(where: { measurement_location_uuid: { _eq: $id } }) {
      date_from
      date_to
      device_datum_plane_height_m
      device_orientation_deg
      height_reference_id
      measurement_location_uuid
      notes
      orientation_reference_id
      update_at
      updated_by
      uuid
    }
  }
`;

export const GET_HEIGHT_REFERENCE_IDS = gql`
  query getHeightReferenceIds {
    height_reference {
      id
    }
  }
`;
export const GET_ORIENTATION_REFERENCE_IDS = gql`
  query getOrientationReferenceIds {
    orientation_reference {
      id
    }
  }
`;
export const GET_EOM_IDS = gql`
  query getEomIds {
    logger_oem {
      id
    }
  }
`;

export const GET_LOGGER_MAIN_CONFIG = gql`
  query getLoggerMainConfig($measurementLocationUuid: uuid!) {
    logger_main_config(where: { measurement_location_uuid: { _eq: $measurementLocationUuid } }) {
      logger_id
      logger_model_name
      logger_oem_id
      logger_serial_number
      notes
      date_from
      date_to
      uuid
    }
  }
`;
export const GET_LIDAR = gql`
  query getLidar($loggerId: uuid!) {
    lidar_config(where: { logger_main_config_uuid: { _eq: $loggerId } }) {
      date_from
      date_to
      flow_corrections_applied
      logger_main_config_uuid
      notes
      uuid
      update_at
    }
  }
`;
export const GET_MEASURMENT_TYPES_USING_SENSOR_TYPE = gql`
  query GetMeasurementTypesUsingSensorType($sensor_type_id: String!) {
    measurement_types_per_sensor(where: { sensor_type_id: { _eq: $sensor_type_id } }) {
      measurement_type_id
    }
  }
`;

export const FETCH_STATISTIC_TYPE = gql`
  query FetchStatisticTypes {
    statistic_type {
      id
    }
  }
`;

export const FETCH_COLUMNS = gql`
  query FetchColumnsForAMeasurementLocation($measurement_location_uuid: uuid!) {
    data_columns(where: { measurement_point: { measurement_location_uuid: { _eq: $measurement_location_uuid } } }) {
      column_name
    }
  }
`;

export const FETCH_UNIQUE_SENSOR = gql`
  query getUniqueSensors($measurement_location_uuid: uuid) {
    measurement_point_sensor(
      where: { measurement_point: { measurement_location_uuid: { _eq: $measurement_location_uuid } } }
    ) {
      measurement_point {
        height_m
        orientation
      }
      sensor {
        sensor_type_id
      }
    }
  }
`;

export const GET_MATCHING_SENSOR = gql`
  query getMatchingSensor($sensor_type_id: String, $height_m: numeric, $orientation: Int) {
    measurement_point_sensor(
      where: {
        measurement_point: { height_m: { _eq: $height_m }, orientation: { _eq: $orientation } }
        sensor: { sensor_type_id: { _eq: $sensor_type_id } }
      }
    ) {
      sensor {
        uuid
        sensor_type_id
        serial_number
        oem
        model
      }
      measurement_point {
        measurement_type_id
        height_m
        orientation
      }
    }
  }
`;

export const FETCH_CATEGORIZED_UNCATEGORIZED_COLUMNS = gql`
  query getDataColumn($measurement_location_uuid: uuid) {
    data_columns(where: { measurement_point: { measurement_location_uuid: { _eq: $measurement_location_uuid } } }) {
      column_name
      statistic_type_id
      uuid
      measurement_point {
        uuid
        measurement_type_id
        height_m
        orientation
        measurement_location_uuid
        measurement_point_sensors {
          sensor {
            sensor_type_id
            uuid
          }
        }
      }
    }
  }
`;

export const FETCH_RELEVANT_MEASUREMENT_APP_BY_LOCATION = gql`
query GetPublishedAppLatestVersions {
  published_app_latest_versions {
    uuid
    name
    description
    flow_uuid
    flow
  }
}
`

export const FETCH_RELEVANT_MEASUREMENT_APP_RUN_BY_LOCATION = gql`
  query GetRelevantAppRunsWithMeasurement ($measurement_location_uuid_with_per: String!, $app_uuid: uuid!) {
    nodles_published_apps(where: {uuid: {_eq: $app_uuid}}) {
      nodles_app_runs(where: {inputs: {_cast: {String: {_ilike: $measurement_location_uuid_with_per}}}}) {
        name
        uuid
        description
        inputs
      }
    }
  }
`