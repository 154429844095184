import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import auth0 from "auth0-js";
import { makeStyles } from "@material-ui/core/styles";
import { Logo } from 'assets';

const useStyles = makeStyles((theme) => ({
topHeading:{
  fontSize:'23px !important',
  textAlign:'center',
},
tagline:{
  textAlign:'center',
  margin:24,
  fontSize:14,
  opacity:0.7,
  lineHeight:1.5,
},
brandBox: {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "14px",
  paddingBottom: "20px",
  position: "relative",
  "&::before": {
    background: "rgba(243, 243, 243, 0.8)",
    // width: "calc(100% + 60px)",
    height: "1px",
    content: "''",
    position: "absolute",
    bottom: "0",
    // left: "-30px",
  },
  "&>.title": {
    fontSize: "30px",
    fontWeight: "600",
    letterSpacing: "4px",
    color: "#111B30",
    // opacity: ".9",
  },
  "&>.subtitle": {
    color: "#1E2447",
    opacity: "0.7",
    textTransform: "uppercase",
    fontSize: "15px",
    fontWeight: "500",
    letterSpacing: ".4px",
  },
},
inputRoot: {
  fontSize: '16px !important',
},
labelRoot: {
  fontSize: '16px !important',
  "&$labelFocused": {
  }
},
labelFocused: {},
backtext:{
  textAlign: "center",
  cursor: "pointer",
  opacity:0.6
},
resetButton:{
  margin:'24px 0px !important', 
  fontSize:'13px !important', 
  height:52,
  width:320,
},
activeResetButton:{
  margin:'24px 0px !important', 
  fontSize:'13px !important', 
  height:52,
  width:320,
  backgroundColor:'#1e2447 !important',
  color:'#e3e3e3 !important',
  opacity:0.9,
  '&:hover': {
    opacity: 1,
    backgroundColor:'#1e2447 !important',
  },
}
}));
const ChangePasswordModal = ({ open, onClose, setCreateToast }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  var webAuth = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
  });

  function validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  const handleResetPassword = async () => {
    setError('');
    setCreateToast('')
    if(!validateEmail(email)){
      setCreateToast({type: 'error', message: 'Invalid email format'})
      return;
    } 
      
    setLoading(true);
    try {
        webAuth.changePassword({
            connection: 'Username-Password-Authentication',
            email: email
          }, function (err, resp) {
            if(err){
              console.log(err.message);
            }else{
              console.log(resp);
            }
          });
      setEmail('');
      setLoading(false);
      setCreateToast({ type: "success", message: "Password reset email sent successfully. Check your inbox and follow the instructions to reset your password. Please check your spam folder if you don't see the password reset link in your primary mailbox." })
      onClose();
    } catch (error) {
      setError('Failed to send reset password email. Please try again.');
      setCreateToast({type: "error", message:'Failed to send reset password email. Please try again.' })
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            width: 400,
            minHeight:540,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            fontSize:15,
            borderRadius:1.5
          }}
        >
          <Box>
          <div className={classes.brandBox}>
              <div className={"logo"}>
                <Logo />
              </div>
              <div className={"title"}>RAPT</div>
              <div className={"subtitle"}>Resource Assesment Platform</div>
            </div>
              <Typography className={classes.topHeading}>Forgot Your Password?</Typography>
              <Box className={classes.tagline}>
              Enter your email address and we will send you instructions to reset your password.
              </Box>
          </Box>
        <TextField
          label="Email"
          type="email"
          value={email}
          color='primary'
          size='large'
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mt: 2, height:52,width:320 }}
          InputProps={{ classes: { root: classes.inputRoot } }}
          InputLabelProps={{
          classes: {
            root: classes.labelRoot,
            focused: classes.labelFocused
          }
        }}
        />
        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={handleResetPassword}
          disabled={!email || loading}
          className={email.length > 0 ? classes.activeResetButton : classes.resetButton }
        >
          {loading ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            'Send Reset Link'
          )}
        </Button>
        {/* <Box className={classes.backtext} onClick={onClose}>Back to Auth0 Dashboard</Box> */}
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
