import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, FormHelperText } from "@material-ui/core";

import { Spinner, Select, RaptMap } from "component_tree/shared";
import "./ProjectDetailsPanel.css";
import { MarkIcon } from "assets";
import { checkValidLatLng } from "utils";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: "#0E1625",
    width: "140px",
    padding: "8px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "18px",
    color: "#FFFFFF",
  },
  arrow: {
    color: "#0E1625",
  },
}));

export const ProjectDetailsPanel = ({
  rootStyles,
  title,
  formButtons,
  onMapClick,
  // center,
  formItems,
  formProps,
  locationsDataList,
  mapZoom,
  fallback,
  autoOpen = true,
}) => {
  // Hooks
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(formItems[0].autoOpen);
  const listRef = useRef();
  const mapRef = useRef();
  const timeoutRef = useRef();
  const history = useHistory();
  const [center, setCenter] = useState(null);

  // Values
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = formProps;

  const labelToltip = (
    <Tooltip
      title="Type in or use the map to select a location"
      placement="right"
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <MarkIcon style={{ marginLeft: "6px" }} />
    </Tooltip>
  );

  useEffect(() => {
    if (values.latitude && values.longitude && checkValidLatLng(values.latitude, values.longitude)) {
      setCenter({ lat: values.latitude, lng: values.longitude });
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        if (mapRef.current) {
          mapRef.current.panTo({ lat: values.latitude, lng: values.longitude });
        }
      }, 100);
    } else {
      setCenter(undefined);
    }
  }, [values.latitude, values.longitude]);

  const handleChangeMiddle = (e) => {

    handleChange(e);
  };
  useEffect(() => { }, []);
  const renderInputTypes = (formItem) => {
    let inpMode;
    if (formItem.inputMode === undefined) inpMode = "text";
    else inpMode = formItem.inputMode;
    let inpPattern;
    if (formItem.inputMode === undefined) inpPattern = undefined;
    else inpPattern = formItem.inputPattern;

    switch (formItem.type) {
      case "input":
        return (
          <div className="ps__input-wrap" key={formItem.label}>
            <label htmlFor="projectName">
              {formItem.label} {formItem.labelToltip ? labelToltip : null}
            </label>
            <input
              type={formItem.inputType}
              placeholder={formItem.placeholder}
              id={formItem.name}
              name={formItem.name}
              onChange={(e) => handleChangeMiddle(e)}
              onBlur={handleBlur}
              value={values[formItem.name]}
              className={errors[formItem.name] && touched[formItem.name] ? "ps__input-error" : ""}
              inputMode={inpMode}
              pattern={inpPattern}
            />
            {errors[formItem.name] && touched[formItem.name] &&
              <FormHelperText error={errors[formItem.name]}>{errors[formItem.name]}</FormHelperText>
            }
          </div>
        );

      case "textarea":
        return (
          <div className="ps__input-wrap" key={formItem.label}>
            <label htmlFor="textarea">{formItem.label}</label>
            <textarea
              placeholder={formItem.placeholder}
              id={formItem.name}
              name={formItem.name}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[formItem.name]}
              className={errors[formItem.name] && touched[formItem.name] ? "ps__input-error" : ""}
            />
            {errors[formItem.name] && touched[formItem.name] &&
              <FormHelperText error={errors[formItem.name]}>{errors[formItem.name]}</FormHelperText>
            }
          </div>
        );

      case "select":
        return (
          <Field name={formItem.name} key={formItem.label}>
            {({ form: { touched: fieldTouched, errors: fieldErrors } }) => {
              return (
                <div className="ps__input-wrap">
                  <label htmlFor="projectName">{formItem.label}</label>
                  <Select
                    selectedValue={values[formItem.name]}
                    listRef={listRef}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    autoOpen={autoOpen}
                    // autoOpen={isOpen}
                    options={formItem.options}
                    onSelectClick={() => {
                      setIsOpen(!isOpen);
                      !isOpen && setTimeout(() => listRef.current.focus(), 50);
                    }}
                    onBlur={() => {
                      setIsOpen(false);
                      setFieldTouched(formItem.name);
                    }}
                    onItemClick={(val) => {
                      setIsOpen(false);
                      setFieldTouched(formItem.name);
                      setFieldValue(formItem.name, val);
                    }}
                    getLeftIcon={formItem.getLeftIcon}
                    error={fieldErrors[formItem.name] && fieldTouched[formItem.name]}
                    tdLogSelectStyles="ps__select-input"
                    tdLogSelectIconStyles="ps__select-input-icon"
                  />

                  {errors[formItem.name] && fieldTouched[formItem.name] &&
                    <FormHelperText error={errors[formItem.name]}>{errors[formItem.name]}</FormHelperText>
                  }
                </div>
              );
            }}
          </Field>
        );

      default:
        return null;
    }
  };

  return (
    <section className={`ps__plantDetailsPanel ${rootStyles}`}>
      <div className="mapClick-direction">
        <p>Click on the map to select a location</p>
      </div>
      <article className="ps__details-left">
        <form onSubmit={handleSubmit}>
          <header>
            <h1>{title}</h1>
          </header>

          <div className="ps__formInput">{formItems.map(renderInputTypes)}</div>

          <div className="ps_submit">
            {formButtons.map((btn, index) => (
              <button key={btn.title} type={btn.type} disabled={isSubmitting} onClick={btn.onClick}>
                {index === 0 && isSubmitting ? <Spinner color="white" /> : btn.title}
              </button>
            ))}
          </div>
        </form>
      </article>

      <article className="ps__map">
        <RaptMap
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          fallback={fallback}
          onMapLoad={(map) => {
            mapRef.current = map;
          }}
          // isLocationHintAvailable={false}
          style={{ flex: 1, height: "100%" }}
          locationsDataList={locationsDataList}
          onMapClick={onMapClick}
          center={center}
          mapZoom={mapZoom}
          history={history}
        />
      </article>
    </section>  
  );
};
