import React from "react";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createContext, useContext, useState } from "react";
import { isExpired } from "react-jwt";
import { ErrorContext } from "contexts/misc/context";
import { handleErrorFromGQL } from "utils";
import { useEffect } from "react";
import { ProgressContext, useInLineProgress } from "pages/ProgressContext/ProgressContext";
import { useMemo } from "react";
// Log any GraphQL errors or network error that occurred

const LoginContext = createContext({
  isLogin: false,
  token: null,
  setIsLogin: (isLogin = false) => null,
  setToken: (token = "") => null,
});
const INITIAL_HEADERS = {
  "content-type": "application/json",
};
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_DB_URL,
  headers: INITIAL_HEADERS,
});

const authMiddleware = (authToken, setProgress) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (authToken) {
      // setProgress((p) => p + 1);

      if (authToken) {
        operation.setContext({
          headers: {
            ...INITIAL_HEADERS,
            Authorization: `Bearer ${authToken}`,
          },
        });
      }
      return forward(operation).map((data, index) => {
        // setProgress((p) => p - 1);
        return data;
      });
    } else {
      return;
    }
  });
const cache = new InMemoryCache({});
const user = JSON.parse(localStorage.getItem("user")) || {};
const isTokenExpired = isExpired(user.token || "");

export const LoginProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(!isTokenExpired);
  const [token, setToken] = useState(!isTokenExpired ? user.token : null);
  return <LoginContext.Provider value={{ isLogin, token, setIsLogin, setToken }}>{children}</LoginContext.Provider>;
};

export const useLogin = () => {
  return useContext(LoginContext);
};

export const useAppApolloClient = () => {
  const { token, setIsLogin, setToken } = useLogin();

  const { setErrors, setDuration } = useContext(ErrorContext);
  // const { setProgress } = useInLineProgress();

  const errorLink = useMemo(() => {
    return onError(({ graphQLErrors, networkError, operation }) => {
      let isJWTError = false;
      // console.log("operation", operation.operationName, graphQLErrors);
      let isSessionExpired = false;
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message.includes("JWT")) {
            setErrors("Your session has expired. Please login again.");
            setDuration(2000);
            setIsLogin(false);
            isJWTError = true;
            setToken("");
            localStorage.setItem("user", JSON.stringify({}));
            isSessionExpired = true;
            return;
          } else {
          }
        });
      if (isSessionExpired) {
      } else {
        if (operation.operationName === "GetPlant" || operation.operationName === "GetTower") {
          // return window.location.replace("/not-found");
          console.log('Getplant error')
        }
      }

      if (networkError || graphQLErrors) {
        if (!isJWTError) {
          handleErrorFromGQL({ graphQLErrors, networkError }, setErrors);
          setDuration(2000);
        }
      }

      return;
    });
  }, [setErrors, setDuration, setIsLogin, setToken]);

  return useMemo(() => {
    return new ApolloClient({
      link: authMiddleware(token).concat(errorLink).concat(httpLink),
      cache,
    });
  }, [token, errorLink]);
};
