import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  statBox: {
    background: "#fefefe",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "150px",
    boxShadow: "0px 0px 20px 8px rgba(0, 0, 0, 0.03)",
    borderRadius: "6px",
    "& > header": {
      display: "flex",
      flex: 2,
      borderBottom: "1px solid #eeeeee",
      padding: "8px 8px 8px 12px",
      justifyContent: "flex-start",
      alignItems: "center",
      "& > p": {
        marginLeft: "5px",
        color: "#1e2447",
        fontSize: "14px",
      },
    },
    "& article": {
      display: "flex",
      flex: 8,
      borderColor: "#eeeeee",
      borderBottom: "1px",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    "@media(max-width: 1399px)": {
      minWidth: "200px",
      height: "150px",
      margin: "12px",
    },
  },
  sbTitle: {
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "29px",
    color: "#1e2447",
  },
  sbSubTitle: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#8e92a3",
  },
}));

export const StatBox = ({ title, icon, subtitle, value }) => {
  const classes = useStyles();
  return (
    <section className={classes.statBox}>
      <header>
        {icon}
        <p>{title}</p>
      </header>
      <article>
        <p className={classes.sbTitle}>{value}</p>
        <p className={classes.sbSubTitle}>{subtitle}</p>
      </article>
    </section>
  );
};
