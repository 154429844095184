import { gql } from "@apollo/client";

export const UPDATE_PLANT = gql`
  mutation UpdatePlant(
    $plantId: uuid!
    $plantName: String!
    $longitude: numeric!
    $latitude: numeric!
    $notes: String!
    $plantTypeId: String!
  ) {
    update_plant(
      where: { uuid: { _eq: $plantId } }
      _set: {
        name: $plantName
        notes: $notes
        longitude_ddeg: $longitude
        latitude_ddeg: $latitude
        plant_type_id: $plantTypeId
      }
    ) {
      returning {
        name
        notes
        longitude_ddeg
        latitude_ddeg
        uuid
      }
    }
  }
`;

export const DELETE_PLANT = gql`
  mutation DeletePlant($plantId: uuid!) {
    delete_plant(where: { uuid: { _eq: $plantId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_PLANT = gql`
  mutation CreatePlant(
    $plantName: String!
    $longitude: numeric!
    $latitude: numeric!
    $notes: String!
    $plantTypeId: String!
  ) {
    insert_plant(
      objects: {
        name: $plantName
        notes: $notes
        longitude_ddeg: $longitude
        latitude_ddeg: $latitude
        plant_type_id: $plantTypeId
      }
    ) {
      returning {
        name
        notes
        longitude_ddeg
        latitude_ddeg
        uuid
      }
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation(
    $locationId: uuid!
    $locationName: String!
    $longitude: numeric!
    $latitude: numeric!
    $notes: String!
    $locationTypeId: String!
  ) {
    update_measurement_location(
      where: { uuid: { _eq: $locationId } }
      _set: {
        name: $locationName
        notes: $notes
        longitude_ddeg: $longitude
        latitude_ddeg: $latitude
        measurement_station_type_id: $locationTypeId
        is_new_created: false
      }
    ) {
      returning {
        name
        notes
        longitude_ddeg
        latitude_ddeg
        uuid
      }
    }
  }
`;

export const UPDATE_MAST_STATUS = gql`
  mutation UpdateLocation(
    $locationId: uuid!
    $isActive: Boolean!
    $decommissionedDate: timestamptz!
  ) {
    update_measurement_location(
      where: { uuid: { _eq: $locationId } }
      _set: {
        decommissioned_date: $decommissionedDate
        is_active: $isActive
      }
    ) {
      returning {
        name
        notes
        longitude_ddeg
        latitude_ddeg
        uuid
      }
    }
  }
`;

export const UPDATE_IS_NEW_CREATED_LOCATION = gql`
  mutation UpdateIsNewCreatedLocation($locationId: uuid!) {
    update_measurement_location(where: { uuid: { _eq: $locationId } }, _set: { is_new_created: false }) {
      returning {
        uuid
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($locationId: uuid!) {
    delete_measurement_location(where: { uuid: { _eq: $locationId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $locationName: String!
    $longitude: numeric!
    $latitude: numeric!
    $notes: String!
    $locationTypeId: String!
    $plantId: uuid!
  ) {
    insert_measurement_location(
      objects: {
        name: $locationName
        notes: $notes
        longitude_ddeg: $longitude
        latitude_ddeg: $latitude
        measurement_station_type_id: $locationTypeId
        plant_uuid: $plantId
        is_new_created: true
      }
    ) {
      returning {
        name
        notes
        longitude_ddeg
        latitude_ddeg
        uuid
      }
    }
  }
`;

export const UPDATE_MAST_PROPERTIES = gql`
  mutation UpdateMastProperties(
    $mastPropertyId: uuid!
    $mastGeometryId: String!
    $mastModel: String!
    $mastOem: String!
    $mastSerialNumber: String!
    $mastHeightM: numeric!
    $mastNotes: String!
    $poleDiameterValue: numeric!
    $latticeBracingDiameterValue: numeric!
    $latticeBracingHeightValue: numeric!
    $latticeFaceWidthBottomValue: numeric!
    $latticeFaceWidthTopValue: Int!
    $latticeHasHorizontalValue: Boolean!
    $latticeLegRoundCrossValue: Boolean!
    $latticeLegWidthValue: numeric!
    $latticeDiagonalMembersValue: numeric!
  ) {
    update_mast_properties(
      where: { uuid: { _eq: $mastPropertyId } }
      _set: {
        mast_height_m: $mastHeightM
        mast_oem: $mastOem
        mast_geometry_id: $mastGeometryId
        mast_model: $mastModel
        mast_serial_number: $mastSerialNumber
        notes: $mastNotes
        pole_diameter_mm: $poleDiameterValue
        lattice_bracing_member_diameter_mm: $latticeBracingDiameterValue
        lattice_bracing_member_height_mm: $latticeBracingHeightValue
        lattice_face_width_at_bottom_mm: $latticeFaceWidthBottomValue
        lattice_face_width_at_top_mm: $latticeFaceWidthTopValue
        lattice_has_horizontal_member: $latticeHasHorizontalValue
        lattice_leg_is_round_cross_section: $latticeLegRoundCrossValue
        lattice_leg_width_mm: $latticeLegWidthValue
        lattice_number_of_diagonal_bracing_members: $latticeDiagonalMembersValue
      }
    ) {
      returning {
        mast_oem
        uuid
        notes
      }
    }
  }
`;

export const CREATE_MAST_PROPERTIES = gql`
  mutation CreateMastProperty {
    insert_mast_properties(objects: {}) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_MAST_PROPERTIES_LOCATION = gql`
  mutation createMastPropertiesLocation($measurement_location_id: uuid!, $mast_properties_id: uuid!) {
    insert_measurement_location_mast_properties(
      objects: { measurement_location_uuid: $measurement_location_id, mast_properties_uuid: $mast_properties_id }
    ) {
      returning {
        measurement_location_uuid
      }
    }
  }
`;

export const UPDATE_MAST_SECTION_GEOMETRY = gql`
  mutation UpdateMastGeometry(
    $mastSectionGeometryId: uuid!
    $mastNotes: String!
    $poleDiameterValue: numeric!
    $latticeBracingDiameterValue: numeric!
    $latticeBracingHeightValue: numeric!
    $latticeFaceWidthBottomValue: numeric!
    $latticeFaceWidthTopValue: numeric!
    $latticeHasHorizontalValue: Boolean!
    $latticeLegRoundCrossValue: Boolean!
    $latticeLegWidthValue: numeric!
    $latticeDiagonalMembersValue: Int!
  ) {
    update_mast_section_geometry(
      where: { uuid: { _eq: $mastSectionGeometryId } }
      _set: {
        pole_diameter_mm: $poleDiameterValue
        lattice_bracing_member_diameter_mm: $latticeBracingDiameterValue
        lattice_bracing_member_height_mm: $latticeBracingHeightValue
        lattice_face_width_at_bottom_mm: $latticeFaceWidthBottomValue
        lattice_face_width_at_top_mm: $latticeFaceWidthTopValue
        lattice_has_horizontal_member: $latticeHasHorizontalValue
        lattice_leg_is_round_cross_section: $latticeLegRoundCrossValue
        lattice_leg_width_mm: $latticeLegWidthValue
        lattice_number_of_diagonal_bracing_members: $latticeDiagonalMembersValue
        notes: $mastNotes
      }
    ) {
      returning {
        uuid
        notes
      }
    }
  }
`;

export const CREATE_MAST_SECTION_GEOMETRY = gql`
  mutation CreateMastGeometry(
    $mastPropertyId: uuid!
    $mastNotes: String!
    $poleDiameterValue: numeric!
    $latticeBracingDiameterValue: numeric!
    $latticeBracingHeightValue: numeric!
    $latticeFaceWidthBottomValue: numeric!
    $latticeFaceWidthTopValue: numeric!
    $latticeHasHorizontalValue: Boolean!
    $latticeLegRoundCrossValue: Boolean!
    $latticeLegWidthValue: numeric!
    $latticeDiagonalMembersValue: Int!
  ) {
    insert_mast_section_geometry(
      objects: {
        mast_properties_uuid: $mastPropertyId
        pole_diameter_mm: $poleDiameterValue
        lattice_bracing_member_diameter_mm: $latticeBracingDiameterValue
        lattice_bracing_member_height_mm: $latticeBracingHeightValue
        lattice_face_width_at_bottom_mm: $latticeFaceWidthBottomValue
        lattice_face_width_at_top_mm: $latticeFaceWidthTopValue
        lattice_has_horizontal_member: $latticeHasHorizontalValue
        lattice_leg_is_round_cross_section: $latticeLegRoundCrossValue
        lattice_leg_width_mm: $latticeLegWidthValue
        lattice_number_of_diagonal_bracing_members: $latticeDiagonalMembersValue
        notes: $mastNotes
      }
    ) {
      returning {
        notes
        uuid
      }
    }
  }
`;

export const UPDATE_LOGGER_CONFIG = gql`
  mutation UpdateLoggerConfig(
    $loggerConfigId: uuid!
    $averaging_period_minutes: Int!
    $clock_is_auto_synced: Boolean!
    $data_transfer_details: String!
    $date_from: timestamp!
    $date_to: timestamp!
    $enclosure_lock_details: String!
    $encryption_pin_or_key: String!
    $logger_id: String!
    $logger_model_name: String!
    $logger_name: String!
    $logger_oem_id: String!
    $logger_serial_number: String!
    $notes: String!
    $offset_from_utc_hrs: numeric!
    $sampling_rate_sec: Int!
    $timestamp_is_end_of_period: Boolean!
  ) {
    update_logger_main_config(
      where: { uuid: { _eq: $loggerConfigId } }
      _set: {
        averaging_period_minutes: $averaging_period_minutes
        clock_is_auto_synced: $clock_is_auto_synced
        data_transfer_details: $data_transfer_details
        date_from: $date_from
        date_to: $date_to
        enclosure_lock_details: $enclosure_lock_details
        encryption_pin_or_key: $encryption_pin_or_key
        logger_id: $logger_id
        logger_model_name: $logger_model_name
        logger_name: $logger_name
        logger_oem_id: $logger_oem_id
        logger_serial_number: $logger_serial_number
        notes: $notes
        offset_from_utc_hrs: $offset_from_utc_hrs
        sampling_rate_sec: $sampling_rate_sec
        timestamp_is_end_of_period: $timestamp_is_end_of_period
      }
    ) {
      returning {
        uuid
        notes
      }
    }
  }
`;

export const DELETE_LOGGER = gql`
  mutation DeleteLogger($loggerId: uuid!) {
    delete_logger_main_config(where: { uuid: { _eq: $loggerId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_LOGGER_CONFIG = gql`
  mutation CreateLoggerConfig(
    $measurementLocationId: uuid!
    $averaging_period_minutes: Int!
    $clock_is_auto_synced: Boolean!
    $data_transfer_details: String!
    $date_from: timestamp!
    $date_to: timestamp!
    $enclosure_lock_details: String!
    $encryption_pin_or_key: String!
    $logger_id: String!
    $logger_model_name: String!
    $logger_name: String!
    $logger_oem_id: String!
    $logger_serial_number: String!
    $notes: String!
    $offset_from_utc_hrs: numeric!
    $sampling_rate_sec: Int!
    $timestamp_is_end_of_period: Boolean!
    $from_standard_model: uuid!
  ) {
    insert_logger_main_config(
      objects: {
        measurement_location_uuid: $measurementLocationId
        averaging_period_minutes: $averaging_period_minutes
        clock_is_auto_synced: $clock_is_auto_synced
        data_transfer_details: $data_transfer_details
        date_from: $date_from
        date_to: $date_to
        enclosure_lock_details: $enclosure_lock_details
        encryption_pin_or_key: $encryption_pin_or_key
        logger_id: $logger_id
        logger_model_name: $logger_model_name
        logger_name: $logger_name
        logger_oem_id: $logger_oem_id
        logger_serial_number: $logger_serial_number
        notes: $notes
        offset_from_utc_hrs: $offset_from_utc_hrs
        sampling_rate_sec: $sampling_rate_sec
        timestamp_is_end_of_period: $timestamp_is_end_of_period
        from_standard_model: $from_standard_model
      }
    ) {
      returning {
        notes
        uuid
      }
    }
  }
`;

export const UPDATE_MEASUREMENT_POINT = gql`
  mutation UpdateMeasurementPoint(
    $measurementPointId: uuid!
    $height: numeric!
    $measurementTypeId: String!
    $name: String!
    $orientation: Int
  ) {
    update_measurement_point(
      where: { uuid: { _eq: $measurementPointId } }
      _set: { height_m: $height, measurement_type_id: $measurementTypeId, name: $name, orientation: $orientation }
    ) {
      returning {
        name
        uuid
      }
    }
  }
`;

export const DELETE_MEASUREMENT_POINT = gql`
  mutation DeleteMeasurementPoint($measurementPointId: uuid!) {
    delete_measurement_point(where: { uuid: { _eq: $measurementPointId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_MEASUREMENT_POINT = gql`
  mutation CreateMeasurementPoint(
    $measurementLocationId: uuid!
    $height: numeric!
    $orientation: Int!
    $measurementTypeId: String!
    $name: String!
  ) {
    insert_measurement_point(
      objects: {
        measurement_location_uuid: $measurementLocationId
        height_m: $height
        orientation: $orientation
        measurement_type_id: $measurementTypeId
        name: $name
        on_conflict: {
          constraint: measurement_point_measurement_location_uuid_measurement_type_id
          update_columns: height_m
        }
      }
    ) {
      returning {
        name
        uuid
      }
    }
  }
`;

export const DELETE_MEASUREMENT_POINT_SENSOR = gql`
  mutation DeleteMeasurementPointSensor($measurementPointId: uuid!, $sensorId: uuid!) {
    delete_measurement_point_sensor(
      where: { sensor_uuid: { _eq: $sensorId }, measurement_point_uuid: { _eq: $measurementPointId } }
    ) {
      returning {
        sensor_uuid
      }
    }
  }
`;

export const CREATE_MEASUREMENT_POINT_SENSOR = gql`
  mutation CreateMeasurementPointSensor($measurementPointId: uuid!, $sensor_uuid: uuid!, $date_from: timestamp!) {
    insert_measurement_point_sensor(
      objects: { sensor_uuid: $sensor_uuid, measurement_point_uuid: $measurementPointId, date_from: $date_from }
    ) {
      returning {
        sensor_uuid
      }
    }
  }
`;

export const UPDATE_SENSOR = gql`
  mutation UpdateSensor(
    $sensorId: uuid!
    $sensor_type_id: String
    $serial_number: String
    $model: String
    $oem: String
    $date_from: timestamp
    $date_to: timestamp
  ) {
    update_sensor(
      where: { uuid: { _eq: $sensorId } }
      _set: {
        oem: $oem
        model: $model
        serial_number: $serial_number
        sensor_type_id: $sensor_type_id
        date_from: $date_from
        date_to: $date_to
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;


export const UPDATE_MEASUREMENT_POINT_SENSOR_DETAILS = gql`
  mutation UpdateMSensor(
    $measurementPointId: uuid!
    $sensorId: uuid!
    $date_from: timestamp!
    $date_to: timestamp!
  ) {
    update_measurement_point_sensor(
      where: { measurement_point_uuid: { _eq: $measurementPointId }, sensor_uuid: { _eq: $sensorId } }
      _set: { date_from: $date_from, date_to: $date_to }
    ) {
      returning {
        date_from
        date_to
        measurement_point_uuid
      }
    }
  }
`;

export const DELETE_MEASUREMENT_POINT_SENSOR_DETAILS = gql`
  mutation DeleteMeasurementPointSensor($measurementPointId: uuid!, $sensorId: uuid!) {
    delete_measurement_point_sensor(
      where: { measurement_point_uuid: { _eq: $measurementPointId }, sensor_uuid: { _eq: $sensorId } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_SENSOR = gql`
  mutation DeleteSensor($sensorId: uuid!) {
    delete_sensor(where: { uuid: { _eq: $sensorId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_SENSOR = gql`
  mutation CreateSensor(
    $serial_number: String
    $sensor_type_id: String
    $model: String
    $oem: String
    $date_from: timestamp!
    $date_to: timestamp!
  ) {
    insert_sensor(
      objects: {
        oem: $oem
        model: $model
        serial_number: $serial_number
        sensor_type_id: $sensor_type_id
        date_from: $date_from
        date_to: $date_to
      }
    ) {
      returning {
        notes
        uuid
        date_from
      }
    }
  }
`;

export const UPDATE_SENSOR_CONFIG = gql`
  mutation UpdateSensorConfig(
    $sensorConfigId: uuid!
    $date_from: timestamp!
    $date_to: timestamp
    $connection_channel: String
    $slope: numeric
    $offset: numeric
    $height_m: numeric
    $measurement_units_id: String
    $notes: String
    $serial_number: String
  ) {
    update_sensor_config(
      where: { uuid: { _eq: $sensorConfigId } }
      _set: {
        date_from: $date_from
        date_to: $date_to
        connection_channel: $connection_channel
        notes: $notes
        measurement_units_id: $measurement_units_id
        serial_number: $serial_number
        slope: $slope
        offset: $offset
        height_m: $height_m
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const DELETE_SENSOR_CONFIG = gql`
  mutation DeleteSensorConfig($sensorConfigId: uuid!) {
    delete_sensor_config(where: { uuid: { _eq: $sensorConfigId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_SENSOR_CONFIG = gql`
  mutation CreateSensorConfig(
    $measurementPointId: uuid!
    $name: String
    $date_from: timestamp!
    $date_to: timestamp
    $connection_channel: String
    $slope: numeric
    $offset: numeric
    $height_m: numeric
  ) {
    insert_sensor_config(
      objects: {
        measurement_point_uuid: $measurementPointId
        name: $name
        date_from: $date_from
        date_to: $date_to
        connection_channel: $connection_channel
        slope: $slope
        offset: $offset
        height_m: $height_m
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const UPDATE_MOUNTING_ARRANGEMENT = gql`
  mutation UpdateMountingArrangement(
    $mountingArrangementId: uuid!
    $mounting_type_id: String
    $boom_orientation_deg: numeric
    $date_from: timestamp!
    $date_to: timestamp
    $orientation_reference_id: String
    $vane_dead_band_orientation_deg: numeric
    $boom_oem: String
    $boom_model: String
    $boom_length_mm: numeric
    $boom_diameter_mm: numeric
    $distance_from_mast_to_sensor_mm: numeric
    $upstand_diameter_mm: numeric
    $upstand_height_mm: numeric
    $tilt_angle_deg: numeric
    $height_m: numeric
  ) {
    update_mounting_arrangement(
      where: { uuid: { _eq: $mountingArrangementId } }
      _set: {
        mounting_type_id: $mounting_type_id
        boom_orientation_deg: $boom_orientation_deg
        date_from: $date_from
        date_to: $date_to
        orientation_reference_id: $orientation_reference_id
        vane_dead_band_orientation_deg: $vane_dead_band_orientation_deg
        boom_oem: $boom_oem
        boom_model: $boom_model
        boom_length_mm: $boom_length_mm
        boom_diameter_mm: $boom_diameter_mm
        distance_from_mast_to_sensor_mm: $distance_from_mast_to_sensor_mm
        upstand_diameter_mm: $upstand_diameter_mm
        upstand_height_mm: $upstand_height_mm
        tilt_angle_deg: $tilt_angle_deg
        height_m: $height_m
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_MOUNTING_ARRANGEMENT = gql`
  mutation CreateMountingArrangement(
    $measurementPointId: uuid!
    $mounting_type_id: String
    $boom_orientation_deg: numeric
    $date_from: timestamp!
    $date_to: timestamp
    $orientation_reference_id: String
    $vane_dead_band_orientation_deg: numeric
    $boom_oem: String
    $boom_model: String
    $boom_length_mm: numeric
    $boom_diameter_mm: numeric
    $distance_from_mast_to_sensor_mm: numeric
    $upstand_diameter_mm: numeric
    $upstand_height_mm: numeric
    $tilt_angle_deg: numeric
    $height_m: numeric
  ) {
    insert_mounting_arrangement(
      objects: {
        measurement_point_uuid: $measurementPointId
        mounting_type_id: $mounting_type_id
        boom_orientation_deg: $boom_orientation_deg
        date_from: $date_from
        date_to: $date_to
        orientation_reference_id: $orientation_reference_id
        vane_dead_band_orientation_deg: $vane_dead_band_orientation_deg
        boom_oem: $boom_oem
        boom_model: $boom_model
        boom_length_mm: $boom_length_mm
        boom_diameter_mm: $boom_diameter_mm
        distance_from_mast_to_sensor_mm: $distance_from_mast_to_sensor_mm
        upstand_diameter_mm: $upstand_diameter_mm
        upstand_height_mm: $upstand_height_mm
        tilt_angle_deg: $tilt_angle_deg
        height_m: $height_m
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const DELETE_MOUNTING_ARRANGEMENT = gql`
  mutation DeleteMountingArrangement($mountingArrangementId: uuid!) {
    delete_mounting_arrangement(where: { uuid: { _eq: $mountingArrangementId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const UPDATE_CALIBRATION = gql`
  mutation UpdateCalibration(
    $calibrationId: uuid!
    $name: String
    $slope: numeric
    $offset: numeric
    $date_of_calibration: date
    $notes: String
    $report_file_name: String
    $calibration_organisation: String
    $sensitivity: numeric
    $place_of_calibration: String
    $report_link: String
    $is_consensus_calibration: Boolean
  ) {
    update_calibration(
      where: { uuid: { _eq: $calibrationId } }
      _set: {
        name: $name
        slope: $slope
        offset: $offset
        calibration_organisation: $calibration_organisation
        sensitivity: $sensitivity
        place_of_calibration: $place_of_calibration
        date_of_calibration: $date_of_calibration
        notes: $notes
        report_file_name: $report_file_name
        report_link: $report_link
        is_consensus_calibration: $is_consensus_calibration
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const DELETE_CALIBRATION = gql`
  mutation DeleteCalibration($calibrationId: uuid!) {
    delete_calibration(where: { uuid: { _eq: $calibrationId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_CALIBRATION = gql`
  mutation CreateCalibration(
    $sensorId: uuid!
    $name: String
    $slope: numeric
    $offset: numeric
    $date_of_calibration: date
    $notes: String
    $report_file_name: String!
    $report_link: String
    $is_consensus_calibration: Boolean
  ) {
    insert_calibration(
      objects: {
        sensor_uuid: $sensorId
        name: $name
        slope: $slope
        offset: $offset
        date_of_calibration: $date_of_calibration
        notes: $notes
        report_file_name: $report_file_name
        report_link: $report_link
        is_consensus_calibration: $is_consensus_calibration
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_SITE_DETAILS = gql`
  mutation CreateSiteDetails(
    $towerId: uuid!
    $name: String
    $site_id: String
    $address: String
    $state_province: bpchar
    $county: String
    $time_zone: bpchar
    $time_offset_from_utc_hours: numeric
    $elevation: Int
    $nearest_town: String
    $magnetic_declination: numeric
  ) {
    insert_site_details(
      objects: {
        measurement_location_id: $towerId
        name: $name
        site_id: $site_id
        address: $address
        state_province: $state_province
        county: $county
        time_zone: $time_zone
        time_offset_from_utc_hours: $time_offset_from_utc_hours
        elevation: $elevation
        nearest_town: $nearest_town
        magnetic_declination: $magnetic_declination
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SITE_DETAILS = gql`
  mutation UpdateSiteDetails(
    $Id: uuid!
    $name: String
    $longitude: numeric
    $latitude: numeric
    $site_id: String
    $address: String
    $state_province: String
    $county: String
    $time_zone: String
    $time_offset_from_utc_hours: numeric
    $elevation: Int
    $nearest_town: String
    $magnetic_declination: numeric
  ) {
    update_measurement_location(
      where: { uuid: { _eq: $Id } }
      _set: {
        county: $county
        elevation: $elevation
        latitude_ddeg: $latitude
        longitude_ddeg: $longitude
        magnetic_declination: $magnetic_declination
        address: $address
        name: $name
        nearest_town: $nearest_town
        site_id: $site_id
        state_province: $state_province
        time_offset_from_utc_hours: $time_offset_from_utc_hours
        time_zone: $time_zone
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;
export const CREATE_LANDOWNER_INFO = gql`
  mutation CreateLandownerInfo($towerId: uuid!, $land_id: String = "", $notes: String = "") {
    insert_landowner_info(objects: {measurement_location_uuid: $towerId, land_id: $land_id, notes: $notes}) {
      returning {
        land_id
        measurement_location_uuid
        notes
      }
    }
  }
`;

export const UPDATE_LANDOWNER_INFO = gql`
mutation MyMutation($notes: String = "", $land_id: String = "", $measurement_location_uuid: uuid!) {
  update_landowner_info(
    where: { measurement_location_uuid: { _eq: $measurement_location_uuid } }
    _set: { land_id: $land_id, notes: $notes, measurement_location_uuid: $measurement_location_uuid }
  ) {
    affected_rows
    returning {
      land_id
    }
  }
}
`;

export const CREATE_LANDOWNER_CONTACTS = gql`
  mutation CreateLandownerContacts(
    $towerId: uuid!
    $landownername: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $phone: String
    $email: String
  ) {
    insert_landowner_contacts(
      objects: {
        measurement_location_id: $towerId
        name: $landownername
        address: $address
        city: $city
        state: $state
        zip: $zip
        phone: $phone
        email: $email
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_LANDOWNER_CONTACT = gql`
  mutation UpdateLandownerContact(
    $Id: Int
    $landownername: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $phone: String
    $email: String
  ) {
    update_landowner_contacts(
      where: { id: { _eq: $Id } }
      _set: { name: $landownername, address: $address, city: $city, state: $state, zip: $zip, phone: $phone, email: $email }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_LANDOWNER_CONTACT = gql`
  mutation DeleteLandownerContact($Id: Int) {
    delete_landowner_contacts(where: { id: { _eq: $Id } }) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_MAINTENANCE_ENTRY = gql`
  mutation CreateMaintenanceEntry(
    $maintenance_date: timestamp!
    $title: String!
    $notes: String!
    $docGrp: uuid!
    $measurement_location_uuid: uuid!
    $report_form: jsonb
    $purpose: String!
  ) {
    insert_maintenance_entries(
      objects: [
        {
          maintenance_date: $maintenance_date
          title: $title
          notes: $notes
          docGrp: $docGrp
          measurement_location_uuid: $measurement_location_uuid
          report_form: $report_form
          purpose: $purpose
        }
      ]
    ) {
      returning {
        id
        title
        notes
        docGrp
        createdAt
        maintenance_date
        measurement_location_uuid
      }
    }
  }
`;

export const DELETE_MAINTENANCE_ENTRY = gql`
  mutation DeleteMaintenanceEntry($id: uuid!) {
    delete_maintenance_entries(where: { id: { _eq: $id } }) {
      returning {
        id
        title
      }
    }
  }
`;

export const UPDATE_MAINTENANCE_ENTRY = gql`
  mutation UpdateMaintenanceEntry(
    $id: uuid!
    $maintenance_date: timestamp!
    $title: String!
    $notes: String!
    $report_form: jsonb
    $purpose: String!
  ) {
    update_maintenance_entries(
      where: { id: { _eq: $id } }
      _set: {
        title: $title
        notes: $notes
        maintenance_date: $maintenance_date
        report_form: $report_form
        purpose: $purpose
      }
    ) {
      returning {
        id
        title
      }
    }
  }
`;

export const UPDATE_DATA_COLUMNS = gql`
  mutation updateDataColumn($id: uuid!, $newColName: String!) {
    update_data_columns(where: { uuid: { _eq: $id } }, _set: { column_name: $newColName }) {
      returning {
        uuid
      }
    }
  }
`;

// export const UPDATE_MAIN_DATA_COLUMNS = gql`
//   mutation updateMainDataColumn($id: uuid!,orientation:numeric,measurement_type_id: String!, boom: numeric ){
//     update_measurement_points(where: {uuid: {_eq: $id}},_set: { orientation: $orientation, boom: $boom,measurement_type_id: $measurement_type_id})
//   }
// `;

export const CREATE_PROFILER = gql`
  mutation createProfiler(
    $date_from: timestamp!
    $date_to: timestamp!
    $device_datum_plane_height_m: numeric!
    $device_orientation_deg: numeric!
    $height_reference_id: String!
    $orientation_reference_id: String!
    $measurement_location_uuid: uuid!
    $notes: String
  ) {
    insert_vertical_profiler_properties_one(
      object: {
        date_from: $date_from
        date_to: $date_to
        device_datum_plane_height_m: $device_datum_plane_height_m
        device_orientation_deg: $device_orientation_deg
        height_reference_id: $height_reference_id
        orientation_reference_id: $orientation_reference_id
        measurement_location_uuid: $measurement_location_uuid
        notes: $notes
      }
    ) {
      uuid
      notes
    }
  }
`;

export const ADD_MEASUREMENT = gql`
  mutation addMeasurement(
    $sensor_type_id: String!
    $height_m: numeric!
    $measurement_type_id: String!
    $orientation: Int
    $measurement_location_uuid: uuid!
    $name: String!
    $date_from: timestamp!
  ) {
    insert_measurement_point_sensor_one(
      object: {
        sensor: { data: { sensor_type_id: $sensor_type_id, model: "Model unknown", oem: "Unknown OEM" } }
        measurement_point: {
          data: {
            height_m: $height_m
            measurement_location_uuid: $measurement_location_uuid
            orientation: $orientation
            measurement_type_id: $measurement_type_id
            name: $name
          }
          on_conflict: {
            constraint: measurement_point_measurement_location_uuid_measurement_type_id
            update_columns: height_m
          }
        }
        date_from: $date_from
      }
    ) {
      measurement_point {
        uuid
      }
      date_from
      sensor {
        uuid
      }
    }
  }
`;

export const UPDATE_PROFILER = gql`
  mutation updateProfiler(
    $profilerId: uuid!
    $date_from: timestamp!
    $date_to: timestamp!
    $device_datum_plane_height_m: numeric!
    $device_orientation_deg: numeric!
    $height_reference_id: String!
    $notes: String!
  ) {
    update_vertical_profiler_properties(
      where: { uuid: { _eq: $profilerId } }
      _set: {
        date_from: $date_from
        date_to: $date_to
        device_datum_plane_height_m: $device_datum_plane_height_m
        device_orientation_deg: $device_orientation_deg
        height_reference_id: $height_reference_id
        notes: $notes
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const DELETE_PROFILER = gql`
  mutation deleteProfiler($profilerId: uuid!) {
    delete_vertical_profiler_properties(where: { uuid: { _eq: $profilerId } }) {
      returning {
        uuid
      }
    }
  }
`;

export const CREATE_LIDAR_CONFIG = gql`
  mutation createLidarConfig(
    $date_from: timestamp!
    $date_to: timestamp!
    $flow_corrections_applied: Boolean!
    $loggerMainConfigId: uuid!
  ) {
    insert_lidar_config(
      objects: {
        date_from: $date_from
        date_to: $date_to
        flow_corrections_applied: $flow_corrections_applied
        logger_main_config_uuid: $loggerMainConfigId
      }
    ) {
      returning {
        notes
        uuid
      }
    }
  }
`;
export const UPDATE_LIDAR_CONFIG = gql`
  mutation updateLidarConfig(
    $id: uuid!
    $date_from: timestamp!
    $date_to: timestamp!
    $flow_corrections_applied: Boolean!
    $notes: String
  ) {
    update_lidar_config(
      where: { uuid: { _eq: $id } }
      _set: {
        date_from: $date_from
        date_to: $date_to
        flow_corrections_applied: $flow_corrections_applied
        notes: $notes
      }
    ) {
      returning {
        uuid
        notes
      }
    }
  }
`;
export const DELETE_LIDAR_CONFIG = gql`
  mutation deleteLidarConfig($lidarId: uuid!) {
    delete_lidar_config(where: { uuid: { _eq: $lidarId } }) {
      returning {
        uuid
        notes
      }
    }
  }
`;
export const UPDATE_LOGGER_MAIN_CONFIG_FOR_PROFILER_PAGE = gql`
  mutation updateLoggerMainConfigForProfilerPage(
    $loggerConfigId: uuid!
    $date_from: timestamp!
    $date_to: timestamp!
    $logger_model_name: String!
    $logger_oem_id: String!
    $logger_serial_number: String!
    $notes: String
  ) {
    update_logger_main_config(
      where: { uuid: { _eq: $loggerConfigId } }
      _set: {
        date_from: $date_from
        date_to: $date_to
        logger_model_name: $logger_model_name
        logger_oem_id: $logger_oem_id
        logger_serial_number: $logger_serial_number
        notes: $notes
      }
    ) {
      returning {
        uuid
        notes
      }
    }
  }
`;
export const ADD_MEASUREMENT_WITHOUT_SENSOR = gql`
  mutation addMeasurementWithoutSensor(
    $height_m: numeric!
    $measurement_type_id: String!
    $orientation: Int
    $measurement_location_uuid: uuid!
    $name: String!
  ) {
    insert_measurement_point(
      objects: {
        height_m: $height_m
        measurement_location_uuid: $measurement_location_uuid
        orientation: $orientation
        measurement_type_id: $measurement_type_id
        name: $name
      }
      on_conflict: {
        constraint: measurement_point_measurement_location_uuid_measurement_type_id
        update_columns: height_m
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const UPDATE_DATA_COLUMNS_STATS = gql`
  mutation updateDataColumn($id: uuid!, $newStatsName: String!) {
    update_data_columns(where: { uuid: { _eq: $id } }, _set: { statistic_type_id: $newStatsName }) {
      returning {
        uuid
      }
    }
  }
`;

export const ADD_DATA_COLUMN = gql`
  mutation addDataColumn($measurement_point_uuid: uuid!, $statistic_type_id: String!, $column_name: String!) {
    insert_data_columns(
      objects: {
        statistic_type_id: $statistic_type_id
        column_name: $column_name
        measurement_point_uuid: $measurement_point_uuid
      }
    ) {
      returning {
        uuid
      }
    }
  }
`;

export const UPDATE_MEASUREMENT_POINT_DC = gql`
  mutation updateMeasurementPointDC(
    $m_uuid: [uuid]!
    $s_uuid: [uuid]!
    $orientation: Int!
    $height_m: numeric!
    $measurement_type_id: String!
    $sensor_type_id: String!
  ) {
    update_measurement_point(
      where: { uuid: { _in: $m_uuid } }
      _set: { measurement_type_id: $measurement_type_id, height_m: $height_m, orientation: $orientation }
    ) {
      affected_rows
    }
    update_sensor(_set: { sensor_type_id: $sensor_type_id }, where: { uuid: { _in: $s_uuid } }) {
      affected_rows
    }
  }
`;

export const UPDATE_MEASUREMENT_POINT_USING_SID = gql`
  mutation updateMeasurementPointSID(
    $orientation: Int!
    $height_m: numeric!
    $sensor_type_id: String!
    $new_orientation: Int!
    $new_height_m: numeric!
  ) {
    update_measurement_point(
      where: {
        height_m: { _eq: $height_m }
        orientation: { _eq: $orientation }
        measurement_point_sensors: { sensor: { sensor_type_id: { _eq: $sensor_type_id } } }
      }
      _set: { height_m: $new_height_m, orientation: $new_orientation }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_MEASUREMENT_POINT_USING_UUID = gql`
    mutation updateMeasurementPointUUID(
    $uuid: uuid!
    $new_orientation: Int!
    $new_height_m: numeric!
  ){
    update_measurement_point(
      where: { uuid: { _eq: $uuid } }
      _set: { height_m: $new_height_m, orientation: $new_orientation }
    ){
      affected_rows
    }
  }
`

