import React from "react";
import ReactDOM from "react-dom";
import { Map, GoogleApiWrapper, Marker, InfoWindow, } from "google-maps-react-new";

import { CircularProgress } from "@material-ui/core";

import MapPin from "assets/icons/map-pin.svg";
import MapPinFullIcon from "assets/new/map-pin-full.svg";
import { CtaArrowRight, mapsStyle } from "assets";
import "./Map.css";
import { isEqual } from "lodash";
import ActiveMarkerCircle from "assets/icons/active-marker-circle.svg";
import { getMastStatusString } from "utils";
const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
};

const DEFAULT_CENTER = {
  lat: 40,
  lng: -102,
};

const MAX_ZOOM = 5;

export class Maps extends React.Component {
  state = {
    showingInfoWindow: false, // Hides or shows the InfoWindow
    activeMarker: {}, // Shows the active marker upon click
    selectedPlace: {}, // Shows the InfoWindow to the selected place upon a marker
    selectedLocation: {},
    bounds: undefined,
    noUpdateBound: false,
    defaultCenter: undefined,
    defaultZoom: undefined,
  };

  onMarkerClick = (props, marker, location) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      selectedLocation: location,
    });
  };

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        selectedPlace: {},
        selectedLocation: {},
      });
    }
  };

  redirectToProject = (projectId, towerId, history, e) => {
    const el = (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          if (projectId !== null && towerId !== null) {
            history.push(`/${projectId}/${towerId}`);
          } else {
            history.push(`/${projectId}`);
          }
        }}
      >
        <span>{projectId !== null && towerId !== null ? "View Location" : "View Project"}</span>
        <CtaArrowRight />
      </div>
    );
    ReactDOM.render(React.Children.only(el), document.getElementById("project"));
  };

  mapLoaded(mapProps, map) {
    const { locationsDataList, center, fallback, currentTowerId } = this.props;

    this.props.onMapLoad && this.props.onMapLoad(map);
    map.setOptions({
      styles: mapsStyle,
      mapTypeControl: true,
    });
    map.setMapTypeId("terrain");

    if (Array.isArray(locationsDataList)) {
      if (locationsDataList.length !== 0) {
        if (locationsDataList.length == 2) {
          locationsDataList.forEach((item) => {
            if (item.towerId == currentTowerId) {
              this.mapRef.map.setCenter(item.coordinates);
              this.mapRef.map.setZoom(2);
            } else {
              console.log("map Nulll");
            }
          });
        } else {
          locationsDataList.forEach((item) => {
            if (item.towerId == currentTowerId) {
              // console.log("map coordinates", item.coordinates);
              // console.log("map towerId", item.towerId);
              // console.log("map item", item);
              this.mapRef.map.setCenter(item.coordinates);
              this.mapRef.map.setZoom(9);
            } else {
              console.log("map Nulll");
            }
          });
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
    // map.setOptions({
    //   mapTypeControl: true,
    // });
  }

  componentDidMount() {
    // this.mapRef.map.setMapTypeId("satellite");
    this.setBounds();
  }

  static mapRef;

  setBounds() {
    const { locationsDataList, center, fallback, currentTowerId } = this.props;
    if (Array.isArray(locationsDataList)) {
      if (locationsDataList.length !== 0) {
        if (locationsDataList.length === 1) {
          this.mapRef.map.setCenter(locationsDataList[0].coordinates);
          this.mapRef.map.setZoom(5);
        } else {
          const bounds = new this.props.google.maps.LatLngBounds();
          locationsDataList.forEach((item) => {
            const latLng = new this.props.google.maps.LatLng(item.coordinates.lat, item.coordinates.lng);
            bounds.extend(latLng);
          });
          this.setState({ bounds: bounds });
        }
      } else {
        if (fallback) {
          this.mapRef.map.setCenter(fallback);
          this.mapRef.map.setZoom(7);
        }
      }
    } else {
      if (center) {
        const bounds = new this.props.google.maps.LatLngBounds();
        this.mapRef.map.setCenter(center);
        this.mapRef.map.setZoom(5);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.noUpdateBound) {
      // this.setState()
    } else {
      if (
        !isEqual(prevProps.locationsDataList, this.props.locationsDataList) ||
        !isEqual(prevProps.fallback, this.props.fallback)
      ) {
        this.setBounds();
      }
    }
  }

  render() {
    const {
      scaledSize,
      locationsDataList,
      center,
      onMapClick,
      mapZoom = 7,
      history,
      highlightedMarker = [],
    } = this.props;
    const { selectedLocation } = this.state;
    return (
      <div style={this.props.style}>
        <Map
          containerStyle={containerStyle}
          className="map"
          ref={(map) => {
            this.mapRef = map;
          }}
          // centerAroundCurrentLocation
          style={this.props.style}
          google={this.props.google}
          bounds={this.state.bounds}
          // onBoundsChanged={(mapProps) => {
          //

          //   if (this._checkMapMaxZoom) {
          //     const zoom = this.mapRef.map.getZoom();
          //
          //     if (zoom > MAX_ZOOM) {
          //
          //       this.mapRef.map.setZoom(MAX_ZOOM);
          //     }
          //     this._checkMapMaxZoom = false;
          //   }
          // }}
          // initialCenter={locationsDataList?.[0]?.coordinates || center || DEFAULT_CENTER}
          // center={this.state.bounds?.getCenter() || center} // to re-render the center on coordinate change
          onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
          onClick={(t, map, c) => {
            this.setState({ noUpdateBound: true });
            onMapClick(c.latLng, map);
          }}
          {...this.props}
        // mapTypeControl={true}
        >
          {center && (
            <Marker
              position={center}
              icon={{
                url: MapPinFullIcon,
                anchor: new this.props.google.maps.Point(36, 36),
                scaledSize: new this.props.google.maps.Size(scaledSize || 50, scaledSize || 50),
              }}
            />
          )}
          {locationsDataList?.map((location, i) => {
            return (
              <Marker
                key={location.coordinates.lat}
                lat={String(location.coordinates.lat)}
                lng={String(location.coordinates.lng)}
                onClick={(props, marker) => this.onMarkerClick(props, marker, location)}
                icon={{
                  url: location.markerIcon || MapPin,

                  anchor: new this.props.google.maps.Point(32, 32),
                  scaledSize: new this.props.google.maps.Size(scaledSize || 26, scaledSize || 26),
                }}
                position={location.coordinates}
                label={{
                  text: `${location.name}`,
                  className: 'marker-labelx',
                }}
              />
            );
          })}

          {highlightedMarker.map((location, i) => {
            return (
              <Marker
                key={String(location.lat) + " " + String(location.lng)}
                position={{
                  ...location.coordinates,
                }}
                onClick={(props, marker) => this.onMarkerClick(props, marker, location)}
                icon={{
                  url: ActiveMarkerCircle,
                  anchor: new this.props.google.maps.Point(39, 39),
                  scaledSize: new this.props.google.maps.Size(scaledSize || 40, scaledSize || 40),
                }}
              />
            );
          })}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onClose}
            onOpen={(e) => {
              this.redirectToProject(selectedLocation?.projectId, selectedLocation?.towerId, history, e);
            }}
          >
            <>
              <div className="ProjectName">{selectedLocation?.name}</div>
              <div className="ProjectType">{selectedLocation?.type + getMastStatusString(selectedLocation)}</div>
              <div className="location">
                Latitude: {selectedLocation?.coordinates?.lat}
                <br />
                Longitude: {selectedLocation?.coordinates?.lng}
                <br />
              </div>
              <div className="map__project-link" id="project" />
            </>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

const LoadingContainer = () => (
  <div className="map__loader">
    <CircularProgress />
  </div>
);

export const RaptMap = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API_KEY,
  LoadingContainer: LoadingContainer,
})(Maps);
